<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">



    <app-navbar></app-navbar>

<div id="main-slider" data-slider-width="100%" data-slider-height="300px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider main-slider_mod-a slider-pro">
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide"><img src="assets/media/components/b-main-slider/bg-9.jpg" alt="slider" class="sp-image"/>
        <div class="container">
          <div class="row">
            <div class="col-md-8">

              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="2200" data-hide-delay="400" class="main-slider__info sp-layer"><img src="assets/media/general/ipad-phone.png" alt="slider"/></div>

            </div>
          </div>
        </div>
      </div>
      <!-- Slide 2-->

    </div>
  </div>
  <!-- end .main-slider-->
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="section-area">
          <div class="b-request-estimate">
            <div class="b-request-estimate__info">GET Store POS is  that comes loaded with all the features that modernised and simplify operations, increase sales, and delight guests.</div>
            <div class="b-request-estimate__title bg-primary"><span class="ui-decor-2"></span>Café POS System</div>
          </div>
          <!-- end .b-request-estimate-->
        </div>
      </div>
    </div>
  </div>
<!-- START THE NAVBAR -->
<section class="section-default retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <span class="stroke icon-ClipboardText"></span>
    <h1>Smooth order management</h1>
    <ul>
        <li>Baristas can easily receive and manage orders with Open tickets.</li>
        <li>Assign tickets to tables in your cafe in one click with the predefined tickets.</li>
        <li>Manage open tickets by splitting, merging, and moving items between tickets.</li>
        <li>Assign tickets to different employees.</li>
    </ul>

</div>
<div class="col-md-6">
    <img src="assets/media/general/opentickets.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row">
<div class="col-md-6">
    <img src="assets/media/general/layout.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <span class="stroke icon-ForkandKnife"></span>
    <h1>Organize sales according to your needs</h1>
    <ul>
        <li>Customize your menu, bring popular items to the first screen.</li>
        <li>Organize items and categories on separate pages, such as coffee, cakes, fruits etc.</li>
        <li>Add toppings and add-ons to the items in one click by using modifiers.</li>
        <li>Create different dining options, such as “Dine in”, “Take out”, “Delivery”, etc.</li>
        <li>Apply discounts and taxes, and make notes to the items or to the orders.</li>
    </ul>
</div>
</div>
</div>
</section>

<section class=" retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <span class="stroke icon-Crown"></span>
    <h1>Make your customers come back</h1>
    <ul>
        <li>Give your customers full control on their orders through digital display showing the sale process.</li>
        <li>Motivate customers to visit your cafe again by rewarding them with bonus points with the loyalty program.</li>
        <li>Build personal engagement with your customers and turn them into constant visitors of your coffee shop by rewarding each purchases.</li>
        <li>Remember each client's personal preferences by making notes in the system.</li>
    </ul>
</div>
<div class="col-md-6">
    <img src="assets/media/general/customer.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row">
<div class="col-md-6">
    <img src="assets/media/general/timecard.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <span class="stroke icon-Users"></span>
    <h1>Manage your employees</h1>
    <ul>
        <li>Track each employee’s sales performance and make informed business decisions.</li>
        <li>Information on the workload per hour allows for efficient management of your staff.</li>
        <li>Manage employee access to sensitive information and functions.</li>
        <li>Employees can clock in/out and the system calculates their total hours.</li>
        <li>Identify any cash discrepancy at the end of the shift and track employees’ inaccuracy or manipulations.</li>
        <a href="#" class="">Read more about Employee management</a>
    </ul>
</div>
</div>
</div>
</section>

<section class=" retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <span class="stroke icon-Export"></span>
    <h1>Immediately send orders to the kitchen</h1>
    <ul>
        <li>Get orders to the kitchen faster and with fewer errors using kitchen displays or printers.</li>
        <li>Items, quantities, modifiers, item and ticket comments will all appear on the kitchen orders with display or printer.</li>
        <li>Create as many kitchen stations as you need by using kitchen displays or printers.</li>
    </ul>
</div>
<div class="col-md-6">
    <img src="assets/media/general/kds-dining.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row text-center">
<h2>Pair any number of kitchen displays and printers with any number of POS devices</h2>
<br>
<img src="assets/media/general/kds-pair.jpg" style="margin:0 auto" alt="foto" class="img-responsive"/>
</div>
</div>
</section>

<section class=" retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <span class="stroke icon-Web"></span>
    <h1>Scale your business</h1>
    <ul>
        <li>Manage multiple cafes or coffee shop chains under one account.</li>
        <li>Track your sales, inventory, employees, and customers — everything you need to know in one place.</li>
        <li>Track the sales growth or decline compared with the previous day, week, or month, and promptly react to changes.</li>
        <li>Sales by item analytics allow the best-selling items to be determined and therefore, the right purchasing decisions can be made.</li>
    </ul>
</div>
<div class="col-md-6">
    <img src="assets/media/general/multishop.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row">
<div class="col-md-6">
    <img src="assets/media/general/inventory.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <span class="stroke icon-StorageBox"></span>
    <h1>Manage your inventory</h1>
    <ul>
        <li>Quickly fill up your stock with fresh products with purchase orders.</li>
        <li>Manage stock of produced items and ingredients with the production of composite items.</li>
        <li>Transfer goods between your cafes.</li>
        <li>Keep your stock always accurate by performing counting and stock correction in the Advanced Inventory Software.</li>
    </ul>
</div>
</div>
</div>
</section>

<div class="container retail">
<div class="row">
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_green section-radius" style="background-position: -62.7px 20.4px;">
            <div class="b-contact__name">Website</div>
            <div class="b-contact__info">{{'envUrl' | envName }}</div>
            <div class="b-contact__icon icon-globe"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_blue section-radius" style="background-position: -296.7px 20.4px;">
            <div class="b-contact__name">Phone</div>
            <div class="b-contact__info"> +60 3-7803 4040</div>
            <div class="b-contact__icon icon-call-in"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_grey section-radius" style="background-position: -530.7px 20.4px;">
            <div class="b-contact__name">Email</div>
            <div class="b-contact__info">getstore@gmail.com</div>
            <div class="b-contact__icon icon-envelope-open"></div>
          </div>
          <!-- end b-contact-->
        </div>
      </div>
</div>

  <section class="b-taglines area-bg bg-primary_a parallax">
    <div class="area-bg__inner">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 col-sm-offset-3">
            <div class="b-taglines__inner">
              <h1 class="">Download Get Store POS</h1>
              <div class="b-taglines__text col-sm-offset-1">For iPhone, iPad, Android smartphone or tablet</div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/gplay-en.png" alt="foto" class="img-responsive"/></a></div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/appstore-en.png" alt="foto" class="img-responsive"/></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>

</div>
<!-- end layout-theme-->

<!-- //div fix-->
<script>
$(document).ready(function() {
$('.nav li a').click(function(e) {
    $('.nav li').removeClass('active');
    var $parent = $(this).parent();
    console.log($parent);
    if (!$parent.hasClass('active')) {
        $parent.addClass('active');
    }
    e.preventDefault();
});
})
</script>
