<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">
  
  <app-navbar></app-navbar>  
  
  <div id="main-slider" data-slider-width="100%" data-slider-height="300px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider main-slider_mod-a slider-pro">
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide"><img src="assets/media/components/b-main-slider/bg-2.jpg" alt="slider" class="sp-image"/>
        <div class="container">
          <div class="row">
            <div class="col-md-8">
                
              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="1200" data-hide-delay="400" class="main-slider__info sp-layer"><img src="assets/media/general/features-devices_01.png" alt="slider"/></div>
            </div>
          </div>
        </div>
      </div>
      <!-- Slide 2-->
      
    </div>
  </div>
  <!-- end .main-slider-->
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="section-area">
          <div class="b-request-estimate">
            <div class="b-request-estimate__info">GET Store POS is  that comes loaded with all the features that modernised and simplify operations, increase sales, and delight guests.</div>
            <div class="b-request-estimate__title bg-primary"><span class="ui-decor-2"></span>POS FEATURES</div>
          </div>
          <!-- end .b-request-estimate-->
        </div>
      </div>
    </div>
  </div>
<!-- START THE NAVBAR -->
<div class="container tabmenu">
<div class="row" id="wrapperone">
<div class="col-md-4" id="sticky">
    
    <ul>
        <li><h1 id="top">POS FEATURES</h1></li>
        <li class="active" (click)="scroll('Two')"><a href="javascript:void(0)">Point-of-Sale (POS)</a></li>
        <li (click)="scroll('Three')"><a href="javascript:void(0)">QR Dine-In and Ordering</a></li>
        <li (click)="scroll('Four')"><a href="javascript:void(0)">Online Ordering / App Ordering</a></li>
        <li (click)="scroll('Five')"><a href="javascript:void(0)">Keep Your Menu Sync</a></li>
        <li (click)="scroll('Six')"><a href="javascript:void(0)">Integrated Payment</a></li>
        <li (click)="scroll('Seven')"><a href="javascript:void(0)">Sync to Cloud</a></li>
        <li (click)="scroll('Eight')"><a href="javascript:void(0)">GET Store Store a Cloud-Based POS & Delivery Management System</a></li>
        <li (click)="scroll('Nine')"><a href="javascript:void(0)">Time to improve the way you do business</a></li>
        <li (click)="scroll('Ten')"><a href="javascript:void(0)">Never ending with a tedious check-out?</a></li>
        <li (click)="scroll('Eleven')"><a href="javascript:void(0)">Troubled  in managing and supervising multiple outlets?</a></li>
        <li (click)="scroll('Twelve')"><a href="javascript:void(0)">Integrated Inventory Management with Real time control</a></li>
        <li (click)="scroll('Thirteen')"><a href="javascript:void(0)">Practical Design</a></li>
        <li (click)="scroll('Fourteen')"><a href="javascript:void(0)">POS Features</a></li>
        <li (click)="scroll('Fifteen')"><a href="javascript:void(0)">Offline protection</a></li>
        <li (click)="scroll('Sixteen')"><a href="javascript:void(0)">Save, hold, split & merge customer orders</a></li>
        <li (click)="scroll('Seventeen')"><a href="javascript:void(0)">Set Promotions</a></li>
        <li (click)="scroll('Eighteen')"><a href="javascript:void(0)">loyalty programs</a></li>
        <li (click)="scroll('Nineteen')"><a href="javascript:void(0)">Online Timings</a></li>
        <li (click)="scroll('Twenty')"><a href="javascript:void(0)">Free website</a></li>
        <li (click)="scroll('Twenty-one')"><a href="javascript:void(0)">Inventory management</a></li>
        <li (click)="scroll('Twenty-two')"><a href="javascript:void(0)">Get automatic low stock alerts</a></li>
        <li (click)="scroll('Twenty-three')"><a href="javascript:void(0)">View/Transfer inventory across multiple stores</a></li>
        <li (click)="scroll('Twenty-four')"><a href="javascript:void(0)">Manage your supplier relationships</a></li>
        <li (click)="scroll('Twenty-five')"><a href="javascript:void(0)">Store Credits</a></li>
        <li (click)="scroll('Twenty-Six')"><a href="javascript:void(0)">Send SMS</a></li>
        <li (click)="scroll('Twenty-Seven')"><a href="javascript:void(0)">Manage employees</a></li>
        <li (click)="scroll('Twenty-Eight')"><a href="javascript:void(0)">Generate reports by transaction</a></li>
        <li (click)="scroll('Twenty-Nine')"><a href="javascript:void(0)">Real-time reports</a></li>
        <li (click)="scroll('Thirty')"><a href="javascript:void(0)">Improved Store Operations</a></li>
        <li (click)="scroll('Thirty-One')"><a href="javascript:void(0)">Cloud-based data management</a></li>
        <li (click)="scroll('Thirty-Two')"><a href="javascript:void(0)">No sales and in/out expenses</a></li>
        <li (click)="scroll('Thirty-Three')"><a href="javascript:void(0)">Attributes, options, variants and modifiers</a></li>
        <li (click)="scroll('Thirty-Four')"><a href="javascript:void(0)">Stock alerts</a></li>
        <li (click)="scroll('Thirty-Five')"><a href="javascript:void(0)">Supplier management</a></li>
        <li (click)="scroll('Thirty-six')"><a href="javascript:void(0)">Integration with ecommerce</a></li>
    </ul>
</div>
 <!-- END NAVBAR -->
<div class="col-md-8 features">
    <div class="row">
        <h2 id="two">Empower the management of your business today!</h2>
        <div class="col-md-6">
        GET StoreStore POS is full-fledged as it comes with all the indispensable features that simplifies operations, increases sales, and astounds guests. GET StoreStore Point of Sale and management system (POS) enables the restaurants to expand and prosper by being user friendly, instant and efficient. Saving countless hours of manual labour and easily controlling every part of your company could not be any easier!<br>
            <a href="/employee">Learn more about Empower management</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Enhance the Managment of you business.jpg" alt="POS FEATURES"/>
        </div>
    </div>
    <div class="row">
        <h2 id="three">QR Dine-In and Ordering</h2>
        <div class="col-md-6">
        An effective and efficient service system that meticulously incorporates the new norm aiming at reducing physical contact between guests and staff. QR menus are the digitally enhanced versions of the menus at restaurants. The GET Store POS Dine-In module comes fully functional and is integrated with these futuristic features. Our system is particularly designed to accelerate table service from the conventional method of placing an order that operates on both Android or iOS devices. No installation is required, merely scan through QR and place your order. Turntables are easier with a smaller crew, making the dining experience for the visitors safer.<br>
            <a href="/back-office">Learn more about QR Dine-In and Ordering</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/QR Dine-In and Ordering.jpg" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Four">Online Ordering / App Ordering</h2>
        <div class="col-md-6">
        GET Store helps to not rely on third-party for online orders from apps. This system enables guests to discover your restaurant irrespective of the online platform used. GET Store Store in-house solution takes direct online ordering and helps your restaurant remain in control of your customer relationships as well as your bottom line. We put utmost emphasis on hassle-free catering to your guests, irrespective of how or where they order from. Furthermore, we accept contactless payments for smooth pickup and dine-in.
            <br>
            <a href="/back-office">Learn more about Online Ordering</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Online Ordering.jpg" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Five">Keep Your Menu Sync</h2>
        <div class="col-md-6">
        Sync your catalogue between your online and physical stores so your customers can access your unique layout of menu that gives them familiarity. The process is further simplified as it enables the consistent and succinct updation of your online menu with GET Store Store POS. Hence, it ends up automatically updating any changes throughout all devices under the store.
            <br>
            <a href="/kitchen">Learn more about Menu Sync</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Keep Your Menu.jpg" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Six">Integrated Payment</h2>
        <div class="col-md-6">
        E-Wallets are widely being used for most transactions as it aims at inculcating a tendency of leaning towards a cashless payment medium for fast and efficient transactions. Ever since the inception of the COVID-19 pandemic, reductions in the usage of physical currencies and promotion of contact-free, electronic payment methods have been embraced worldwide. Therefore, GET Store presently supports and accepts transactions from most of the E-Wallets.
            <br>
            <a href="/customer">Learn more about Integrated Payment</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Integrated Payment.jpg" alt="POS FEATURES"/>
        </div>
        <hr>
    </div> 
    <div class="row">
        <h2 id="Seven">Sync to Cloud</h2>
        <div class="col-md-6">
        Sign in and work from wherever your sales, product and reports are ever-available, secure, and up to date with the GET Store Store cloud-based POS system. Through GET Store Store cloud you can easily monitor various aspects of your data from multiple locations simultaneously and take immediate action regardless of whether it's a singular restaurant or a chain of restaurants.
            <br>
            <a href="/multi-store">Learn more about Sync to Cloud</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Sync to Cloud.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Eight">GET Store Store a Cloud-Based POS & Delivery Management System</h2>
        <div class="col-md-6">
        GET Store Store POS System is what your Business is lacking, with one powerful smart interface that any retail company can use with ease . With a user friendly interface you can configure GET Store Store to meet your precise needs.
            <br>
            <a href="/back-office">Learn more about Delivery Management System</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Cloud-Based.jpg" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Nine">Time to improve the way you do business</h2>
        <div class="col-md-6">
        With GET Store-Hub Cloud POS, you can receive instant feedback on all stores inventory, employees to help you manage your company more effectively and efficiently.
            <br>
            <a href="/dashboard">Learn more about Improve Business</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/improve the way.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Ten">Never ending with a tedious check-out?</h2>
        <div class="col-md-6">
        Our cloud-based system offers swift and streamlined updates on your products, users, or stores anytime, with a user-friendly interface for organizing easily. No more disruptions, delays or difficulties updating the cashier system.
            <br>
            <a href="/dashboard">Learn more about Tedious check-out</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Never ending.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Eleven">Troubled  in managing and supervising multiple outlets?</h2>
        <div class="col-md-6">
        GET Store provides a dashboard with live, cloud-based information, thereby enabling complete visibility for managing all stores and outlets in real time irrespective where you are.
            <br>
            <a href="/multi-store">Learn more about Multiple outlets</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/multiple outlets.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>

    <div class="row">
        <h2 id="Twelve">Integrated Inventory Management with Real time control</h2>
        <div class="col-md-6">
        GET Store-Hub Inventory automates each data transaction from the POS into the stock and procurement management . It allows you to monitor the availability of products without having to spend copious amounts of time after it. With the GET Store Store inventory monitoring app, you'll be able to keep track of stocks as they get sold out and notifies you once it's time to replenish more ingredients quickly.
            <br>
            <a href="/inventory">Learn more about Inventory Management</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Inventory Management.jpg" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Thirteen">Practical Design</h2>
        <div class="col-md-6">
        The elegant minimal interface-based design offers a stupendous user experience and places emphasis on the evaluation of what actually matters of your services.
            <br>
            <a href="/customer">Learn more about Practical Design</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Practical Design.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>

    <div class="row feature-row">
        <h2 id="Fourteen">POS Features</h2>
        <div class="col-md-6">
        <h3>POS runs in Online and Offline </h3>
        <p>Don't let an unstable internet slow down your efficiency . Once the link is restored, all the data will be immediately synced.</p>
<p>The ability to set up the offline at any suitable place and time, and then easily reconnects to the web.
</p><br>
            <a href="/dashboard">Learn more about Online and Offline</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Online and Offline.jpg" alt="POS FEATURES"/>
        </div>


        <div class="col-md-6">
        <h3>Billing Feature</h3>
        <p>Combine Bill - Combine bills from any other tables or individual order.</p>	
        <p>Split Bil - Individual bills can be provided for each table based on individual orders.</p>
        <p>Transfer Table - Get your orders easily transferred as you move to another table.</p>
            <br>
            <a href="/dashboard">Learn more about Billing Feature</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Billing Feature.png" alt="POS FEATURES"/>
        </div>


        <div class="col-md-6">
        <h3>Contactless & Eco Friendly Payment Solution</h3>
        <p>As guests are striving to  practice less physical contact, all it takes is a single tap to make transactions in just under one second.</p>

        <h5>We accept:</h5>
        <p>E-Wallets -Boost, Touch’ N Go E-Wallet, MCash, Maybank QR PAY and GrabPay</p>
        <p>International - WeChat Pay, Alipay and UnionPay</p>
            <br>
            <a href="/dashboard">Learn more about Contactless</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Payment Solution.png" alt="POS FEATURES"/>
        </div>
    </div>
    <div class="row feature-row">
        <div class="col-md-6">
        <h3>Restaurent Layout</h3>
        <p>GetStore  simplifies all aspects of the customer's experience for your convenience. Configure the table management system to control the capacity and automatically mitigate any possible inconveniences</p>
            <br>
            <a href="/kitchen">Learn more about Restaurent Layout</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Restaurent Layout.jpg" alt="POS FEATURES"/>
        </div>

        <div class="col-md-6">
        <h3>Floor</h3>
        <p>Floor plan interactive</p>	
        <p>Manage the restaurant tables effortlessly to unleash your restaurant's full potential.</p>
            <br>
            
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Floor plan.jpg" alt="POS FEATURES"/>
        </div>

        <div class="col-md-6">
        <h3>List</h3>
        <p>Views customer's bookings in the list assess and process them easily via digital reservation.</p>
            <br>
            <a href="/kitchen">Learn more about list</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/List.jpg" alt="POS FEATURES"/>
        </div>

        <div class="col-md-6">
        <h3>Cloud base software</h3>
        <p>A centralised structure for managing the necessary task restaurant activities with less complications.</p>
        <p>It helps in the management of core operations processes including all the essential activities like ticketing, invoicing, printing KOT.</p>
        <p>It analyses your company's progress in all aspects with personalized reports generated automatically at the end of the day on our dashboard.</p>
            <br>
            <a href="/multi-store">Learn more about Cloud base software</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Cloud base.png" alt="POS FEATURES"/>
        </div>
    </div>
    <div class="row feature-row">
        <div class="col-md-6">
        <h3>Menu Management</h3>
        <p>Displays order information to your customers on a separate digital display simultaneously as the sale progresses.</p>
        <p>Manages the availability of items from the menu as well as pricing on your own website, right from the POS.</p>
            <br>
            <a href="/multi-store">Learn more about Menu Management</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Menu Management.png" alt="POS FEATURES"/>
        </div>

        <div class="col-md-6">
        <h3>Integrated Inventory Management</h3>
        <p>Monitor stocks in real time</p>
        <p>Low Inventory Alert - Get notified regarding lower stock and make the necessary adjustments to item orders on time.</p>
        <p>Track Entire Transaction - Views records of your inventory and gain insights into the flow expense and future benefit.</p>
        <p>Tracking Over-Portioning -  Mitigates over-portioning, over-buying, and theft.</p>
        <p>Arrival or receiving of new stocks</p>
        <p>ERP Accounting Software</p>
        <p>Sales & Customer Analysis Listing</p>
        <p>Inventory Audit Trial</p>
        <p>Top Sales by Item, Sales & Profit</p>
            <br>
            <a href="/inventory">Learn more about Integrated Inventory Management</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Inventory Management.png" alt="POS FEATURES"/>
        </div>

    </div>
    <div class="row feature-row">
        <div class="col-md-6">
        <h3>Improved cash management</h3>
        <p>Reliable and secure system</p>
        <p>Solidifies the integrity of your operations.</p>
        <p>Makes it more systematic and organized.</p>
        <p>Avoids any errors when closing for the day</p>
        <p>Is Anti-theft</p>
            <br>
            <a href="/inventory">Learn more about Improved Cash Management</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Cash Management.png" alt="POS FEATURES"/>
        </div>
    </div>
    <div class="row">
        <h2 id="Fifteen">Offline protection</h2>
        <div class="col-md-6">
        WiFi down?<br>
With our auto-offline mode, you can still run your store seamlessly without WiFi. Just remember to sync your iPad once your WiFi is back on.
<br>
            <a href="/back-office">Learn more about Offline Protection</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Offline protection.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Sixteen">Save, hold, split & merge customer orders</h2>
        <div class="col-md-6">
        We understand the differences in needs when it comes to managing your order and customers.<br>

When your customer initiates a checkout but suddenly changes his/her mind and is not ready to pay, you can save his/her orders. Once the order is saved, you can access the orders from ‘Open Orders’. If your customer wants to split the bill individually, you can also split open orders for your customers directly from the app. This comes in handy while handling big bills at restaurants. If there are two separate ‘Open Orders’ that you want to combine, you can now merge them together for your customers directly from the app.<br>
These tools will help you save time as you do not need to create a new order to cater to your customer’s needs.
            <br>
            <a href="/customer">Learn more about Merge customer orders</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/customer-order.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Seventeen">Set Promotions</h2>
        <div class="col-md-12">
        Get Store POS Recommended Promotions.<br>

Enjoy the flexibility to design promotions that best fit your business needs.<br>
<table class="promotionstable">
<tr>
    <td>1</td>
    <td class="strong">% Percentage discount</td>
    <td>% Percentage discount offers your customers a % discount off the original price of an item of category (Example: 20% OFF).</td>
</tr>
<tr>
    <td>2</td>
    <td class="strong">Discount offers</td>
    <td>$ Fixed amount discount offers your customers a fixed amount discount off the original price of an item or category. (Example: RM 5 OFF).</td>
</tr>
<tr>
    <td>3</td>
    <td class="strong">Delivery free</td>
    <td>Delivery free discount offers your customers a discount off the delivery fee when a minimum ($) order is met.(Example: RM5 OFF Free Del Fee with minimum spend RM30).</td>
</tr>
<tr>
    <td>4</td>
    <td class="strong">Free Promotion offers</td>
    <td>Free Item promotion offers your customers free items from your menu when a minimum ($) order is met. (Example: Spend a minimum of RM10 and get a free gift).</td>
</tr>
<tr>
    <td>5</td>
    <td class="strong">Combo Promotion offers</td>
    <td>Combo Promotion offers your customers see original price of an item and saving amount and %.</td>
</tr>
<tr>
    <td>6</td>
    <td class="strong">Send SMS </td>
    <td>Send SMS message for your existing customers and update new offers and promotions.</td>
</tr>
<tr>
    <td>7</td>
    <td class="strong">Cashback</td>
    <td>Cashback as a loyalty program mainly encourages repeat customers by giving back a certain percentage of the amount spent and helping them save money on their next purchase. This helps businesses like you to retain your customers while enhancing the customer’s experience.</td>
</tr>
</table>

        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Eighteen">loyalty programs</h2>
        <div class="col-md-6">
        Build loyalty to strengthen engagement, enhance customer experience, and boost sales—free of additional cost.
            <br>
            <a href="/customer">Learn more about Loyalty Programs</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/loyalty programs.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Nineteen">Online Timings</h2>
        <div class="col-md-6">
        Enables and Disables menu in POS for Online display.
            <br>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Online Timings.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    
    <h1>FREE & AUTOMATIC UPDATES</h1>		
    <div class="row">
        <h2 id="Twenty">Free website</h2>
        <div class="col-md-6">
        Set up your Online Store within minutes using our user-friendly template.
Get Store POS E-commerce is incorporated perfectly into your Get Store POS system. This means you’ll never oversell and you’ll always know when to reorder and you can easily fulfil your orders – all ingrained in a single system.
<br>

With a single product catalogue, you can choose what to sell in-store, online, or both. You’ll never have to manage your business across multiple systems that don’t even interact with one another ever again. Sky-rocket your sales channel and keep your store open 24/7 by going online now!
<br>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/website.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    
    <div class="row">
        <h2 id="Twenty-one">Inventory management</h2>
        <div class="col-md-6">
        No more counting your stocks manually after each sale transaction! Minimize human-induced errors and manage your inventory and stock effortlessly using our platform. If you’re using Get Store POS Ecommerce, the best part is that it syncs with your offline and online inventory! This means you do not need to manually reduce a product quantity online if it was sold offline. Get Store POS does all of that for you!
            <br>
            <a href="/inventory">Learn more about Inventory Management</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Inventory-management1.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Twenty-two">Get automatic low stock alerts</h2>
        <div class="col-md-6">
Get notified automatically whenever you are running low on stock. This ensures that you never run out of your top selling products.<br>

Ultimately, this could help with better decision making as well. For instance, if sock A always runs out of stock quickly, apart from ordering more stocks, you should also focus on selling more designs that are similar so you can replicate your business success.
            <br>
            <a href="/kitchen">Learn more about Automatic low stock</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/alerts.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Twenty-three">View/Transfer inventory across multiple stores</h2>
        <div class="col-md-6">If you’re managing multiple stores, good news! You can share stock information across all your stores.<br>

You also get to view your inventory per store and transfer inventory between them. Whenever one of your stores has insufficient stock, you can easily control and coordinate the movement of your inventory between your stores.
<br>
            <a href="/multi-store">Learn more about Transfer Inventory</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/multiple stores.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Twenty-four">Manage your supplier relationships</h2>
        <div class="col-md-6">
Send or print purchase orders directly to your suppliers.<br>

Once your stock starts running low, you can easily order from your suppliers via e-mail directly from your Get Store POS BackOffice! To utilise this feature, make sure that all of the your products that you want to order <br>

have the names of their respective suppliers mentioned on the 'Manage Products' page and are tracking inventory as well.
            <br>
            <a href="/customer">Learn more about Supplier Relationships</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Smart Factories.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Twenty-five">Store Credits</h2>
        <div class="col-md-6">StoreHub’s Store Credits feature helps fulfill your Customer Relationship Management efforts. Collect customer data, reward them with Store Credits and procure their loyalty!
You can also assign store credits to specific items and define your default store credit-earning. For example: If you specify RM100 = 1 Store Credit, every RM100 spent at your store would give your customers 1 Store Credits. Your customer can then use the collected credits for their next purchase.
            <br>
            <a href="/kitchen">Learn more about Store Credits</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Store Credits.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Twenty-Six">Send SMS</h2>
        <div class="col-md-6">
If you have an existing customer database on yourGet Store POS BackOffice (alternatively, if you have an external list, import them into Get Store POS), send SMS in bulk to your customers. Remarketing is definitely cheaper than reaching out to new customers.<br>

Remain in touch with your customers. By sending SMS to your customers, you can update them regarding your latest promotions, personalised deals and show them that their convenience is your priority.
<br>
            <a href="/multi-store">Learn more about Send SMS</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/SMS.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>	
    <div class="row">
        <h2 id="Twenty-Seven">Manage employees</h2>
        <div class="col-md-6">
Add as many employees as you want! You can even customise sections of the BackOffice you want your particular employee to access. For example, if you do not want your cashier to access your stock information, you can deny his/her access by simply unchecking Stock Management.<br>

You can even monitor your staff’s activities to find out who your best salesperson is. Use this feature to greatly influence your staff performance and loyalty via a rewards system based on their sales.
            <br>
            <a href="/customer">Learn more about Manage employees</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/employees.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>		
    <div class="row">
        <h2 id="Twenty-Eight">Generate reports by transaction</h2>
        <div class="col-md-6">
You can export or generate your sales reports on a day, hour, shift, categories, and even promotions you’ve set.<br>

Understanding these reports can help you maximise your sales transactions. For example, if product category A is performing better than the rest, you can focus on expanding category A’s inventory while reducing the other categories’ or changing it up.<br>
            <a href="/customer">Learn more about Reports by Transaction</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/reports.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>	
    <div class="row">
        <h2 id="Twenty-Nine">Real-time reports</h2>
        <div class="col-md-6">
Want to know how your store is doing right now?<br>

Get Store POS BackOffice‘s dashboard enables you to monitor your live sales performance anytime, anywhere. It shows you the day's total sales, transactions, items sold and members present.<br>

There is also a quick overview on your total cumulative sales and your sales per store displayed as a graph.
<br>
            <a href="/employee">Learn more about Real-time reports</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Real-time-reports.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Thirty">Improved Store Operations</h2>
        <div class="col-md-6">
GGet Store POS end-to-end unified system provides live, comprehensive and up-to-date data regarding customers, inventory, sales, financials, and more.
            <br>
            <a href="/multi-store">Learn more about improved Store Operations</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Store-Operations.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Thirty-One">Cloud-based data management</h2>
        <div class="col-md-6">
Manage your stores and access sales, inventory, and employee data anytime, anywhere.
            <br>
            <a href="/dashboard">Learn more about Cloud-based</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Cloud-based.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Thirty-Two">No sales and in/out expenses</h2>
        <div class="col-md-6">
Get Store POS cash drawer opens automatically when a transaction is being processed  and if it’s connected to the till system. It might be the only way to open it for an employee, because if they need to take out cash for a purchase for the shop? Or out the change and expense receipt back in the cash drawer after that business purchase? Or simply check something in the cash drawer, count the cash float and so on.
            <br>
            <a href="/customer">Learn more about Out Expenses</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/No-sales.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Thirty-Three">Attributes, options, variants and modifiers</h2>
        <div class="col-md-6">
Apart from wider categories, the following may be applied to individual products:<br>

Attributes – product characteristics, e.g. Size, Colour, Type<br>
Options – choices within each characteristic, e.g. Black, Blue and Red within the Size attribute<br>
Variants – the code or identifying label of a product based on chosen characteristics, e.g. a blue medium jacket can have one SKU code that’s different from a small red jacket.<br>
Modifiers – option that changes the composition or/and price of the product, e.g. “with cream” can add 20p to the price of a latte
            <br>
            <a href="/multi-store">Learn more about Attributes</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Attributes.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Thirty-Four">Stock alerts</h2>
        <div class="col-md-6">
Get Store POS systems send email alerts upon being out of stock or running low on a product. Once you have less than 5 units of a given product, an email will be sent warning you of the depleting stock. The deficient item will be automatically hidden at the time of checking out.
            <br>
            <a href="/employee">Learn more about Stock alerts</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Stock-alerts.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Thirty-Five">Supplier management</h2>
        <div class="col-md-6">
Get Store POS systems include integrated supplier management. This system allows you to enter supplier details when you create the product.
            <br>
            <a href="/inventory">Learn more about Supplier Management</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/Supplier-management.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    <div class="row">
        <h2 id="Thirty-six">Integration with ecommerce</h2>
        <div class="col-md-6">
Get Store POS system's integration with one or more ecommerce platforms, which means that sales and products libraries can be synced across sales channels. This also means that in-store and online sales can be linked to the same inventory library, prices and sales reports, or separated depending on settings.
            <br>
            <a href="/employee">Learn more about Integration with ecommerce</a>
        </div>
        <div class="col-md-6">
        <img src="assets/media/general/ecommerce.png" alt="POS FEATURES"/>
        </div>
        <hr>
    </div>
    
  <p (click)="scroll('top')"><a href="javascript:void(0)">Top</a></p>
    <br>
    <br>
    <br>
</div>
</div>
</div>
  
  <app-footer></app-footer>
  
</div>
<!-- end layout-theme-->
