<style>
/**
 * Overlay
 * -- only show for tablet and up
 */

 @media screen and (min-width: 40em) {
    .modal-overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      background-color: rgba(0, 0, 0, 0.6);
      opacity: 0;
      visibility: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1), visibility 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    }
    .modal-overlay.active {
      opacity: 1;
      visibility: visible;
    }
  }
  /**
   * Modal
   */
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    background-color: #fff;
    width: 600px;
    max-width: 75rem;
    min-height: 20rem;
    padding: 1rem;
    border-radius: 3px;
    opacity: 0;
    overflow-y: auto;
    visibility: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transform: scale(1.2);
    transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  }
  .modal .close-modal {
    position: absolute;
    cursor: pointer;
    top: 5px;
    right: 15px;
    opacity: 0;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1), transform 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    transition-delay: 0.3s;
  }
  .modal .close-modal svg {
    width: 1.75em;
    height: 1.75em;
  }
  .modal .modal-content {
    opacity: 0;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    transition-delay: 0.3s;
  }
  .modal.active {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
  .modal.active .modal-content {
    opacity: 1;
  }
  .modal.active .close-modal {
    transform: translateY(10px);
    opacity: 1;
  }

  /**
   * Mobile styling
   */
  @media only screen and (max-width: 39.9375em) {
    h1 {
      font-size: 1.5rem;
    }

    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-overflow-scrolling: touch;
      border-radius: 0;
      transform: scale(1.1);
      padding: 0 !important;
    }

    .close-modal {
      right: 20px !important;
    }
  }
</style>

  <!-- ==========================-->
  <!-- MOBILE MENU-->
  <!-- ==========================-->
    <div data-off-canvas="mobile-slidebar left overlay">
        <ul class="yamm main-menu nav navbar-nav">
            <li class="dropdown"><a href="/">Home</a></li>
            <li><a href="/features">Feature</a></li>
            <li><a href="/hardware">Hardware</a></li>
            <li><a href="/pricing">Pricing</a></li>
            <li class="dropdown">
                <a href="javascript:void(0)" data-toggle="dropdown" class="dropdown-toggle">Business types<b class="caret"></b></a>
                <ul role="menu" class="dropdown-menu">
                    <li><a href="/restaurant" >Restaurant </a></li>
                    <li><a href="/cafe" >Cafe</a></li>
                    <li><a href="/bar" >Bar</a></li>
                    <li><a href="/groceries" >Groceries </a></li>
                    <li><a href="/fashion" >Fashion</a></li>
                    <li><a href="/retail" >Retail</a></li>
                </ul>
            </li>
            <li class="dropdown">
                <a href="javascript:void(0)" data-toggle="dropdown" class="dropdown-toggle">Products<b class="caret"></b></a>
                <ul role="menu" class="dropdown-menu">
                    <li><a href="/office" >Get Store Office</a></li>
                    <li><a href="/backoffice" >Back Office</a></li>
                    <li><a href="/dashboard" >Dashboard</a></li>
                    <li><a href="/kitchen" >Kitchen Display</a></li>
                    <li><a href="/customer" >Customer Display</a></li>
                    <li><a href="/employee" >Employee Management</a></li>
                    <li><a href="/multistore" >Multi-Store Management</a></li>
                    <li><a href="/inventory" >Advanced Inventory</a></li>
                </ul>
            </li>
            <li><a href="/marketplace">App Marketplace</a></li>
            <li><a href="/help">Help</a></li>
            <li><a href="/partners">Partners</a></li>
        </ul>
    </div>

<!-- ==========================-->
  <!-- FULL SCREEN MENU-->
  <!-- ==========================-->

    <header class="header header-boxed-width header-background-trans header-logo-black header-topbarbox-1-left header-topbarbox-2-right header-navibox-1-left header-navibox-2-right header-navibox-3-right header-navibox-4-right">
        <div class="top-bar">
            <div class="container container-boxed-width">
                <div class="container row">
                    <div class="header-topbarbox-1 col-md-6">
                        <ul class="top-bar-contact">
                        <li class="top-bar-contact__item"><i class="icon icon-call-in"></i> 040-46019092</li>
                        <li class="top-bar-contact__item"><i class="icon icon-envelope-open"></i> hr@getstorepos.com</li>
                        <li class="top-bar-contact__item"><i class="icon icon-clock"></i> Mon – Fri  9.00 am – 6.00 pm</li>
                        </ul>
                    </div>
                    <div class="header-topbarbox-2 col-md-6">
                        <div class="loginmenu col-xs-offset-2">
                            <ul>
                                <li><a href="/">Community</a></li>
                                <li><a href="/signup">Sign Up</a></li>
                                <li class="modalbtn" >
                                    <a href="javascript:void(0)" (click)="openModal(loginModal)">Login</a>
                                </li>
                            </ul>
                            <div class="clearfix"></div>
                        </div>
                        <!-- (click)="openModal('loginModal')" -->
                        <!-- <div class="Country float-right">
                            <form>
                                <select class="selectpicker" data-width="fit">
                                    <option  data-content='<span class="flag-icon flag-icon-my"></span> Malaysia <p class="flag-icon currency-malaysia"></p>'>Malaysia</option>
                                    <option  data-content='<span class="flag-icon flag-icon-in"></span> India <p class="flag-icon currency-india"></p>'>India</option>
                                    <option  data-content='<span class="flag-icon flag-icon-mc"></span> Indonesia <p class="flag-icon currency-indonesia"></p>'>Indonesia</option>
                                    <option  data-content='<span class="flag-icon flag-icon-sg"></span> Singapore <p class="flag-icon currency-singapore"></p>'>Singapore</option>
                                    <option  data-content='<span class="flag-icon flag-icon-lk"></span> Sri lanka <p class="flag-icon currency-srilanka"></p>'>Sri lanka</option>
                                </select>
                                <select class="selectpicker language" data-width="fit">	<option  data-content='English'>English</option>
                                    <option data-content='Hindi'>Hindi</option>
                                    <option  data-content='Malayu'>Malayu</option>
                                </select>
                            </form>
                        </div> -->

                        <!-- end social-list-->
                    </div>

                </div>
            </div>
        </div>

        <div class="container container-boxed-width">
            <nav id="nav" class="navbar">
                <div class="container">
                    <div class="header-navibox-1">
                        <!-- Mobile Trigger Start-->
                        <button class="menu-mobile-button visible-xs-block js-toggle-mobile-slidebar toggle-menu-button"><i class="toggle-menu-button-icon"><span></span><span></span><span></span><span></span><span></span><span></span></i></button>
                        <!-- Mobile Trigger End--><a href="/" class="navbar-brand scroll"><img src="assets/media/general/logo.png" alt="logo" class="normal-logo"/><img src="assets/media/general/logo-dark.png" alt="logo" class="scroll-logo hidden-xs"/></a>
                    </div>
                    <div class="header-navibox-3">
                        <a href="#" class="btn btn-default btn-xs">Get Started</a>
                    </div>
                    <div class="header-navibox-2">
                        <ul class="yamm main-menu nav navbar-nav">
                            <li class="dropdown"><a href="/">Home</a></li>
                            <li><a href="/features">Feature</a></li>
                            <li><a href="/hardware">Hardware</a></li>
                            <li><a href="/pricing">Pricing</a></li>
                            <li class="dropdown">
                                <a href="javascript:void(0)" data-toggle="dropdown" class="dropdown-toggle">Business types<b class="caret"></b></a>
                                <ul role="menu" class="dropdown-menu">
                                    <li><a href="/restaurant" >Restaurant </a></li>
                                    <li><a href="/cafe" >Cafe</a></li>
                                    <li><a href="/bar" >Bar</a></li>
                                    <li><a href="/groceries" >Groceries </a></li>
                                    <li><a href="/fashion" >Fashion</a></li>
                                    <li><a href="/retail" >Retail</a></li>
                                </ul>
                            </li>
                            <li class="dropdown">
                                <a href="javascript:void(0)" data-toggle="dropdown" class="dropdown-toggle">Products<b class="caret"></b></a>
                                <ul role="menu" class="dropdown-menu">
                                    <li><a href="/office" >Get Store Office</a></li>
                                    <li><a href="/back-office" >Back Office</a></li>
                                    <li><a href="/dashboard" >Dashboard</a></li>
                                    <li><a href="/kitchen" >Kitchen Display</a></li>
                                    <li><a href="/customer" >Customer Display</a></li>
                                    <li><a href="/employee" >Employee Management</a></li>
                                    <li><a href="/multi-store" >Multi-Store Management</a></li>
                                    <li><a href="/inventory" >Advanced Inventory</a></li>
                                </ul>
                            </li>
                            <li><a href="/marketplace">App Marketplace</a></li>
                            <li><a href="/help">Help</a></li>
                            <li><a href="/partners">Partners</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>

    </header>
  <!-- end .header-->

  <ng-template #loginModal let-modal>
    <!-- modal -->

	<div class="modal-header" style="display: flex; justify-content: space-between;">
		<h5 style="color: #ef7f1a;" class="modal-title text-center" id="myModalLabel160">
            Enter your store URL or Email
		</h5>
		<button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body" tabindex="0" ngbAutofocus>
		<ngb-alert [type]="'danger'" style="margin:0;padding:0" [dismissible]="false" *ngIf="error">
			<div class="alert-body">
				<p>{{ error }}.<br><a class="link text-info" style="text-decoration: underline;"
						(click)="register()"><i>Click Here to create new store.</i></a></p>
			</div>
		</ngb-alert>
		<form class="text-center p-2">
            <div class="pt-input-group">
                <input [(ngModel)]="domainVar" type="text" class="pt-input" placeholder="Store URL" name="domain">
                <span class="pt-input-action">.{{'envUrl' | envName }}</span>
            </div>
			<!-- <div class="form-group row p-2">
				<div style="box-shadow: none;padding: 0 16px;" class="input-group col-sm-12 mb-2">
					<input [(ngModel)]="domainVar" type="text" class="form-control" placeholder="Store URL" name="domain" />
					<span class="input-group-append">
						<span class="input-group-text" id="basic-addon2">.{{'envUrl' | envName }}</span>
					</span>
				</div>
			</div> -->
			<h4 class="text-info">OR</h4>

			<div class="pt-input-group row p-2 ">
				<div class="col-sm-12">
					<input [(ngModel)]="emailVar" type="text" class="form-control" placeholder="Email"
						aria-label="user email" name="email" aria-describedby="basic-addon2" />
				</div>
			</div>

			<div class="col-sm-12">
				<button style="color: white;background-color: #ef7f1a;" (click)="login()" type="button"
					class="btn proceed-btn text-center" rippleEffect>
					Proceed
				</button>
			</div>
		</form>
	</div>
</ng-template>


<script>
</script>
