<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">



  <app-navbar></app-navbar>

  <div id="main-slider" data-slider-width="100%" data-slider-height="500px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider slider-pro">
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide"><img src="assets/media/components/b-main-slider/product-bg-3.jpg" alt="slider" class="sp-image"/>
        <div class="container">
          <div class="row">
            <div class="col-sm-10 col-sm-offset-1">
              <div data-width="100%" data-show-transition="right" data-hide-transition="left" data-show-duration="1200" data-show-delay="2000" data-hide-delay="400" data-vertical="350px" data-horizontal="300" class="main-slider__item-1 sp-layer"><a href="#" ><img src="assets/media/general/gplay-en.png" alt="Item"/></a></div>

<div data-width="100%" data-show-transition="left" data-hide-transition="right" data-show-duration="1200" data-show-delay="2000" data-hide-delay="400" data-vertical="350px" data-horizontal="600" class="main-slider__item-1 sp-layer"><a href="#" ><img src="assets/media/general/appstore-en.png" alt="Item"/></a></div>

              <h2 data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="800" data-show-delay="400" data-hide-delay="400" class="main-slider__title sp-layer">Get Store Dashboard</h2>
              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="1200" data-show-delay="600" data-hide-delay="400" class="sp-layer">
                <div class="main-slider__decor bg-primary"></div>
              </div>
            <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="1200" data-hide-delay="400" class="main-slider__info sp-layer"><strong>Instant access to your store’s most important data</strong><br>Loyverse Dashboard helps you instantly analyze your store’s sales, and track inventory directly from your smartphone anytime, anywhere. Complementing the Loyverse POS app, it shows vital information about your business at your fingertips in real-time, allowing you to make important decisions immediately.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end .main-slider-->
<!-- START THE NAVBAR -->
<section class="retail">
<div class="container">
<div class="row text-center">
<div class="col-sm-8 col-sm-offset-2 newp">
<h1>Get Your Store's Statistics Anytime, Anywhere</h1>
<br>
<p>Whether you're on holiday, a business trip, or just need to visit a nearby supermarket for 10 minutes, you'll have real-time access to inventory and sales data at hand at all times.</p>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row">
<div class="col-sm-10 col-sm-offset-1 newp pb-2 text-center">
<div class="ui-decor-1"><img src="assets/media/general/ui-decor-1.png" alt="decor" class="center-block"></div>
<h1>Features</h1>
</div>
<div class="col-sm-10 col-sm-offset-1">
<div class="col-md-6">
    <h1>Sales by item</h1>
    <p>Determine which items are performing well on average or underperforming to increase customer satisfaction levels.</p>

    <h1>Sales by category</h1>
    <p>Cakes, roses, baguettes, t-shirts... every type of shops will benefit from Loyverse Dashboard, which allows you to see which categories are selling the best and which needs offers or discounts to attract further attention.</p>

</div>
<div class="col-md-6">
    <img src="assets/media/general/sales-summary.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <img src="assets/media/general/sales-by-item-2.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <h1>Sales summary</h1>
    <p>Analyze store growth as you assess the revenue, average sales, and profit. Create informed and innovative business strategies continuously to prevent business stagnation.</p>

    <h1>Sales trend</h1>
    <p>Track sales growth by comparing with previous days, weeks, months, or years and make critical decisions that would enable a long-lasting business legacy.</p>
</div>
<div class="clearfix"></div>
<div class="col-md-6">
    <h1>Sales by employee</h1>
    <p>Find out who is performing well and who needs more training based on real-time data.</p>

    <h1>Item stock</h1>
    <p>View stock levels and apply filters to inform yourself when items are running low or are all out. This way you'll never leave your customers disappointed.</p>

    <h1>Stock push-notifications</h1>
    <p>Get notifications in real-time on items that are running low or are out of stock. Your smartphone and tablet have never been this practical!</p>

</div>
<div class="col-md-6">
    <img src="assets/media/general/item-stock.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</div>
</section>


<div class="container retail">
<div class="row">
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_green section-radius" style="background-position: -62.7px 20.4px;">
            <div class="b-contact__name">Website</div>
            <div class="b-contact__info">{{'envUrl' | envName }}</div>
            <div class="b-contact__icon icon-globe"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_blue section-radius" style="background-position: -296.7px 20.4px;">
            <div class="b-contact__name">Phone</div>
            <div class="b-contact__info"> +60 3-7803 4040</div>
            <div class="b-contact__icon icon-call-in"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_grey section-radius" style="background-position: -530.7px 20.4px;">
            <div class="b-contact__name">Email</div>
            <div class="b-contact__info">getstore@gmail.com</div>
            <div class="b-contact__icon icon-envelope-open"></div>
          </div>
          <!-- end b-contact-->
        </div>
      </div>
</div>

  <section class="b-taglines area-bg bg-primary_a parallax">
    <div class="area-bg__inner">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 col-sm-offset-3">
            <div class="b-taglines__inner">
              <h1 class="">Download Get Store POS</h1>
              <div class="b-taglines__text col-sm-offset-1">For iPhone, iPad, Android smartphone or tablet</div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/gplay-en.png" alt="foto" class="img-responsive"/></a></div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/appstore-en.png" alt="foto" class="img-responsive"/></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>

</div>
<!-- end layout-theme-->

<!-- //div fix-->
<script>
$(document).ready(function() {
$('.nav li a').click(function(e) {
    $('.nav li').removeClass('active');
    var $parent = $(this).parent();
    console.log($parent);
    if (!$parent.hasClass('active')) {
        $parent.addClass('active');
    }
    e.preventDefault();
});
})
</script>
