<footer class="footer">
  <div class="footer__main">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <div class="text-center">
            <a href="home.html" class="footer__logo"
              ><img
                src="assets/media/general/logo.png"
                alt="Logo"
                class="img-responsive"
            /></a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 col-md-offset-2">
          <form class="footer-form">
            <div class="row">
              <div class="col-sm-5">
                <h3 class="footer-form__title">Get the FREE Newsletter</h3>
                <div class="footer-form__info">
                  Sign up to get the updates about new events
                </div>
              </div>
              <div class="col-sm-7">
                <div class="form-group">
                  <input
                    type="email"
                    placeholder="Your email address ..."
                    class="footer-form__input"
                  />
                  <button class="footer-form__btn form-control-feedback">
                    <i class="icon icon-envelope-open text-primary_h"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="footer-section">
            <h3 class="footer-section__title ui-title-inner">
              <i class="ui-decor-2 bg-primary"></i> About Get Store
            </h3>
            <div class="footer-section__subtitle">
              GET Store point of sale and management system
            </div>
            <div class="footer__info">
              <p>
                GET Store POS is that comes loaded with all the features that
                modernised and simplify operations, increase sales, and delight
                guests.
              </p>
            </div>
            <a href="/features" class="btn btn-default btn-xs"
              ><i class="icon"></i> Read More</a
            >
          </div>
        </div>
        <div class="col-md-3">
          <section class="footer-section">
            <h3 class="footer-section__title ui-title-inner">
              <i class="ui-decor-2 bg-primary"></i> Keep In Touch
            </h3>
            <div class="footer__contact">
              <i class="icon icon-map"></i> FeatureZen Innovation Techniques Pvt. Ltd, 1-57
              /545/B, Sri Ram Castle, Flat No. 401, Sri Ram Nagar, B Block, Kondapur, Hyderabad 500084
            </div>
            <div class="footer__contact">
              <i class="icon icon-call-in"></i> 040-46019092
            </div>
            <div class="footer__contact">
              <i class="icon icon-envelope-open"></i> hr@getstorepos.com
            </div>
            <div class="footer__contact">
              <i class="icon icon-clock"></i> Mon - Fri 9.00 am - 6.00 pm
            </div>
          </section>
        </div>
        <div class="col-md-3">
          <section class="footer-section">
            <h3 class="footer-section__title ui-title-inner">
              <i class="ui-decor-2 bg-primary"></i>Gallery
            </h3>
            <ul class="footer-gallery list-unstyled js-zoom-gallery clearfix">
              <li class="footer-gallery__item">
                <a
                  href="assets/media/components/footer/gallery-1.jpg"
                  class="footer-gallery__link js-zoom-gallery__item"
                  ><img
                    src="assets/media/components/footer/gallery-1.jpg"
                    alt="foto"
                    class="img-responsive"
                /></a>
              </li>
              <li class="footer-gallery__item">
                <a
                  href="assets/media/components/footer/gallery-2.jpg"
                  class="footer-gallery__link js-zoom-gallery__item"
                  ><img
                    src="assets/media/components/footer/gallery-2.jpg"
                    alt="foto"
                    class="img-responsive"
                /></a>
              </li>
              <li class="footer-gallery__item">
                <a
                  href="assets/media/components/footer/gallery-3.jpg"
                  class="footer-gallery__link js-zoom-gallery__item"
                  ><img
                    src="assets/media/components/footer/gallery-3.jpg"
                    alt="foto"
                    class="img-responsive"
                /></a>
              </li>
              <li class="footer-gallery__item">
                <a
                  href="assets/media/components/footer/gallery-4.jpg"
                  class="footer-gallery__link js-zoom-gallery__item"
                  ><img
                    src="assets/media/components/footer/gallery-4.jpg"
                    alt="foto"
                    class="img-responsive"
                /></a>
              </li>
              <li class="footer-gallery__item">
                <a
                  href="assets/media/components/footer/gallery-5.jpg"
                  class="footer-gallery__link js-zoom-gallery__item"
                  ><img
                    src="assets/media/components/footer/gallery-5.jpg"
                    alt="foto"
                    class="img-responsive"
                /></a>
              </li>
              <li class="footer-gallery__item">
                <a
                  href="assets/media/components/footer/gallery-6.jpg"
                  class="footer-gallery__link js-zoom-gallery__item"
                  ><img
                    src="assets/media/components/footer/gallery-6.jpg"
                    alt="foto"
                    class="img-responsive"
                /></a>
              </li>
            </ul>
          </section>
        </div>
        <div class="col-md-3">
          <section class="footer-section">
            <h3 class="footer-section__title ui-title-inner">
              <i class="ui-decor-2 bg-primary"></i> Quick Links
            </h3>
            <ul class="footer-list list list-mark-4 list-unstyled">
              <li class="footer-list__item">
                <a href="/features" class="footer-list__link">Our Feature</a>
              </li>
              <li class="footer-list__item">
                <a href="team.html" class="footer-list__link">Our Team</a>
              </li>
              <li class="footer-list__item">
                <a href="hardware.html" class="footer-list__link"
                  >About Get Store</a
                >
              </li>
              <li class="footer-list__item">
                <a href="clients.html" class="footer-list__link"
                  >Clients List</a
                >
              </li>
              <li class="footer-list__item">
                <a href="blog.html" class="footer-list__link">News Blog</a>
              </li>
              <li class="footer-list__item">
                <a href="#" class="footer-list__link">Brochure</a>
              </li>
              <li class="footer-list__item">
                <a href="contact.html" class="footer-list__link"
                  >Get In Touch</a
                >
              </li>
              <li class="footer-list__item">
                <a href="career.html" class="footer-list__link">Career</a>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  </div>
  <div class="footer__bottom">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <div class="copyright pull-left">
            © 2021<strong> Get Store</strong> - All Rights Reserved.<a
              href="/terms-and-condition"
              class="copyright__link"
            >
              Terms of Use</a
            ><a href="/privacy-policy" class="copyright__link"
              >Privacy Policy</a
            >
          </div>
          <ul class="social-net list-inline pull-right">
            <li class="social-net__item">
              <a href="youtube.com" class="social-net__link text-primary_h"
                ><i class="icon fa fa-youtube"></i
              ></a>
            </li>
            <li class="social-net__item">
              <a href="twitter.com" class="social-net__link text-primary_h"
                ><i class="icon fa fa-twitter"></i
              ></a>
            </li>
            <li class="social-net__item">
              <a href="facebook.com" class="social-net__link text-primary_h"
                ><i class="icon fa fa-facebook"></i
              ></a>
            </li>
            <li class="social-net__item">
              <a href="plus.google.com" class="social-net__link text-primary_h"
                ><i class="icon fa fa-google-plus"></i
              ></a>
            </li>
            <li class="social-net__item">
              <a href="instagram.com" class="social-net__link text-primary_h"
                ><i class="icon fa fa-instagram"></i
              ></a>
            </li>
          </ul>
          <!-- end social-list-->
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- .footer-->
