<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">



  <app-navbar></app-navbar>

  <div id="main-slider" data-slider-width="100%" data-slider-height="650px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider slider-pro">
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide"><img src="assets/media/components/b-main-slider/product-bg-7.jpg" alt="slider" class="sp-image"/>
        <div class="container">
          <div class="row">
            <div class="col-sm-10 col-sm-offset-1">
              <div data-width="100%" data-show-transition="right" data-hide-transition="left" data-show-duration="1200" data-show-delay="2000" data-hide-delay="400" data-vertical="250px" data-horizontal="170" class="main-slider__item-1 sp-layer"><a href="#" ><img src="assets/media/general/devices.png" alt="Item"/></a></div>

              <h2 data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="800" data-show-delay="400" data-hide-delay="400" class="main-slider__title sp-layer">Employee Management and Timecards Software</h2>
              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="1200" data-show-delay="600" data-hide-delay="400" class="sp-layer">
                <div class="main-slider__decor bg-primary"></div>
              </div>
            <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="1200" data-hide-delay="400" class="main-slider__info sp-layer"><strong>Improve your employee management and increase their performance</strong></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end .main-slider-->
<!-- START THE NAVBAR -->
<section class="retail">
<div class="container">
<div class="row">
<div class="col-sm-10 col-sm-offset-1 newp text-center">
<h1>Access settings</h1>
    <p>Manage employee access to sensitive information and functions</p>
</div>
<br><br>
<div class="col-md-6">
<ul>
<li>Create user groups with different access and assign employees to them</li>

<li>Restrict access of your employees to sensitive information and functions</li>

<li>Give each employee access to the POS app by PIN or password</li>

<li>You can set access rights to void saved items in open tickets</li>
</ul>

</div>
<div class="col-md-6">
    <img src="assets/media/general/accessrights.png" alt="foto" class="img-responsive"/>
</div>

</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row text-center">
<div class="col-sm-10 col-sm-offset-1 newp">
<div class="ui-decor-1"><img src="assets/media/general/ui-decor-1.png" alt="decor" class="center-block"></div>
<h1>Sales by employees</h1>
<br>
<br>
    <img src="assets/media/general/sales-by-employee.png" alt="foto" class="img-responsive col-sm-offset-1"/>
</div>
</div>
    <ul class="col-sm-10 col-sm-offset-2">
        <li>Use sales reports in the back office to see the sales of each employee</li>
        <li>Back office allows you to filter reports by employee, store and time period</li>
        <li>Download your reports in CSV files</li>
    </ul>
</div>
</section>

<section class="section-default retail">
<div class="container">
<div class="row">
    <div class="col-sm-10 col-sm-offset-1 newp text-center">
<h1>Check employee’s efficiency</h1>
    <p>Information on the workload per hour allows for efficient management of your staff</p><br>
        <img src="assets/media/general/totalhours.png" alt="foto" class="img-responsive"/>
</div>
<!--  product-->
<div class="col-md-6">
    <img src="assets/media/general/timecard.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <br><br>
    <ul>
<li>Employee can clock in with his/her PIN at the beginning of the shift and the system starts counting time</li>

<li>At the end of the day Employee has to clock out with PIN and the system stops counting hours of work</li>

<li>Total hours are automatically calculated based on clock in and clock out time, the owner can correct the timecard or create a new at the back office</li>

<li>At the back office the owner can see the total hours worked summary report of each employee in the chosen period</li>
</ul>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row">
<div class="col-sm-10 col-sm-offset-1 newp text-center">
<span class="stroke icon-Speaker2"></span>
<h1>Protect your business</h1>
    <p>Identify any cash discrepancy at the end of the shift and track employees’ inaccuracy or manipulations</p>
<br>
    <img src="assets/media/general/shifts.png" alt="foto" class="img-responsive col-sm-offset-1"/>
    <br>
</div>
<div class="col-sm-10 col-sm-offset-2">
<ul>
<li>Set up whether your cashier should see the expected amount of cash or not, during and when closing shift</li>

<li>The employee have to key in the real amount of cash in the cash drawer</li>

<li>The owner can see this two numbers and difference between them</li>
</ul>
</div>
</div>
</div>
</section>

<section class="section-default retail">
<div class="container">
<div class="row">
    <div class="col-sm-10 col-sm-offset-1 newp text-center">
<h1>Pricing</h1>
<div class="pane-content-inner">
            <div id="guides-tabs">
            <!-- Nav tabs -->
        <ul class="nav nav-tabs tabs-group top col-sm-offset-1">
        <li class="study-inn active" tabindex="0">
            <a data-toggle="tab" data-target="#1" class="box box-1 jquery-once-6-processed" aria-expanded="true"><span tabindex="0" class="text">Pay monthly</span></a>
        </li>
        <li class="study-inn" tabindex="1">
            <a data-toggle="tab" data-target="#2" class="box box-2 jquery-once-6-processed" aria-expanded="false"><span tabindex="1" class="text">Pay annually</span></a>
        </li>
    </ul>
<div class="clearfix"></div>
        <!-- Tab panes -->
        <div class="tab-content">
          <div id="1" class="tab-pane active">
            <div class="add-onsul">
                <ul>
                <li>
                    <h3>$5 USD/month</h3>
                      <p>per employee<br>14 day free trial</p>
                  </li>
                </ul>
                <div class="clearfix"></div>
              </div>
            </div>


          <div id="2" class="tab-pane">
            <div class="add-onsul">
                <ul>
                <li>
                    <h3>$50 USD/month</h3>
                      <p>per employee<br>14 day free trial</p>
                  </li>
                </ul>
                <div class="clearfix"></div>
              </div>
            </div>

        </div>
    </div>
    </div>
</div>
</div>
</div>
</section>

<div class="container retail">
<div class="row">
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_green section-radius" style="background-position: -62.7px 20.4px;">
            <div class="b-contact__name">Website</div>
            <div class="b-contact__info">{{'envUrl' | envName }}</div>
            <div class="b-contact__icon icon-globe"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_blue section-radius" style="background-position: -296.7px 20.4px;">
            <div class="b-contact__name">Phone</div>
            <div class="b-contact__info"> +60 3-7803 4040</div>
            <div class="b-contact__icon icon-call-in"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_grey section-radius" style="background-position: -530.7px 20.4px;">
            <div class="b-contact__name">Email</div>
            <div class="b-contact__info">getstore@gmail.com</div>
            <div class="b-contact__icon icon-envelope-open"></div>
          </div>
          <!-- end b-contact-->
        </div>
      </div>
</div>

  <section class="b-taglines area-bg bg-primary_a parallax">
    <div class="area-bg__inner">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 col-sm-offset-3">
            <div class="b-taglines__inner">
              <h1 class="">Download Get Store POS</h1>
              <div class="b-taglines__text col-sm-offset-1">For iPhone, iPad, Android smartphone or tablet</div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/gplay-en.png" alt="foto" class="img-responsive"/></a></div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/appstore-en.png" alt="foto" class="img-responsive"/></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>

</div>
<!-- end layout-theme-->

<!-- //div fix-->
<script>
$(document).ready(function() {
    $('.nav li a').click(function(e) {
        $('.nav li').removeClass('active');
        var $parent = $(this).parent();
        console.log($parent);
        if (!$parent.hasClass('active')) {
            $parent.addClass('active');
        }
        e.preventDefault();
    });
})
</script>
