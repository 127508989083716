<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">
  
  

  <app-navbar></app-navbar>  
  
<div id="main-slider" data-slider-width="100%" data-slider-height="300px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider main-slider_mod-a slider-pro">
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide"><img src="assets/media/components/b-main-slider/bg-12.jpg" alt="slider" class="sp-image"/>
        <div class="container">
          <div class="row">
            <div class="col-md-8">
                
              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="2200" data-hide-delay="400" class="main-slider__info sp-layer"><img src="assets/media/general/partner.png" alt="slider"/></div>
            </div>
          </div>
        </div>
      </div>
      <!-- Slide 2-->
      
    </div>
  </div>
  <!-- end .main-slider-->
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="section-area">
          <div class="b-request-estimate">
            <div class="b-request-estimate__info">GET Store POS is  that comes loaded with all the features that modernised and simplify operations, increase sales, and delight guests.</div>
            <div class="b-request-estimate__title bg-primary"><span class="ui-decor-2"></span>Partnership With Great Benefits & Rewards!</div>
          </div>
          <!-- end .b-request-estimate-->
        </div>
      </div>
    </div>
  </div>
<!-- START THE NAVBAR -->
  <!-- end .main-slider-->
  <section class="section-default">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-lg-offset-2">
          <div class="ui-decor-1"><img src="assets/media/general/ui-decor-1.png" alt="decor" class="center-block"></div>
          <div class="text-center">
            <h2 class="ui-title-block ui-title-block_lg">Welcome to<span class="text-primary"> Get Store</span></h2>
            <div class="ui-subtitle-block">It has been a great experience working with Get Store as they have been going above and beyond to take care our customers.</div>
          </div>
        </div>
      </div>
    </div>
<div class="block-table block-table-lg">
    <div class="block-table__cell col-lg-6">
      <section class="section-type-2 bg-grey">
        <h2 class="ui-title-block">Why Choose<span class="text-primary"> Get Store</span></h2>
        <div class="ui-subtitle-block">GET Store POS that comes loaded with all the features that modernised and simplify operations, increase sales, and delight guests.</div>
        <section class="b-advantages-2 b-advantages-2_mod-a"><i class="b-advantages-2__icon stroke icon-UploadCLoud"></i>
          <div class="b-advantages-2__inner">
            <h3 class="b-advantages-2__title ui-title-inner bg-primary_b">Cloud Partner Portal</h3>
          </div>
        </section>
        <!-- end .b-advantages-->
        <section class="b-advantages-2 b-advantages-2_mod-a"><i class="b-advantages-2__icon stroke icon-Rolodex"></i>
          <div class="b-advantages-2__inner">
            <h3 class="b-advantages-2__title ui-title-inner bg-primary_b">View & manage clients</h3>
          </div>
        </section>
        <!-- end .b-advantages-->
        <section class="b-advantages-2 b-advantages-2_mod-a"><i class="b-advantages-2__icon stroke icon-Pointer"></i>
          <div class="b-advantages-2__inner">
            <h3 class="b-advantages-2__title ui-title-inner bg-primary_b">Track your sales performance and commission</h3>
          </div>
        </section>
        <!-- end .b-advantages-->
      </section>
    </div>
    <div class="block-table__cell col-lg-6"><img src="assets/media/content/960x783/1.jpg" alt="foto"></div>
  </div>
<div class="block-table block-table-md">
    <div class="block-table__cell col-md-6"><img src="assets/media/content/960x750/2.jpg" alt="foto"></div>
    <div class="block-table__cell col-md-6">
      <section data-stellar-background-ratio="0.4" class="section-form-contact section-form-contact_color_white section-texture bg-primary stellar" style="background-position: -404.7px -118.547px;">
        <h2 class="ui-title-block"><span>Get Store</span> Partners Form</h2>
        <div id="success"></div>
        <form id="contactForm" action="#" method="post" class="b-form-contacts ui-form">
          <div class="row">
            <div class="col-md-6">
              <input id="user-name" type="text" name="user-name" placeholder="Full Name" required="required" class="form-control">
              <input id="user-phone" type="tel" name="user-phone" placeholder="Phone" class="form-control">
              <select class="selectpicker form-control">
                <option  data-content='Select Country'>Select Country</option>
                  <option  data-content='Malaysia'>Malaysia</option>
                <option data-content='India'>India</option>
                <option  data-content='Indonesia'>Indonesia</option>
                <option  data-content='Singapore'>Singapore</option>
                <option  data-content='Sri lanka'>Sri lanka</option>
            </select>
            </div>
            <div class="col-md-6">
              <input id="user-email" type="email" name="user-email" placeholder="Email" class="form-control">
            <input id="company-name" type="text" name="company-name" placeholder="Company Name" required="required" class="form-control">
              <select class="selectpicker form-control">
                <option  data-content='Business Types'>Business Types</option>
                  <option  data-content='Restaurant'>Restaurant</option>
                <option data-content='Cafe'>Cafe</option>
                <option  data-content='Bar'>Bar</option>
                <option  data-content='Groceries'>Groceries</option>
                <option  data-content='Fashion'>Fashion</option>
                <option  data-content='Retail'>Retail</option>
            </select>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <textarea id="user-message" rows="3" placeholder="Message ..." required="required" class="form-control"></textarea>
              <button class="btn btn-default">Send Message</button>
            </div>
          </div>
        </form>
        <!-- end .b-form-contact-->
        
      </section>
    </div>
  </div>
  </section>
<section class="b-advertisement area-bg area-bg_dark area-bg_op_70 parallax">
    <div class="area-bg__inner">
      <div class=""><img src="assets/media/general/partner-2.png" alt="decor"></div>
      <h2 class="b-advertisement__title ui-title-block">Let’s start an amazing</h2>
      <div class="b-advertisement__info">partnership together!</div>
    </div>
  </section>
  
  <app-footer></app-footer>
  
</div>
<!-- end layout-theme-->

