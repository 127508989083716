import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hardware',
  templateUrl: './hardware.component.html',
  styleUrls: ['./hardware.component.css']
})
export class HardwareComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  scroll(val: String) {
    document.querySelector('#'+val).scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

}
