<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">
  
  <app-navbar></app-navbar>  
    
    <div id="main-slider" data-slider-width="100%" data-slider-height="300px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider main-slider_mod-a slider-pro">
      <div class="sp-slides">
        <!-- Slide 1-->
        <div class="sp-slide"><img src="../../../../../../assets/media/components/b-main-slider/bg-3.jpg" alt="slider" class="sp-image img-responsive"/>
          <div class="container">
            <div class="row">
              <div class="col-md-8">
        
                <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="2200" data-hide-delay="400" class="main-slider__info sp-layer"><img src="../../../assets/media/general/HARDWARE.png" alt="slider"/></div>
              </div>
            </div>
          </div>
        </div>
        <!-- Slide 2-->
        
      </div>
    </div>
    <!-- end .main-slider-->
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <div class="section-area">
            <div class="b-request-estimate">
              <div class="b-request-estimate__info">GET Store POS is  that comes loaded with all the features that modernised and simplify operations, increase sales, and delight guests.</div>
              <div class="b-request-estimate__title bg-primary"><span class="ui-decor-2"></span>POS Hardware</div>
            </div>
            <!-- end .b-request-estimate-->
          </div>
        </div>
      </div>
    </div>
    
  <!-- START THE NAVBAR -->

  <div class="container tabmenu">
    <div class="row" id="wrapperone">

      <div class="row">
        
        <div class="col-md-3" id="sticky">
          <ul>
            <li><h1>Hardware</h1></li>
            <li class="block-1 active" (click)="scroll('block-1')"><a href="javascript:void(0)">Receipt printer</a></li>
            <li class="block-5" (click)="scroll('block-5')"><a href="javascript:void(0)">Cash Drawers</a></li>
            <li class="block-3" (click)="scroll('block-3')"><a href="javascript:void(0)">Label printers</a></li>
            <li class="block-2" (click)="scroll('block-2')"><a href="javascript:void(0)">Terminals Receipt printers</a></li>
            <li class="block-4" (click)="scroll('block-4')"><a href="javascript:void(0)">Scanners</a></li>
            <li class="block-6" (click)="scroll('block-6')"><a href="javascript:void(0)">ipad / Tab</a></li>
            <li class="block-7" (click)="scroll('block-7')"><a href="javascript:void(0)">Rauter</a></li>
          </ul>
        </div>
 
  <!-- END NAVBAR -->

<div class="col-md-9 features">

  <!-- POS printers -->
  
  <div class="row" id="block-1">
  
    <h2>Receipt printer</h2>
  
    <div class="pane-content-inner">
  
      <div id="guides-tabs">
  
        <!-- Nav tabs -->
  
        <ul class="nav nav-tabs tabs-group top pull-right">
          <li class="study-inn" tabindex="0" class="tab tab-1 ">
              <a data-toggle="tab" data-target="#1" class="box box-1 jquery-once-6-processed"><span tabindex="0" class="text">All</span></a>
          </li>
          <li class="study-inn" tabindex="1" class="tab tab-2">
              <a data-toggle="tab" data-target="#2" class="box box-2 jquery-once-6-processed"><span tabindex="1" class="text">Android</span></a>
          </li>
          <li class="study-inn" tabindex="2" class="tab tab-3">
              <a data-toggle="tab" data-target="#3" class="box box-3 jquery-once-6-processed"><span tabindex="2" class="text">iOS</span></a>
          </li>
        </ul>
    
        <!-- Tab panes -->
    
        <div class="tab-content">
    
          <div id="1" class="tab-pane active" >
    
            <div class="col-md-6">
    
              <div class="col-md-4"><img src="../../../../../../assets/tab/star-mpop.jpg" alt="printers" class="sp-image"/></div>
    
              <div class="col-md-8">
    
                <h3>Receipt printer-1</h3>
    
                <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
    
                <p>The Receipt printer is a combined Bluetooth POS receipt printer and cash drawer.</p>
    
                <!-- <p> -->
    
                  <ul>
    
                    <li><i class="i-img"><img src="../../../assets/tab/bluetooth.svg" alt="printers" class="sp-image"/></i>Bluetooth</li>
    
                  </ul> 
    
                <!-- </p> -->
    
              </div>
    
            </div>
    
            <div class="col-md-6">
        
              <div class="col-md-4"><img src="../../../assets/tab/tsp143iiiu.jpg" alt="printers" class="sp-image"/></div>
        
              <div class="col-md-8">
        
                <h3>Receipt printer-2</h3>
        
                <span class="pull-right iconimg"><img src="../../../assets/tab/apple-pict.png" alt="printers" class="sp-image"/></span>
        
                <p>Enables reliable USB communication and simultaneous charging with an iPad or an iPhone.</p>
        
                <!-- <p> -->
        
                  <ul>
        
                    <li><i class="i-img"><img src="../../../assets/tab/usb.svg" alt="printers" class="sp-image"/></i>USB</li>
        
                  </ul>
        
                <!-- </p> -->
        
              </div>
      
            </div>
    
          </div>
    
          <div id="2" class="tab-pane">
    
            <div class="col-md-6">
    
              <div class="col-md-4"><img src="../../../assets/tab/star-mpop.jpg" alt="printers" class="sp-image"/></div>
    
              <div class="col-md-8">
    
                <h3>Receipt printer</h3>
    
                <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
    
                <p>The Receipt printer is a combined Bluetooth POS receipt printer and cash drawer.</p>
    
                <!-- <p> -->
    
                  <ul>
    
                    <li><i class="i-img"><img src="../../../assets/tab/bluetooth.svg" alt="printers" class="sp-image"/></i>Bluetooth</li>
    
                  </ul>
    
                <!-- </p> -->
    
              </div>
    
            </div>
    
            <div class="col-md-6">
    
              <div class="col-md-4"><img src="../../../assets/tab/star-mc-print3.jpg" alt="printers" class="sp-image"/></div>
    
              <div class="col-md-8">
    
                <h3>Receipt printer</h3>
    
                <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
    
                <p>Offers exceptional functionality with up to 5 interfaces including Star CloudPRNT.</p>
    
                <!-- <p> -->
    
                  <ul>
    
                    <li><i class="i-img"><img src="../../../assets/tab/ethernet.svg" alt="printers" class="sp-image"/></i>Ethernet</li>
    
                    <li><i class="i-img"><img src="../../../assets/tab/bluetooth.svg" alt="printers" class="sp-image"/></i>Bluetooth</li>
    
                    <li><i class="i-img"><img src="../../../assets/tab/usb.svg" alt="printers" class="sp-image"/></i>USB</li>
                  </ul>
                  
                <!-- </p> -->
    
              </div>
    
            </div>
    
          </div>
    
    <div id="3" class="tab-pane">
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/tsp143iiiu.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Receipt printer</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Enables reliable USB communication and simultaneous charging with an iPad or an iPhone.</p>
          <!-- <p> -->
          <ul>
            <li><i class="i-img"><img src="../../../assets/tab/usb.svg" alt="printers" class="sp-image"/></i>USB</li>
          </ul>
          <!-- </p> -->
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/star-sp-700.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Receipt printer</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Built to work in environments where heat and humidity can erode the type on the kitchen order.</p>
          <!-- <p> -->
          <ul>
            <li><i class="i-img"><img src="../../../assets/tab/ethernet.svg" alt="printers" class="sp-image"/></i>Ethernet</li>
          </ul> 
        <!-- </p> -->
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
</div>

  <!-- end POS printers -->
<div class="clearfix"></div>
<!-- Cash Drawers -->
  <div class="row" id="block-5">
    <h2>Cash Drawers</h2>
      <div class="pane-content-inner">
        <div id="guides-tabs">
      <!-- Nav tabs -->
    <ul class="nav nav-tabs tabs-group top pull-right">
          <li class="study-inn" tabindex="0" class="tab tab-1 ">
              <a data-toggle="tab" data-target="#13" class="box box-1 jquery-once-6-processed"><span tabindex="0" class="text">All</span></a>
          </li>
          <li class="study-inn" tabindex="11" class="tab tab-2">
              <a data-toggle="tab" data-target="#14" class="box box-2 jquery-once-6-processed"><span tabindex="1" class="text">Android</span></a>
          </li>
          <li class="study-inn" tabindex="12" class="tab tab-3">
              <a data-toggle="tab" data-target="#15" class="box box-3 jquery-once-6-processed"><span tabindex="2" class="text">iOS</span></a>
          </li>
      </ul>
    <!-- Tab panes -->
    <div class="tab-content">
      <div id="13" class="tab-pane active" >
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/apg_vasario_1416.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Cash Drawers</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Heavy duty cash drawer is printer-driven and has multiple functions.</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/apg_vasario_1616.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Cash Drawers</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Perfect solution for low- to medium-volume point of sale.</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/bematech-cd415.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Cash Drawers</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Highly reliable cash drawer for retail and hospitality environments.</p>
        </div>
      </div>
    </div>
      <div id="14" class="tab-pane">
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/apg_vasario_1416.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Cash Drawers</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Heavy duty cash drawer is printer-driven and has multiple functions.</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/apg_vasario_1616.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Cash Drawers</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Perfect solution for low- to medium-volume point of sale.</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/bematech-cd415.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Cash Drawers</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Highly reliable cash drawer for retail and hospitality environments.</p>
        </div>
      </div>
      </div>
      <div id="15" class="tab-pane">
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/apg_vasario_1416.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Cash Drawers</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Heavy duty cash drawer is printer-driven and has multiple functions.</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/apg_vasario_1616.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Cash Drawers</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Perfect solution for low- to medium-volume point of sale.</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/bematech-cd415.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Cash Drawers</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Highly reliable cash drawer for retail and hospitality environments.</p>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
</div>
  <!-- end Cash Drawers -->

  <!-- Label printers -->
  <div class="row" id="block-3">
    <h2>Label printers</h2>
      <div class="pane-content-inner">
        <div id="guides-tabs">
      <!-- Nav tabs -->
    <ul class="nav nav-tabs tabs-group top pull-right">
          <li class="study-inn" tabindex="0" class="tab tab-1 ">
              <a data-toggle="tab" data-target="#7" class="box box-1 jquery-once-6-processed"><span tabindex="0" class="text">All</span></a>
          </li>
          <li class="study-inn" tabindex="1" class="tab tab-2">
              <a data-toggle="tab" data-target="#8" class="box box-2 jquery-once-6-processed"><span tabindex="1" class="text">Android</span></a>
          </li>
          <li class="study-inn" tabindex="2" class="tab tab-3">
              <a data-toggle="tab" data-target="#9" class="box box-3 jquery-once-6-processed"><span tabindex="2" class="text">iOS</span></a>
          </li>
      </ul>
    <!-- Tab panes -->
    <div class="tab-content">
    <p>Connect label printers to your PC or Mac®, and you’re ready to print labels directly from Loyverse Back Office.</p>
      <div id="7" class="tab-pane active" >
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/labelwriter450turbo.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Label printers</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/icon-desktop.png" alt="printers" class="sp-image"/></span>
          <p>Printer’s compact design makes it easy to add, and is an attractive addition to any modern workspace.</p>
          <!-- <p> -->
          <ul>
            <li><i class="i-img"><img src="../../../assets/tab/usb.svg" alt="printers" class="sp-image"/></i>USB</li>
          </ul> 
        <!-- </p> -->
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/labelwriter450turbo.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Label printers</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/icon-desktop.png" alt="printers" class="sp-image"/></span>
          <p>It can print up to 71 addresses, shipping labels, file folders and barcode labels per minute.</p>
          <!-- <p> -->
          <ul>
            <li><i class="i-img"><img src="../../../assets/tab/usb.svg" alt="printers" class="sp-image"/></i>USB</li>
          </ul> 
        <!-- </p> -->
        </div>
      </div>
    </div>
      <div id="8" class="tab-pane">

      </div>
      <div id="9" class="tab-pane">

    </div>
  </div>
  </div>
  </div>
</div>
  <!-- end Label printers -->
<!-- Terminals Receipt printers -->
  <div class="row" id="block-2">
    <h2>Terminals Receipt printers</h2>
      <div class="pane-content-inner">
        <div id="guides-tabs">
      <!-- Nav tabs -->
    <ul class="nav nav-tabs tabs-group top pull-right">
          <li class="study-inn" tabindex="0" class="tab tab-1 ">
              <a data-toggle="tab" data-target="#4" class="box box-1 jquery-once-6-processed"><span tabindex="0" class="text">All</span></a>
          </li>
          <li class="study-inn" tabindex="1" class="tab tab-2">
              <a data-toggle="tab" data-target="#5" class="box box-2 jquery-once-6-processed"><span tabindex="1" class="text">Android</span></a>
          </li>
          <li class="study-inn" tabindex="2" class="tab tab-3">
              <a data-toggle="tab" data-target="#6" class="box box-3 jquery-once-6-processed"><span tabindex="2" class="text">iOS</span></a>
          </li>
      </ul>
    <!-- Tab panes -->
    <div class="tab-content">
      <div id="4" class="tab-pane active" >
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/Terminals1.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Terminals printer</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>The Terminals printer is a combined Bluetooth POS receipt printer and cash drawer.</p>
          <!-- <p> -->
          <ul>
            <li><i class="i-img"><img src="../../../assets/tab/bluetooth.svg" alt="printers" class="sp-image"/></i>Bluetooth</li>
          </ul> 
        <!-- </p> -->
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/Terminals2.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Terminals printer</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Enables reliable USB communication and simultaneous charging with an iPad or an iPhone.</p>
          <!-- <p> -->
          <ul>
            <li><i class="i-img"><img src="../../../assets/tab/usb.svg" alt="printers" class="sp-image"/></i>USB</li>
          </ul>
          <!-- </p> -->
        </div>
      </div>
    </div>
      <div id="5" class="tab-pane">
        <div class="col-md-6">
          <div class="col-md-4"><img src="../../../assets/tab/Terminals3.jpg" alt="printers" class="sp-image"/></div>
          <div class="col-md-8">
            <h3>Terminals printer</h3>
            <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
            <p>The Terminals Receipt printer is a combined Bluetooth POS receipt printer and cash drawer.</p>
            <!-- <p> -->
            <ul>
              <li><i class="i-img"><img src="../../../assets/tab/bluetooth.svg" alt="printers" class="sp-image"/></i>Bluetooth</li>
            </ul>
            <!-- </p> -->
          </div>
        </div>
          <div class="col-md-6">
          <div class="col-md-4"><img src="../../../assets/tab/Terminals4.jpg" alt="printers" class="sp-image"/></div>
          <div class="col-md-8">
            <h3>Terminals printer</h3>
            <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
            <p>Offers exceptional functionality with up to 5 interfaces including Star CloudPRNT.</p>
            <!-- <p> -->
            <ul>
              <li><i class="i-img"><img src="../../../assets/tab/ethernet.svg" alt="printers" class="sp-image"/></i>Ethernet</li>
              <li><i class="i-img"><img src="../../../assets/tab/bluetooth.svg" alt="printers" class="sp-image"/></i>Bluetooth</li>
              <li><i class="i-img"><img src="../../../assets/tab/usb.svg" alt="printers" class="sp-image"/></i>USB</li>
            </ul>
            <!-- </p> -->
          </div>
        </div>
      </div>
      <div id="6" class="tab-pane">
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/Terminals5.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3> Terminals Receipt printer</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Enables reliable USB communication and simultaneous charging with an iPad or an iPhone.</p>
          <!-- <p> -->
          <ul>
            <li><i class="i-img"><img src="../../../assets/tab/usb.svg" alt="printers" class="sp-image"/></i>USB</li>
          </ul>
          <!-- </p> -->
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/Terminals2.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Terminals Receipt printer</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Built to work in environments where heat and humidity can erode the type on the kitchen order.</p>
          <!-- <p> -->
          <ul>
            <li><i class="i-img"><img src="../../../assets/tab/ethernet.svg" alt="printers" class="sp-image"/></i>Ethernet</li>
          </ul> 
        <!-- </p> -->
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
</div>
  <!-- end POS printers -->
  <!-- Barcode Scanners -->
  <div class="row" id="block-4">
    <h2>Scanners</h2>
      <div class="pane-content-inner">
        <div id="guides-tabs">
      <!-- Nav tabs -->
    <ul class="nav nav-tabs tabs-group top pull-right">
          <li class="study-inn" tabindex="0" class="tab tab-1 ">
              <a data-toggle="tab" data-target="#10" class="box box-1 jquery-once-6-processed"><span tabindex="0" class="text">All</span></a>
          </li>
          <li class="study-inn" tabindex="11" class="tab tab-2">
              <a data-toggle="tab" data-target="#11" class="box box-2 jquery-once-6-processed"><span tabindex="1" class="text">Android</span></a>
          </li>
          <li class="study-inn" tabindex="12" class="tab tab-3">
              <a data-toggle="tab" data-target="#12" class="box box-3 jquery-once-6-processed"><span tabindex="2" class="text">iOS</span></a>
          </li>
      </ul>
    <!-- Tab panes -->
    <div class="tab-content">
      <div id="10" class="tab-pane active" >
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/honeywell-voyager-1200g-usb.png" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Scanners</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-pict.png" alt="printers" class="sp-image"/></span>
          <p>It does an exceptional job of reading poor quality and even damaged barcodes.</p>
          <!-- <p> -->
          <ul>
            <li><i class="i-img"><img src="../../../assets/tab/usb.svg" alt="printers" class="sp-image"/></i>USB</li>
          </ul> 
        <!-- </p> -->
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/1250g.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Scanners</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-pict.png" alt="printers" class="sp-image"/></span>
          <p>Optimized for fast scanning of linear barcodes, including poorly printed and damaged codes.</p>
          <!-- <p> -->
          <ul>
            <li><i class="i-img"><img src="../../../assets/tab/usb.svg" alt="printers" class="sp-image"/></i>USB</li>
          </ul>
          <!-- </p> -->
        </div>
      </div>
    </div>
      <div id="11" class="tab-pane">
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/honeywell-voyager-1200g-usb.png" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Scanners</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-pict.png" alt="printers" class="sp-image"/></span>
          <p>It does an exceptional job of reading poor quality and even damaged barcodes.</p>
          <!-- <p> -->
          <ul>
            <li><i class="i-img"><img src="../../../assets/tab/usb.svg" alt="printers" class="sp-image"/></i>USB</li>
          </ul> 
        <!-- </p> -->
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/1250g.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Scanners</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-pict.png" alt="printers" class="sp-image"/></span>
          <p>Optimized for fast scanning of linear barcodes, including poorly printed and damaged codes.</p>
          <!-- <p> -->
          <ul>
            <li><i class="i-img"><img src="../../../assets/tab/usb.svg" alt="printers" class="sp-image"/></i>USB</li>
          </ul>
          <!-- </p> -->
        </div>
      </div>
      </div>
      <div id="12" class="tab-pane">
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/socketscan740.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Scanners</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Has a light-weight, ergonomic structure, and a long-lasting battery to withstand entire work days.</p>
          <!-- <p> -->
          <ul>
            <li><i class="i-img"><img src="../../../assets/tab/bluetooth.svg" alt="printers" class="sp-image"/></i>Bluetooth</li>
          </ul>
          <!-- </p> -->
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/socketscan740.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Scanners</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Universal barcode scanner, scans either 1D or 2D barcodes on paper or screen.</p>
          <!-- <p> -->
          <ul>
            <li><i class="i-img"><img src="../../../assets/tab/bluetooth.svg" alt="printers" class="sp-image"/></i>Bluetooth</li>
          </ul>
          <!-- </p> -->
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
</div>
  <!-- end Barcode Scanners -->

<!-- Android POS terminals -->
  <div class="row" id="block-6">
    <h2>ipad / Tab</h2>
      <div class="pane-content-inner">
        <div id="guides-tabs">
      <!-- Nav tabs -->
    <ul class="nav nav-tabs tabs-group top pull-right">
          <li class="study-inn" tabindex="0" class="tab tab-1 ">
              <a data-toggle="tab" data-target="#16" class="box box-1 jquery-once-6-processed"><span tabindex="0" class="text">All</span></a>
          </li>
          <li class="study-inn" tabindex="11" class="tab tab-2">
              <a data-toggle="tab" data-target="#17" class="box box-2 jquery-once-6-processed"><span tabindex="1" class="text">Android</span></a>
          </li>
          <li class="study-inn" tabindex="12" class="tab tab-3">
              <a data-toggle="tab" data-target="#18" class="box box-3 jquery-once-6-processed"><span tabindex="2" class="text">iOS</span></a>
          </li>
      </ul>
    <!-- Tab panes -->
    <div class="tab-content">
      <div id="16" class="tab-pane active" >
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/tab1.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Tab</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-pict.png" alt="printers" class="sp-image"/></span>
          <p>Get Store is compatible with built-in printers on Tab devices.</p>
          <a href="#">Supported Tab models</a>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/tab2.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Tab</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-pict.png" alt="printers" class="sp-image"/></span>
          <p>widescreen projective capacitive touch Android All-in-One Point-of-Sale terminal.</p>
        </div>
      </div>
    </div>
      <div id="17" class="tab-pane">
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/tab3.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Tab</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-pict.png" alt="printers" class="sp-image"/></span>
          <p>Get Store is compatible with built-in printers on ipad devices.</p>
          <a href="#">Supported ipad models</a>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/tab4.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Tab</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-pict.png" alt="printers" class="sp-image"/></span>
          <p> widescreen projective capacitive touch Android All-in-One Point-of-Sale terminal.</p>
        </div>
      </div>
      </div>
      <div id="18" class="tab-pane">

    </div>
  </div>
  </div>
  </div>
</div>
  <!-- end Android POS terminals -->

<!-- Tablet Stands -->
  <div class="row" id="block-7">
    <h2>Rauter</h2>
      <div class="pane-content-inner">
        <div id="guides-tabs">
      <!-- Nav tabs -->
    <ul class="nav nav-tabs tabs-group top pull-right">
          <li class="study-inn" tabindex="0" class="tab tab-1 ">
              <a data-toggle="tab" data-target="#19" class="box box-1 jquery-once-6-processed"><span tabindex="0" class="text">All</span></a>
          </li>
          <li class="study-inn" tabindex="11" class="tab tab-2">
              <a data-toggle="tab" data-target="#20" class="box box-2 jquery-once-6-processed"><span tabindex="1" class="text">Android</span></a>
          </li>
          <li class="study-inn" tabindex="12" class="tab tab-3">
              <a data-toggle="tab" data-target="#21" class="box box-3 jquery-once-6-processed"><span tabindex="2" class="text">iOS</span></a>
          </li>
      </ul>
    <!-- Tab panes -->
    <div class="tab-content">
      <div id="19" class="tab-pane active" >
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/rauter1.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Rauter</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Spacing for a compact printer. At the rear, a payment holder or extra display can be put. Fits all tablets.</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/rauter2.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Rauter</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Spacing for a large printer. At the rear, a payment holder or extra display can be put. Fits all tablets.</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/rauter3.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Rauter</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Bezel freeholder with adjustable angle and micro-suction technology. Fits all tablets. Compact ground surface with feet.</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/rauter4.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Rauter</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-pict.png" alt="printers" class="sp-image"/></span>
          <p>Secure a Galaxy Tab 3 10.1'' or Galaxy Tab 4 10.1'' tablet with elegance and class. For Galaxy Tab 3 and 4 10.1''</p>
        </div>
      </div>
    </div>
      <div id="20" class="tab-pane">
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/rauter5.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Rauter</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-pict.png" alt="printers" class="sp-image"/></span>
          <p>Secure a Galaxy Tab 3 10.1'' or Galaxy Tab 4 10.1'' tablet with elegance and class. For Galaxy Tab 3 and 4 10.1''</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/rauter6.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Rauter</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/android-pict.png" alt="printers" class="sp-image"/></span>
          <p>Secure your Galaxy Tab A 9.7'' with elegance and class. For Galaxy Tab A 9.7''</p>
        </div>
      </div>
      </div>
      <div id="21" class="tab-pane">
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/rauter2.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Rauter</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>The stand combines svelte esthetics with sturdy and secure construction. For iPad Air 1 and 2</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-4"><img src="../../../assets/tab/rauter4.jpg" alt="printers" class="sp-image"/></div>
        <div class="col-md-8">
          <h3>Rauter</h3>
          <span class="pull-right iconimg"><img src="../../../assets/tab/apple-pict.png" alt="printers" class="sp-image"/></span>
          <p>Featuring powdercoated steel that’s both strong and elegant. For iPad Pro</p>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
</div>
  <!-- end Tablet Stands -->

</div>
</div>
</div>
</div>
    <section class="b-taglines area-bg bg-primary_a parallax">
      <div class="area-bg__inner">
        <div class="container">
          <div class="row">
            <div class="col-sm-10 col-sm-offset-3">
              <div class="b-taglines__inner">
                <h1 class="">Download Get Store POS</h1>
                <div class="b-taglines__text col-sm-offset-1">For iPhone, iPad, Android smartphone or tablet</div>
        <div class="col-md-3"><a href="#" class=""><img src="../../../assets/media/general/gplay-en.png" alt="foto" class="img-responsive"/></a></div>
        <div class="col-md-3"><a href="#" class=""><img src="../../../assets/media/general/appstore-en.png" alt="foto" class="img-responsive"/></a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <app-footer></app-footer>

  </div>
  <!-- end layout-theme-->

<!-- //div fix-->

<script>
$(document).ready(function() {
  $('.nav li a').click(function(e) {
      $('.nav li').removeClass('active');
      var $parent = $(this).parent();
      console.log($parent);
      if (!$parent.hasClass('active')) {
          $parent.addClass('active');
      }
      e.preventDefault();
  });
})
</script>

  
