<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">



    <app-navbar></app-navbar>

<div id="main-slider" data-slider-width="100%" data-slider-height="300px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider main-slider_mod-a slider-pro">
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide"><img src="assets/media/components/b-main-slider/bg-11.jpg" alt="slider" class="sp-image"/>
        <div class="container">
          <div class="row">
            <div class="col-md-8">

              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="2200" data-hide-delay="400" class="main-slider__info sp-layer"><img src="assets/media/general/grocerypos.png" alt="slider"/></div>

            </div>
          </div>
        </div>
      </div>
      <!-- Slide 2-->

    </div>
  </div>
  <!-- end .main-slider-->
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="section-area">
          <div class="b-request-estimate">
            <div class="b-request-estimate__info">GET Store POS is  that comes loaded with all the features that modernised and simplify operations, increase sales, and delight guests.</div>
            <div class="b-request-estimate__title bg-primary"><span class="ui-decor-2"></span>Grocery Store POS</div>
          </div>
          <!-- end .b-request-estimate-->
        </div>
      </div>
    </div>
  </div>
<!-- START THE NAVBAR -->
<section class="section-default retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <span class="stroke icon-PicnicBasket"></span>
    <h1>Make the sales process quick and easy</h1>
    <ul>
        <li>Organize sales according to your needs with a customizable menu screen, group your items into categories.</li>
        <li>Add weighted items to a ticket by scanning barcodes with embedded weight.</li>
        <li>Make sales, apply percentage and amount discounts in a glance.</li>
        <li>Speed sales with an integrated payment system by accepting major credit and debit cards.</li>
    </ul>

</div>
<div class="col-md-6">
    <img src="assets/media/general/grocery-hardware.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row">
<div class="col-md-6">
    <img src="assets/media/general/inventory.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <span class="stroke icon-StorageBox"></span>
    <h1>Manage your inventory</h1>
    <ul>
        <li>Create new items and check their stock right on the spot from your Grocery POS.</li>
        <li>Add thousands of items, correct their prices or inventory in one click using the export-import functionality.</li>
        <li>Receive email messages about the low stock and send purchase orders to suppliers from your Back office.</li>
        <li>Understand the total cost of your inventory and predict potential revenue and profits.</li>
    </ul>
    <a class="" href="#">Learn more about inventory management</a>
</div>
</div>
</div>
</section>

<section class="retail">
<div class="container">
<div class="row">
<div class="col-md-6">
    <span class="stroke icon-Headset"></span>
    <h1>Provide your customers with the best service</h1>
    <ul>
        <li>Use a built-in loyalty system to make your customers return to your grocery or convenience store.</li>
        <li>Instantly identify your customers by scanning barcodes from loyalty cards.</li>
        <li>View customer purchase history to provide personalized service.</li>
        <li>Print customer address on receipt to streamline delivery orders.</li>
    </ul>
</div>
<div class="col-md-6">
    <img src="assets/media/general/customer.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row">
<div class="col-md-6">
    <img src="assets/media/general/sales-by-item.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <span class="stroke icon-ChartUp"></span>
    <h1>Analyze your sales</h1>
    <ul>
        <li>Receive valuable insights into your store’s performance from the report section of the Back office.</li>
        <li>Get insights about your sales by items or categories; check your revenue in real-time.</li>
        <li>Check the performance of your employees by time periods and make balanced decisions.</li>
    </ul>
    <a class="" href="#">Learn more about Loyverse Back Office</a>
</div>
</div>
</div>
</section>

<section class=" retail">
<div class="container">
<div class="row">

<div class="col-md-6">
    <span class="stroke icon-WorldGlobe"></span>
    <h1>Scale your business</h1>
    <ul>
        <li>Grow your business from a single store to a chain of grocery stores.</li>
        <li>Manage your items’ inventory, prices, and receive low stock notifications by stores.</li>
        <li>Move items between stores with transfer orders.</li>
        <li>Track all your store performance as well as consolidated reports by different parameters in one place.</li>
    </ul>
    <a class="" href="#">Learn more about multi-store management</a>
</div>
<div class="col-md-6">
    <img src="assets/media/general/multistore.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<div class="container retail">
<div class="row">
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_green section-radius" style="background-position: -62.7px 20.4px;">
            <div class="b-contact__name">Website</div>
            <div class="b-contact__info">{{'envUrl' | envName }}</div>
            <div class="b-contact__icon icon-globe"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_blue section-radius" style="background-position: -296.7px 20.4px;">
            <div class="b-contact__name">Phone</div>
            <div class="b-contact__info"> +60 3-7803 4040</div>
            <div class="b-contact__icon icon-call-in"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_grey section-radius" style="background-position: -530.7px 20.4px;">
            <div class="b-contact__name">Email</div>
            <div class="b-contact__info">getstore@gmail.com</div>
            <div class="b-contact__icon icon-envelope-open"></div>
          </div>
          <!-- end b-contact-->
        </div>
      </div>
</div>

  <section class="b-taglines area-bg bg-primary_a parallax">
    <div class="area-bg__inner">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 col-sm-offset-3">
            <div class="b-taglines__inner">
              <h1 class="">Download Get Store POS</h1>
              <div class="b-taglines__text col-sm-offset-1">For iPhone, iPad, Android smartphone or tablet</div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/gplay-en.png" alt="foto" class="img-responsive"/></a></div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/appstore-en.png" alt="foto" class="img-responsive"/></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>

</div>
<!-- end layout-theme-->

<!-- //div fix-->
<script>
$(document).ready(function() {
$('.nav li a').click(function(e) {
    $('.nav li').removeClass('active');
    var $parent = $(this).parent();
    console.log($parent);
    if (!$parent.hasClass('active')) {
        $parent.addClass('active');
    }
    e.preventDefault();
});
})
</script>
