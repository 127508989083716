<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">



  <app-navbar></app-navbar>

  <div id="main-slider" data-slider-width="100%" data-slider-height="620px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider slider-pro">
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide"><img src="assets/media/components/b-main-slider/product-bg-6.jpg" alt="slider" class="sp-image"/>
        <div class="container">
          <div class="row">
            <div class="col-sm-10 col-sm-offset-1">
              <div data-width="100%" data-show-transition="right" data-hide-transition="left" data-show-duration="1200" data-show-delay="2000" data-hide-delay="400" data-vertical="530px" data-horizontal="300" class="main-slider__item-1 sp-layer"><a href="#" ><img src="assets/media/general/gplay-en.png" alt="Item"/></a></div>

<div data-width="100%" data-show-transition="left" data-hide-transition="right" data-show-duration="1200" data-show-delay="2000" data-hide-delay="400" data-vertical="530px" data-horizontal="600" class="main-slider__item-1 sp-layer"><a href="#" ><img src="assets/media/general/appstore-en.png" alt="Item"/></a></div>

              <h2 data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="800" data-show-delay="400" data-hide-delay="400" class="main-slider__title sp-layer">Customer Display System</h2>
              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="1200" data-show-delay="600" data-hide-delay="400" class="sp-layer">
                <a href="#" ><img src="assets/media/general/cds-header.png" alt="Item"/></a>
              </div>
            <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="1200" data-hide-delay="400" class="main-slider__info sp-layer"><strong>Clearly display price information to your customers at the time of purchase</strong><br>Install Loyverse CDS on a separate tablet and use it alongside Loyverse POS to deliver order details, price information and reward points to customers at the time of purchase. Give customers a way to conveniently enter their email address.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end .main-slider-->

<!-- START THE NAVBAR -->
<section class="retail">
<div class="container">
<div class="row">
<div class="col-sm-10 col-sm-offset-1 newp">
<div class="ui-decor-1"><img src="assets/media/general/ui-decor-1.png" alt="decor" class="center-block"></div>
<h1 class="text-center">Features</h1>
<br>
<br>
    <img src="assets/media/general/cds-tablet.png" alt="foto" class="img-responsive col-sm-offset-1"/>
    <br>
    <ul class="col-sm-10 col-sm-offset-2">
        <li><strong>Convenient to customers</strong><br>
Customers can see order information before final payment.</li>
        <li><strong>Displays all information</strong><br>
All order details are visible including modifiers, discounts, taxes etc.</li>
        <li><strong>Loyalty points</strong><br>
Regular customers can see accumulated loyalty points.</li>
        <li><strong>Optional email entry</strong><br>
Customers wishing to receive e-receipt can easily enter their email.</li>
        <li><strong>Less mistakes</strong><br>
Shows every purchased item as well as paid amount and change.</li>
        <li><strong>Works offline</strong><br>
It just works, even without Internet connection.</li>
        <li><strong>Quick and easy installation</strong><br>
Download the app and set it up in an instant.</li>
    </ul>
</div>
</div>
</div>
</section>

<div class="bg-grey container retail">
<div class="row">
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_green section-radius" style="background-position: -62.7px 20.4px;">
            <div class="b-contact__name">Website</div>
            <div class="b-contact__info">{{'envUrl' | envName }}</div>
            <div class="b-contact__icon icon-globe"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_blue section-radius" style="background-position: -296.7px 20.4px;">
            <div class="b-contact__name">Phone</div>
            <div class="b-contact__info"> +60 3-7803 4040</div>
            <div class="b-contact__icon icon-call-in"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_grey section-radius" style="background-position: -530.7px 20.4px;">
            <div class="b-contact__name">Email</div>
            <div class="b-contact__info">getstore@gmail.com</div>
            <div class="b-contact__icon icon-envelope-open"></div>
          </div>
          <!-- end b-contact-->
        </div>
      </div>
</div>

  <section class="b-taglines area-bg bg-primary_a parallax">
    <div class="area-bg__inner">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 col-sm-offset-3">
            <div class="b-taglines__inner">
              <h1 class="">Download Get Store POS</h1>
              <div class="b-taglines__text col-sm-offset-1">For iPhone, iPad, Android smartphone or tablet</div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/gplay-en.png" alt="foto" class="img-responsive"/></a></div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/appstore-en.png" alt="foto" class="img-responsive"/></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>

</div>
<!-- end layout-theme-->

<!-- //div fix-->
<script>
$(document).ready(function() {
$('.nav li a').click(function(e) {
    $('.nav li').removeClass('active');
    var $parent = $(this).parent();
    console.log($parent);
    if (!$parent.hasClass('active')) {
        $parent.addClass('active');
    }
    e.preventDefault();
});
})
</script>
