<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">



  <app-navbar></app-navbar>

  <div id="main-slider" data-slider-width="100%" data-slider-height="500px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider slider-pro">
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide"><img src="assets/media/components/b-main-slider/product-bg-2.jpg" alt="slider" class="sp-image"/>
        <div class="container">
          <div class="row">
            <div class="col-sm-10 col-sm-offset-1">
              <div data-width="100%" data-show-transition="right" data-hide-transition="left" data-show-duration="1200" data-show-delay="2000" data-hide-delay="400" data-vertical="300px" data-horizontal="450" class="main-slider__item-1 sp-layer"><img src="assets/media/general/Back Office.png" alt="Item"/></div>
              <h2 data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="800" data-show-delay="400" data-hide-delay="400" class="main-slider__title sp-layer">Manage your Store Thoroughly with Get Store Back Office</h2>
              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="1200" data-show-delay="600" data-hide-delay="400" class="sp-layer">
                <div class="main-slider__decor bg-primary"></div>
              </div>
            <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="1200" data-hide-delay="400" class="main-slider__info sp-layer">The Back Office is an online-based tool that works in connection with the Get Store POS app. It gives you sales reports to make a complete view of your business and allows to adjust settings and manage a Get Store account.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end .main-slider-->
<!-- START THE NAVBAR -->
<section class="retail">
<div class="container">
<div class="row text-center">
<div class="col-sm-10 col-sm-offset-1 newp">
<h1>Reports and Sales Analytics Tools</h1>
<br>
<p>The Back Office provides a wide variety of reports and sales analytics which you can access via a browser on any device. Analyze your sales by different parameters, such as time, stores, employees, payment types and more. Manage your inventory, employees, and customers, set the features necessary for your business, bulk import/export a list of items and customers, export sales reports.</p>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row text-center">
<div class="col-sm-10 col-sm-offset-1 newp">
<span class="stroke icon-ClipboardText"></span>
<h1>Reports</h1>
<br>
<p>Analyze your sales by different parameters and filters and find out the trends and problem areas that help you constantly improve the performance of your business</p><br>
    <img src="assets/media/general/sales-by-item-1.png" alt="foto" class="img-responsive col-sm-offset-1"/>
</div>
</div>
    <ul class="col-sm-10 col-sm-offset-1">
        <li>Compare sales by different periods, stores, employees, check the important parameters at one page, such as gross sales, net sales, and gross profit, amounts of refunds made and given discounts.</li>
        <li>Determine top-selling items and categories, top-performing employee and store with the top sales.</li>
        <li>Check the sales by shifts and find out the difference between expected and actual cash amounts.</li>
        <li>Browse transaction history, export reports to CSV files, and analyze your data.</li>
    </ul>
</div>
</section>

<section class="section-default retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <span class="stroke icon-Tshirt"></span>
    <h1>Items</h1>
    <h3>Manage your items, categories, modifiers, and discounts. Customize items by picture, price by unit or weight, create composite items, variants, and modifiers.</h3>
    <ul>
        <li>Customize items by picture, select to sell them by unit or weight, create composite items. Manage item parameters such as categories, price, cost, stock, and many others. Import item list in one click.</li>
        <li>Add more flexibility to your sales by creating modifiers that allows to customize items during sales and change the prices of items.</li>
        <li>Make your store more attractive to customers by creating amount- and percentage-based discounts. Restrict application of discounts to certain employees.</li>
        <li>Set different prices and stock for the items in different stores.</li>
    </ul>

</div>
<div class="col-md-6">
    <img src="assets/media/general/item-card.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row text-center">
<div class="col-sm-10 col-sm-offset-1 newp">
<span class="stroke icon-Speaker2"></span>
<h1>Inventory management</h1>
<br>
<p>Advanced inventory management subscription gives you a comprehensive tool to manage your stock</p><br>
    <img src="assets/media/general/inventory-report.png" alt="foto" class="img-responsive col-sm-offset-1"/>
</div>
</div>
    <ul class="col-sm-10 col-sm-offset-1">
        <li>Update stock in one click through purchase orders and send orders to your suppliers. The system will calculate the average price of received items automatically.</li>
        <li>Distribute items among your stores if you have multiple stores. Perform partial or full inventory counting either manually or with a barcode scanner.</li>
        <li>Correct stock [numbers] on the go, specifying different reasons for the operation. Check stock history to control all changes.</li>
        <li>Print label for items, view valuation of your inventory, produce complex items from other items-components and manage their stock.</li>
        <li><a href="#" >Read more about Advanced inventory</a></li>
    </ul>

</div>
</section>

<section class="retail">
<div class="container">
<div class="row">
<div class="col-md-6">
    <img src="assets/media/general/timecard.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <span class="stroke icon-Glasses"></span>
    <h1>Employees</h1>
    <h3>Manage your employees: create different groups of employees and set up access to specific functionality on the POS and the Back office</h3>
    <ul>
        <li>Get the Information on the workload per hour that allows for efficient management of your staff.</li>
        <li>Check employee work time with the clock-in / clock-out feature.</li>
        <li>Track each employee’s sales performance and make informed business decisions.</li>
        <a href="#" >Read more about Employee management</a>
    </ul>


</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <span class="stroke icon-User"></span>
    <h1>Customers</h1>
    <h3>Manage your customers, find the customers with the most visits or most purchases, etc</h3>
    <ul>
        <li>Export your customers' list and use it to contact them.</li>
        <li>Add new customers in one click.</li>
        <li>Correct a customer profile, edit points balance.</li>
        <li>Import customer list from other sources.</li>
    </ul>
</div>
<div class="col-md-6">
    <img src="assets/media/general/customer-profile.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<section class="retail">
<div class="container">
<div class="row">
<div class="col-md-6">
    <img src="assets/media/general/settings-1.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <span class="stroke icon-Settings"></span>
    <h1>Settings</h1>
    <h3>Adjust all the necessary parameters of the Loyverse account: payment types, loyalty settings, taxes, kitchen printer groups, dining options and a lot more</h3>
    <ul>
        <li>Create multiple stores and POS to manage your growing business successfully.</li>
        <li>Set up the features you need: shifts, time clock, open tickets, kitchen printers, customer displays, dining options, low stock notifications, negative stock alerts.</li>
        <li>Manage your trials and subscriptions in the Billing and subscriptions section.</li>
        <li>Customize your logo and text messages at the top and bottom of the receipts.</li>
    </ul>
</div>

</div>
</div>
</section>

<div class="container retail">
<div class="row">
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_green section-radius" style="background-position: -62.7px 20.4px;">
            <div class="b-contact__name">Website</div>
            <div class="b-contact__info">{{'envUrl' | envName }}</div>
            <div class="b-contact__icon icon-globe"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_blue section-radius" style="background-position: -296.7px 20.4px;">
            <div class="b-contact__name">Phone</div>
            <div class="b-contact__info"> +60 3-7803 4040</div>
            <div class="b-contact__icon icon-call-in"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_grey section-radius" style="background-position: -530.7px 20.4px;">
            <div class="b-contact__name">Email</div>
            <div class="b-contact__info">getstore@gmail.com</div>
            <div class="b-contact__icon icon-envelope-open"></div>
          </div>
          <!-- end b-contact-->
        </div>
      </div>
</div>

  <section class="b-taglines area-bg bg-primary_a parallax">
    <div class="area-bg__inner">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 col-sm-offset-3">
            <div class="b-taglines__inner">
              <h1 class="">Download Get Store POS</h1>
              <div class="b-taglines__text col-sm-offset-1">For iPhone, iPad, Android smartphone or tablet</div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/gplay-en.png" alt="foto" class="img-responsive"/></a></div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/appstore-en.png" alt="foto" class="img-responsive"/></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>

</div>
<!-- end layout-theme-->

<!-- //div fix-->
<script>
$(document).ready(function() {
$('.nav li a').click(function(e) {
    $('.nav li').removeClass('active');
    var $parent = $(this).parent();
    console.log($parent);
    if (!$parent.hasClass('active')) {
        $parent.addClass('active');
    }
    e.preventDefault();
});
})
</script>
