import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js'
import { Router } from '@angular/router';
import Stepper from 'bs-stepper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from './authentication.service';
import { environment } from 'src/environments/environment';

const envUrl=environment.envUrl

@Component({
	selector: 'app-auth-register-v2',
	templateUrl: './auth-register-v2.component.html',
	styleUrls: ['./auth-register-v2.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AuthRegisterV2Component implements OnInit {

	// Public
	public coreConfig: any;
	public passwordTextType1: boolean;
	public passwordTextType2: boolean;
	public registerForm: FormGroup;
	public submitted = false;
	public error = '';
	public loading = false;
	public loginError = '';
	public selectBasicLoading = false;
	public emailValid = false;
	// Private
	private _unsubscribeAll: Subject<any>;

	console = console;
  envUrl=envUrl

	public selectBusiness: any = [];

	public ASCIICode: any;
	/**
	 * Constructor
	 *
	 * @param {CoreConfigService} _coreConfigService
	 * @param {FormBuilder} _formBuilder
	 */
	constructor(private _formBuilder: FormBuilder, private _router: Router,
		private _authenticationService: AuthenticationService, private apollo: Apollo, private modalService: NgbModal, private changeDetectorRef: ChangeDetectorRef, private _toastr: ToastrService) {

		// redirect to home if already logged in
		if (this._authenticationService.currentUserValue) {
			this._router.navigate(['/']);
		}

		this._unsubscribeAll = new Subject();

		// Configure the layout

	}

	// convenience getter for easy access to form fields
	get f() {
		return this.registerForm.controls;
	}

	/**
	 * Toggle password
	 */
	togglePasswordTextType1() {
		this.passwordTextType1 = !this.passwordTextType1;
	}

	/**
 * Toggle password
 */
	togglePasswordTextType2() {
		this.passwordTextType2 = !this.passwordTextType2;
	}


	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {


		this.verticalWizardStepper = new Stepper(document.querySelector('#stepper2'), {
			linear: true,
			animation: true
		});


		// content header
		this.contentHeader = {
			headerTitle: 'Form Wizard',
			actionButton: true,
			breadcrumb: {
				type: '',
				links: [
					{
						name: 'Home',
						isLink: true,
						link: '/'
					},
					{
						name: 'Forms',
						isLink: true,
						link: '/'
					},
					{
						name: 'Form Wizard',
						isLink: false
					}
				]
			}
		};




		this.registerForm = this._formBuilder.group({
			username: ['', [Validators.required]],
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required]
		});

		// Subscribe to config changes


		this.apollo.query({
			query: gql`
			query {
				getCountriesList {
					message,
					  data{
						  id,
					name,
					code,
					phone_code
				  }
				}
			  }
			`
		}).subscribe((res: any) => {
			this.countryList = res.data.getCountriesList.data;
			this.countryCodes = res.data.getCountriesList.data.map(a => a.code.toLowerCase());
			res.data.getCountriesList.data.map(c => {
				this.customLabels[c.code.toLowerCase()] = c.name;
			})
			this.selectedCountry = this.countryList[0];
		});


		this.apollo.query({
			query: gql`
			query {
				getBusinessTypes{
					message,
				  data{
					id,
					name
				  }
				}
			  }
			`
		}).subscribe((res: any) => {
			this.selectBusiness = res.data.getBusinessTypes.data;
			this.selectedBusiness = this.selectBusiness[0].id;
		})


	}
	phoneInvalid = true;
	onlyNumberKey(evt: any) {

		// Only ASCII character in that range allowed
		this.ASCIICode = (evt.which) ? evt.which : evt.keyCode
		if (this.ASCIICode > 31 && (this.ASCIICode < 48 || this.ASCIICode > 57))
			return false;
		return true;
	}

	validatePhone(event: any) {
		// this.console.log(event.length)
		if (isPossiblePhoneNumber(event, this.selectedCountry.code))
			this.phoneInvalid = !isValidPhoneNumber(event, this.selectedCountry.code)
		else if(event.length < 6 && event.length >10)
			this.phoneInvalid = true;
		else
			this.phoneInvalid = true;
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	// Stepper
	// public
	public contentHeader: object;
	public TDNameVar;
	public TDEmailVar;
	public TDPassword;
	public TDBName;
	public TDBDomain;
	public TDFirstNameVar;
	public TDLastNameVar;
	public TDContactVar;
	public twitterVar;
	public facebookVar;
	public googleVar;
	public linkedinVar;
	public landmarkVar;
	public addressVar;
	public TDVerifyVar;
	public loginDomainVar;
	public loginEmailVar;
	public selectBasic = [
		{ name: 'UK' },
		{ name: 'USA' },
		{ name: 'Spain' },
		{ name: 'France' },
		{ name: 'Italy' },
		{ name: 'Australia' }
	];


	public selectStores = [

		{ name: 'I don\'t own a business and I don\'t intend to', val: -1 },
		{ name: 'Zero (but I intend to open a business in the future)', val: 0 },
		{ name: '1 (I currently own one store)', val: 1 },
		{ name: '2+ (I currently own multiple stores)', val: 2 }

	]

	public selectMulti = [{ name: 'English' }, { name: 'French' }, { name: 'Spanish' }];
	public selectMultiSelected;

	selectedStore = 2;


	countryList: any = [];
	countryCodes: any = [];
	selectedCountry;

	selectedBusiness;
	customLabels = {};


	changeSelectedCountryCode(value: string): void {

		this.selectedCountry = this.countryList.find(x => x.code.toLowerCase() == value);
		console.log(this.selectedCountry);
		this.changeDetectorRef.detectChanges();
	}
	// private
	private verticalWizardStepper: Stepper;
	public index = 0;

	/**
 * On Submit
 */
	onSubmit() {
		if (this.TDVerifyVar != "") {
			this.loading = true;
			this.apollo.query({
				query: gql`
			query {
				verifyEmail(input:{email:"${this.TDEmailVar}",otp:${this.TDVerifyVar}}) {
				  isValid,
				  verifyStatus,
				  message,
				  statusCode
				}
			  }`
			}).subscribe((response: any) => {
				if (response.data.verifyEmail.isValid) {
					this.verticalWizardStepper.next();
					this.index++;
				} else
					this.error = response.data.verifyEmail.verifyStatus;
				this.loading = false;
			});
		}
	}

	emailExist = false;
	domainExist = false;

	checkEmail(event: any) {
		if (event.target.value != "")
			this.apollo.query({
				query: gql`
			query{
				checkEmailExists(input:{email:"${event.target.value}"}){
					message,
					available
				}
			}`
			}).subscribe((response: any) => {
				this.emailExist = !response.data.checkEmailExists.available;
			});
		else {
			this.emailExist = false;
		}
	}

	changeDomain() {
		this.TDBDomain = this.TDBName.replace(/[^A-Za-z0-9]/gi, "");
	}

  checkDomain(event: any) {

		if (event.target.value != "") {
			this.TDBDomain = event.target.value.replace(/[^A-Za-z0-9]/gi, "");
			this.apollo.query({
				query: gql`
			query {
				checkDomainExists(input:{domain:"${this.TDBDomain + `.${envUrl}`}"}) {
				  available
				}
			  }`
			}).subscribe((response: any) => {
				this.domainExist = !response.data.checkDomainExists.available;
			});
		} else {
			this.domainExist = false;
		}
	}

  completeRegister(data: any) {
		this.submitted = true;
		if (data.form.valid === true && !this.emailExist && !this.domainExist && !this.phoneInvalid) {
			this.loading = true;
			this.apollo.mutate({
				mutation: gql`
			mutation {
				customerRegistration(
						  input: {
					  email:"${this.TDEmailVar}",
					  password:"${this.TDPassword}",
					  countryId:"${this.selectedCountry.id}",
					  businessName:"${this.TDBName}",
					  businessDomain:"${this.TDBDomain + `.${envUrl}`}",
					  firstName:"${this.TDFirstNameVar}",
					  lastName:"${this.TDLastNameVar}",
					  phone:"${this.selectedCountry.phone_code + this.TDContactVar}",
					  businessTypeId:"${this.selectedBusiness}",
					  noOfStores:${this.selectedStore}
					}){
				  statusCode,
				  message,
				  error{
					path,
					message
				  },
				  id
				}
			  }
			`, update: (cache, { data: { customerRegistration } }: any) => {
					cache.writeQuery({ query: gql`{checkEmailExists(input:{email:"${this.TDEmailVar}"}){message,available}}`, data: { checkEmailExists: { available: false } } })
					cache.writeQuery({
						query: gql`{checkDomainExists(input:{domain:"${this.TDBDomain + `.${envUrl}`}"}) {
						available
					  }}`, data: { checkDomainExists: { available: false } }
					})
				}
			}).subscribe((res: any) => {
				if (res.data.customerRegistration.statusCode == 200 || res.data.customerRegistration.statusCode == 201) {
					this.verticalWizardStepper.next();
					this.index++;
				} else {
					if (res.data.customerRegistration.error)
						this.error = res.data.customerRegistration.error[0].message;
					else {
						this.error = res.data.customerRegistration.message;
					}
				}
				this.loading = false;
			});

		}

	}

	loginCheckDomain() {

		if (this.loginDomainVar != "") {
			console.log(this.loginDomainVar);
			this.apollo.query({
				query: gql`
					query {
						checkDomainExists(input:{domain:"${this.loginDomainVar + `.${envUrl}`}"}) {
					  	available
						}
				  	}`
			}).subscribe((response: any) => {
				console.log(response);
				if (!response.data.checkDomainExists.available) {
					this.modalService.dismissAll();
					window.location.href="https://"+this.loginDomainVar + `.${envUrl}`
				} else {
					this.loginError = response.data.checkDomainExists.message;
				}

			})

		}

	}


	loginCheckEmail() {
		console.log(this.loginEmailVar);
		if (this.loginEmailVar != "") {
			this.apollo.query({
				query: gql`
					query {
						checkEmailExists(input:{email:"${this.loginEmailVar}"}) {
							message
					  		available
						}
				  	}`
			}).subscribe((response: any) => {

				if (!response.data.checkEmailExists.available) {

					this.apollo.query({
						query: gql`
							query {
								getDomainByEmail(email:"${this.loginEmailVar}") {
									message
									domain
								}
							}
						`
					}).subscribe((res: any) => {
						if (res.data.getDomainByEmail.domain != null) {
							sessionStorage.setItem('domainName', res.data.getDomainByEmail.domain);
							this.modalService.dismissAll();
							window.location.href="https://"+res.data.getDomainByEmail.domain
						} else {
							this.loginError = res.data.getDomainByEmail.message;
						}
					})
				} else {
					this.loginError = response.data.checkEmailExists.message;
				}

			})

		}
	}

	login() {

		if (this.loginEmailVar && this.loginEmailVar != "") {
			console.log('email', this.loginEmailVar);
			this.loginCheckEmail();
		} else if (this.loginDomainVar && this.loginDomainVar != "") {
			console.log('domain', this.loginDomainVar);
			this.loginCheckDomain();
		} else {
			this.error = "Please enter either your email or domain";
		}

	}

	register() {
		this._router.navigate(['/register']);
		this.modalService.dismissAll();
	}


	/**
	 * Horizontal Wizard Stepper Next
	 *
	 * @param data
	 */

	/**
	 * Vertical Wizard Stepper Next
	 */
	verticalWizardNext(data) {
		this.loading = true;
		this.submitted = true;

		if (data.form.valid === true && !this.emailExist && !this.domainExist) {
			this.verticalWizardStepper.next();
			this.index++;
		}
		this.loading = false;
	}
	/**
	 * Vertical Wizard Stepper Previous
	 */
	verticalWizardPrevious() {

		this.verticalWizardStepper.previous();
		this.index--;
	}

	// modal Open Vertically Centered
	modalOpenVC(modalVC) {
		this.modalService.open(modalVC, {
			centered: true
		});
	}

	public seconds: number = 60;

	resendOTP() {
		let timer = setInterval(() => {
			this.seconds = this.seconds - 1;
			if (this.seconds == 0) {
				clearInterval(timer);
				this.seconds = 60;
			}
		}, 1000);


		this.apollo.mutate({
			mutation: gql`
				mutation {
					resendEmailOtp(email:"${this.TDEmailVar}") {
						statusCode,
						message
					}
				}
			`
		}).subscribe((response: any) => {
			if (response.data.resendEmailOtp.statusCode == 200 || response.data.resendEmailOtp.statusCode == 201)
				this._toastr.success("Resend OTP. Please check email.", "Success");
			else
				this._toastr.error(response.data.resendEmailOtp.message, "Error");
		})
	}


}
