
<div class="auth-wrapper auth-v2">
	<div class="auth-inner row m-0">

		<!-- Left Text-->
		<div class=" d-lg-flex col-lg-4 align-items-center p-5">
			<a class="brand-logo" routerLink="/">
				<!-- <img src="../../../../../../assets/images/logo/gsp-02.png" alt="brand-logo" height="100" width="150" /> -->
				<img src="../../../assets/media/general/logo-dark.png" alt="brand-logo" height="100" width="150" />
			</a>
			<div class="d-none w-100 d-lg-flex align-items-center justify-content-center px-5">
				<img class="img-fluid" [src]=" 'assets/images/register-v2.svg'
          " alt="Register V2" />
			</div>
		</div>
		<!-- /Left Text-->

		<!-- Register-->
		<div class="d-flex col-lg-8 col-md-12 h-100 align-items-center auth-bg px-2">
			<div class="col-md-12 px-xl-2 mx-auto" style="height:75%">
				<h2 class="card-title font-weight-bold mb-1">Sign up Now</h2>
				<p class="card-text mb-2">Start your FREE 14-day trial now</p>

				<ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
					<div class="alert-body">
						<p>{{ error }}</p>
					</div>
				</ngb-alert>


				<section class="horizontal-wizard mt-2">
					<div id="stepper2" class="bs-stepper vertical-wizard-example">
						<div class="bs-stepper-header">
							<div class="step" data-target="#account-details-vertical">
								<button type="button" class="step-trigger">
									<span class="bs-stepper-box">1</span>
									<span class="bs-stepper-label">
										<span class="bs-stepper-title">Account Details</span>
										<span class="bs-stepper-subtitle">Setup Account Details</span>
									</span>
								</button>
							</div>
							<div class="line" [hidden]="index < 1"><i data-feather="chevron-right"
									class="font-medium-2"></i></div>
							<div class="step" [hidden]="index < 1" data-target="#personal-info-vertical">
								<button type="button" class="step-trigger">
									<span class="bs-stepper-box">2</span>
									<span class="bs-stepper-label">
										<span class="bs-stepper-title">Personal Info</span>
										<span class="bs-stepper-subtitle">Add Personal Info</span>
									</span>
								</button>
							</div>
							<div class="line" [hidden]="index < 2"><i data-feather="chevron-right"
									class="font-medium-2"></i></div>
							<div class="step" [hidden]="index < 2" data-target="#verification-step-vertical">
								<button type="button" class="step-trigger">
									<span class="bs-stepper-box">3</span>
									<span class="bs-stepper-label">
										<span class="bs-stepper-title">Verification</span>
										<span class="bs-stepper-subtitle">Verify Email</span>
									</span>
								</button>
							</div>
							<div class="line" [hidden]="index < 3"><i data-feather="chevron-right"
									class="font-medium-2"></i></div>
							<div class="step" [hidden]="index < 3" data-target="#address-step-vertical">
								<button type="button" class="step-trigger">
									<span class="bs-stepper-box">4</span>
									<span class="bs-stepper-label">
										<span class="bs-stepper-title">Complete</span>
										<span class="bs-stepper-subtitle">Finish Register</span>
									</span>
								</button>
							</div>
						</div>
						<div class="bs-stepper-content">
							<form (ngSubmit)="(HWForm.form.valid)" autocomplete="off" #HWForm="ngForm">
								<div id="account-details-vertical" style="margin:auto" class="content">
									<form #accountDetailsForm="ngForm">
										<div class="row">
											<div class="form-group col-md-12">
												<label class="form-label" for="email">Email<span class="required-asterisk">*</span></label>
												<input (focus)="checkEmail($event)" (blur)="checkEmail($event)"
													[(ngModel)]="TDEmailVar" ngModel #TDEmailRef="ngModel" required
													email type="email" name="email" id="email" class="form-control"
													[class.error]="!TDEmailRef.valid && accountDetailsForm.submitted"
													placeholder="john.doe@email.com" aria-label="john.doe" />
												<span
													*ngIf="(emailExist || !TDEmailRef.valid ) && (accountDetailsForm.submitted || TDEmailRef.touched || TDEmailRef.dirty)"
													class="invalid-form">
													<small class="form-text text-danger"
														*ngIf="TDEmailRef.errors && TDEmailRef.errors.required"> Email
														is
														required.
													</small>
													<small class="form-text text-danger"
														*ngIf="!TDEmailRef.valid && !TDEmailRef.errors.required">Email
														must be
														valid!</small>
													<small class="form-text text-danger" *ngIf="emailExist">Email
														already exists!</small>
												</span>
											</div>
										</div>
										<div class="row">
											<div class="form-group col-md-12">
												<label class="form-label" for="password">Password</label>
												<div class="input-group input-group-merge">
													<input [(ngModel)]="TDPassword"
														[type]="passwordTextType1 ? 'text' : 'password'" name="password"
														id="password" class="form-control form-control-merge"
														[class.error]="!TDPasswordOnlyRef.valid && accountDetailsForm.submitted"
														placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
														ngModel #TDPasswordOnlyRef="ngModel" required minlength="6"
														pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
														autocomplete="new-password" />
													<div class="input-group-append">
														<span class="input-group-text cursor-pointer"><i
																class="feather font-small-4" [ngClass]="{
											  'icon-eye-off': passwordTextType1,
											  'icon-eye': !passwordTextType1
											}" (click)="togglePasswordTextType1()"></i></span>
													</div>
												</div>
												<div *ngIf="!TDPasswordOnlyRef.valid && (TDPasswordOnlyRef.touched || TDPasswordOnlyRef.dirty || accountDetailsForm.submitted)"
													class="form-text text-danger">
													<small *ngIf="TDPasswordOnlyRef.errors.required"> Password is
														required.
													</small>
													<small *ngIf="TDPasswordOnlyRef.errors.pattern">
														Must contain at least one number and one uppercase and lowercase
														letter, and at least 8 or more
														characters.</small>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="form-group  col-md-12">
												<label class="form-label" for="confirm-password">Confirm
													Password</label>
												<div class="input-group input-group-merge">
													<input [type]="passwordTextType2 ? 'text' : 'password'"
														name="confirm-password" id="confirm-password"
														class="form-control  form-control-merge"
														[class.error]="!TDRepeatPasswordRef.valid && accountDetailsForm.submitted"
														placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
														required ngModel #TDRepeatPasswordRef="ngModel"
														pattern="{{ TDPasswordOnlyRef.value }}" minlength="6" />
													<div class="input-group-append">
														<span class="input-group-text cursor-pointer"><i
																class="feather font-small-4" [ngClass]="{
											  'icon-eye-off': passwordTextType2,
											  'icon-eye': !passwordTextType2
											}" (click)="togglePasswordTextType2()"></i></span>
													</div>
												</div>
												<div *ngIf="TDRepeatPasswordRef.invalid && (accountDetailsForm.submitted || TDRepeatPasswordRef.touched || TDRepeatPasswordRef.dirty)"
													class="form-text text-danger">
													<small *ngIf="TDRepeatPasswordRef.errors.required"> Confirm password
														is required. </small>
													<small *ngIf="TDRepeatPasswordRef.errors.pattern">
														Password & Confirm Password does not match.</small>
												</div>
											</div>
										</div>
										<!-- <div class="row">
											<div class="form-group col-md-12">
												<label class="form-label" for="country">Country</label>
												<ng-select required [items]="selectBasic" bindLabel="name"> </ng-select>
											</div>
										</div> -->
										<div class="row">
											<div class="form-group col-md-12"
												*ngIf="countryList.length && selectedCountry.code">
												<label class="form-label" for="country">Country</label>
												<ngx-flag-picker class="form-control"
													[selectedCountryCode]="selectedCountry.code.toLowerCase()"
													[countryCodes]="countryCodes" [customLabels]='customLabels'
													[showArrow]='false'
													(changedCountryCode)="changeSelectedCountryCode($event)">
												</ngx-flag-picker>
											</div>
										</div>


										<div class="d-flex justify-content-end">
											<button [disabled]="loading"
												(click)="verticalWizardNext(accountDetailsForm)"
												class="btn btn-primary btn-next" rippleEffect>
												<span *ngIf="loading"
													class="spinner-border spinner-border-sm mr-1"></span><span
													class="align-middle d-sm-inline-block d-none">Next</span>
												<i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
											</button>
										</div>
									</form>
								</div>
								<div id="personal-info-vertical" style="margin:auto" class="content">
									<form #personalInfoForm="ngForm">
										<div class="row">
											<div class="form-group col-md-12">
												<label class="form-label" for="b-name">Business
													Name<span class="required-asterisk">*</span></label>
												<input [(ngModel)]="TDBName" (ngModelChange)="changeDomain()"
													(focus)="checkDomain($event)" (blur)="checkDomain($event)"
													#TDBNameRef="ngModel" name="bname" type="text" id="b-name"
													class="form-control" placeholder="John"
													[class.error]="personalInfoForm.submitted && TDBNameRef.invalid"
													required />
												<span
													*ngIf="(personalInfoForm.submitted || TDBNameRef.touched || TDBNameRef.dirty) && TDBNameRef.invalid"
													class="invalid-form">
													<small class="form-text text-danger"
														*ngIf="TDBNameRef.errors && TDBNameRef.errors.required">Business
														Name is
														required!</small>
												</span>
											</div>
										</div>
										<div class="row">
											<div class="form-group col-md-12">
												<label class="form-label" for="vertical-last-name">Business Domain
													Name<span class="required-asterisk">*</span></label>
												<div class="input-group">
													<input [(ngModel)]="TDBDomain" (focus)="checkDomain($event)"
														(blur)="checkDomain($event)" #TDBDomainRef="ngModel"
														name="bdomain" type="text" required id="vertical-last-name"
														class="form-control" placeholder="Doe"
														[class.error]="personalInfoForm.submitted && TDBDomainRef.invalid"
														pattern="[A-Za-z0-9]+" />
													<div class="input-group-append"><span id="basic-addon2"
															class="input-group-text">.{{envUrl}}</span></div>
												</div>
												<span
													*ngIf="(domainExist || !TDBDomainRef.valid ) && (personalInfoForm.submitted || TDBDomainRef.touched || TDBDomainRef.dirty || TDBNameRef.touched || TDBNameRef.dirty)"
													class="invalid-form">
													<small class="form-text text-danger"
														*ngIf="TDBDomainRef.errors && TDBDomainRef.errors.required">Business
														Domain is
														required!</small>
													<small class="form-text text-danger" *ngIf="domainExist">Domain
														already exists!</small>
													<small class="form-text text-danger"
														*ngIf="TDBDomainRef.errors && TDBDomainRef.errors.pattern">Domain
														cannot include space or special characters!</small>
												</span>
											</div>
										</div>
										<div class="row">
											<div class="form-group col-md-6">
												<label class="form-label" for="first-name">First Name<span class="required-asterisk">*</span></label>
												<input [(ngModel)]="TDFirstNameVar" #TDFirstNameRef="ngModel" required
													type="text" name="first-name" id="first-name" class="form-control"
													[class.error]="personalInfoForm.submitted && TDFirstNameRef.invalid" pattern="[a-zA-Z]+"
													placeholder="John" />
												<span
													*ngIf="(personalInfoForm.submitted || TDFirstNameRef.touched || TDFirstNameRef.dirty) && TDFirstNameRef.invalid"
													class="invalid-form">
													<small class="form-text text-danger"
														*ngIf="TDFirstNameRef.errors && TDFirstNameRef.errors.required">This
														field is
														required!</small>
												</span>
											</div>
											<div class="form-group col-md-6">
												<label class="form-label" for="last-name">Last Name<span class="required-asterisk">*</span></label>
												<input [(ngModel)]="TDLastNameVar" #TDLastNameRef="ngModel" required pattern="[a-zA-Z]+"
													type="text" name="last-name" id="last-name" class="form-control"
													[class.error]="personalInfoForm.submitted && TDLastNameRef.invalid"
													placeholder="Doe" />
												<span
													*ngIf="(personalInfoForm.submitted || TDLastNameRef.touched || TDLastNameRef.dirty) && TDLastNameRef.invalid"
													class="invalid-form">
													<small class="form-text text-danger"
														*ngIf="TDLastNameRef.errors && TDLastNameRef.errors.required">This
														field is
														required!</small>
												</span>
											</div>
										</div>

										<div class="row">
											<div class="form-group col-md-12"
												*ngIf="selectedCountry && selectedCountry.code">
												<label class="form-label" for="contact">Contact</label><br>

												<div class="input-group">
													<div class="input-group-prepend">
														<span class="input-group-text"><i class="mr-1"
																[ngClass]="['flag-icon-'+selectedCountry.code.toLowerCase(), 'flag-icon']"></i>{{selectedCountry.phone_code}}</span>
													</div>
													<input type="tel" [ngModel]="TDContactVar" maxlength="10" minlength="6"
														oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
														(ngModelChange)="TDContactVar=$event;validatePhone($event)"
														#ngModel #TDContactRef="ngModel" name="contact"
														class="form-control" id="contact" aria-describedby="contact" />
												</div>

												<span
													*ngIf=" (personalInfoForm.submitted || TDContactRef.touched || TDContactRef.dirty) && (TDContactRef.invalid || phoneInvalid)"
													class="invalid-form">
													<small class="form-text text-danger"
														*ngIf="TDContactRef.errors && TDContactRef.errors.required">This
														field is
														required!</small>
													<small class="form-text text-danger" *ngIf="phoneInvalid">Phone
														Number should be valid</small>
												</span>

											</div>
										</div>
										<div class="row">
											<div *ngIf="selectBusiness.length" class="form-group col-md-12">
												<label for="customSelectMulti">What does your business do?</label>
												<select class="custom-select" id="customSelectMulti" name="business-do">
													<option [value]="option.id" *ngFor="let option of selectBusiness">
														{{option.name}}</option>
												</select>
												<!-- <label class="form-label" for="business-do">What does your business
													do?</label>
												<ng-select name="business-do" [(ngModel)]="selectedBusiness"
													#TDBusinessType="ngModel" [items]="selectBusiness" bindLabel="name"
													bindValue="id" autofocus required>
												</ng-select> -->
											</div>
										</div>
										<div class="row">
											<div class="form-group col-md-12">
												<label class="form-label" for="business-own">How many stores do you
													own?</label>
												<select class="custom-select" id="customSelectMulti"
													name="business-own">
													<option [value]="option.val" *ngFor="let option of selectStores">
														{{option.name}}</option>
												</select>
												<!-- <ng-select required id="business-own" name="business-own"
													[(ngModel)]="selectedStore" [items]="selectStores" bindLabel="name"
													bindValue="val">
												</ng-select> -->
											</div>

										</div>



										<div class="d-flex justify-content-between">
											<button [disabled]="loading" (click)="verticalWizardPrevious()"
												class="btn btn-primary btn-prev" rippleEffect>
												<i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
												<span class="align-middle d-sm-inline-block d-none">Previous</span>
											</button>
											<button [disabled]="loading" (click)="completeRegister(personalInfoForm)"
												class="btn btn-primary btn-next" rippleEffect>
												<span *ngIf="loading"
													class="spinner-border spinner-border-sm mr-1"></span><span
													class="align-middle d-sm-inline-block d-none">Next</span>
												<i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
											</button>
											<!-- <button class="btn btn-success btn-submit"
												[disabled]="personalInfoForm.invalid" (click)="onSubmit()"
												rippleEffect>Submit</button> -->
										</div>
									</form>
								</div>
								<div id="verification-step-vertical" style="margin:auto" class="content">
									<form #verificationForm="ngForm">
										<div class="content-header">

											<small>Please verify your email.</small>

										</div>

										<div class="row align-items-center mb-3 justify-content-between">
											<div class="form-group col-md-5">
												<label class="form-label" for="verification">Verification Code</label>
												<input [(ngModel)]="TDVerifyVar" ngModel #TDVerifyRef="ngModel" required
													type="number" onkeypress="return event.charCode >= 48 && event.charCode <= 57 ||  event.charCode ==46" onKeyPress="if(this.value.length==6) return false;" name="verification" id="verification"
													class="form-control" [class.error]="!TDVerifyRef.valid"
													autocomplete="none" />
												<span
													*ngIf="!TDVerifyRef.valid && (verificationForm.submitted || TDVerifyRef.touched || TDVerifyRef.dirty)"
													class="invalid-form">
													<small class="form-text text-danger"
														*ngIf="TDVerifyRef.errors && TDVerifyRef.errors.required"> Code
														is
														required.
													</small>
												</span>
											</div>
											<div class="col-md-3 col-md-offset-0 text-center pt-2"
												style="justify-content: center;align-items: center;display: flex;">
												<button type="button" class="verify-btn btn-flat-success"
												[disabled]="seconds != 60" (click)="resendOTP()">{{(seconds !=
												60)?seconds:"Resend OTP"}}</button>
											</div>

										</div>
										<div class="d-flex  justify-content-between">
											<button class="btn btn-success btn-submit"
												[disabled]="verificationForm.invalid || loading" (click)="onSubmit()"
												rippleEffect>
												<span *ngIf="loading"
													class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
										</div>
									</form>
								</div>
								<div id="address-step-vertical" class="content fade active dstepper-block mx-auto">
									<div class="row">
										<div class="col-sm-12 d-flex justify-content-center align-items-center">
											<img src="../../../../../../assets/images/checked.png" alt="brand-logo" height="100" width="100">
										</div>
										<div class="col-sm-12 d-flex justify-content-center align-items-center">
											<h3 class="mb-0 pt-2">Completed</h3>
										</div>
										<div class="col-sm-12 d-flex justify-content-center align-items-center">
											<h5 class="mb-0 pt-2">Registration is complete</h5>
										</div>
										<div class="col-sm-12 mt-2 d-flex justify-content-center align-items-center">
											<a [href]="'https://'+TDBDomain+'.{{envUrl}}'" class="btn btn-primary btn-next pt-2" rippleEffect>
												<span class="align-middle d-sm-inline-block d-none">Login</span>
												<i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
											</a>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</section>
				<p class="text-center mt-2">
					<span>Already have an account?</span><a (click)="modalOpenVC(modalVC)"><span>&nbsp;Sign in
							instead</span></a>
				</p>
			</div>
		</div>
		<!-- /Register-->
	</div>
</div>

<!-- Modal -->
<ng-template #modalVC let-modal>
	<div class="modal-header">
		<h5 class="modal-title" id="exampleModalCenterTitle">Enter your store URL or Email</h5>
		<button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body" tabindex="0" ngbAutofocus>
		<ngb-alert [type]="'danger'" style="margin:0;padding:0" [dismissible]="false" *ngIf="loginError">
			<div class="alert-body">
				<p>{{ loginError }}.<br><a class="link text-info" style="text-decoration: underline;"
						(click)="register()"><i>Click Here to create new store.</i></a></p>
			</div>
		</ngb-alert>
		<form class="text-center p-2">
			<div class="form-group row">
				<div class="input-group col-12">
					<input [(ngModel)]="loginDomainVar" type="text" class="form-control" placeholder="Store URL"
						aria-label="Recipient's username" name="domain" aria-describedby="basic-addon2" />
					<div class="input-group-append">
						<span class="input-group-text" id="basic-addon2">.{{envUrl}}</span>
					</div>
				</div>
			</div>
			<h4 class="text-info">OR</h4>
			<div class="form-group row">
				<div class="input-group col-12">
					<input [(ngModel)]="loginEmailVar" type="text" class="form-control" placeholder="Email"
						aria-label="user email" name="loginEmail" aria-describedby="basic-addon2" />
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="login()" rippleEffect>
			Login
		</button>
	</div>
</ng-template>
<!-- / Modal -->
