import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
// import { AuthenticationService } from 'app/auth/service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Apollo, gql } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../auth-register-v2/authentication.service';
// import { Apollo, gql } from 'apollo-angular';
const envUrl=environment.envUrl
@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

	public domainVar;
	public emailVar;
	public toastStyle: object = {};
	public error = '';
	constructor(private _router: Router,
		private _authenticationService: AuthenticationService,
		 private modalService: NgbModal,
		 private apollo: Apollo
		 ) {
			this._authenticationService.isSubDomain.subscribe(val=>{
				// this.isSubDomain=val
				console.log(val)
				if(val){
				  this._router.navigate(['/login'])
				}
			  })
		}

	checkLogin(): Boolean {
		// redirect to home if already logged in
		// return (this._authenticationService.currentUserValue) ? true : false;
		return;
	}
	ngOnInit(): void {
	}


	openModal(name) {
		this.modalService.open(name, {
			centered: true,
			windowClass: 'modal modal-danger',
			// size:'lg'
		})
	}

	register() {
		this._router.navigate(['/register']);
		this.modalService.dismissAll();
	}

	checkDomain() {

		if (this.domainVar != "") {
			this.apollo.query({
				query: gql`
					query {
						checkDomainExists(input:{domain:"${this.domainVar + `.${envUrl}`}"}) {
							message
					  		available
						}
				  	}`
			}).subscribe((response: any) => {
				console.log(response);
				if (!response.data.checkDomainExists.available) {
					let domainName=this.domainVar + `.${envUrl}`
					this.modalService.dismissAll();
					window.location.href = "https://"+domainName;


				} else {
					this.error = "Store is not available";
				}

			})
		}

	}

	checkEmail() {
		if (this.emailVar != "") {
			this.apollo.query({
				query: gql`
					query {
						checkEmailExists(input:{email:"${this.emailVar}"}) {
							message
					  		available
						}
				  	}`
			}).subscribe((response: any) => {
				console.log(response);
				if (!response.data.checkEmailExists.available) {

					this.apollo.query({
						query: gql`
							query {
								getDomainByEmail(email:"${this.emailVar}") {
									message
									domain
								}
							}
						`
					}).subscribe((res: any) => {
						if (res.data.getDomainByEmail.domain != null) {
							sessionStorage.setItem('domainName', res.data.getDomainByEmail.domain);
							this.modalService.dismissAll();
							window.location.href =  "https://"+res.data.getDomainByEmail.domain
							// this._router.navigate([`/login`], { state: { email: this.emailVar } });
						} else {
							this.error = res.data.getDomainByEmail.message;
						}
					})
				} else {
					this.error = response.data.checkEmailExists.message;
				}

			})

		}
	}

	login() {

		if (this.emailVar && this.emailVar != "") {
			this.checkEmail();
		}
		else if (this.domainVar && this.domainVar != "") {
			this.checkDomain();
		} else {
			this.error = "Please enter either your email or domain";
		}

	}

}
