<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">



    <app-navbar></app-navbar>

<div id="main-slider" data-slider-width="100%" data-slider-height="300px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider main-slider_mod-a slider-pro">
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide"><img src="assets/media/components/b-main-slider/bg-5.jpg" alt="slider" class="sp-image"/>
        <div class="container">
          <div class="row">
            <div class="col-md-8">

              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="2200" data-hide-delay="400" class="main-slider__info sp-layer"><img src="assets/media/general/grocerypos.png" alt="slider"/></div>
            </div>
          </div>
        </div>
      </div>
      <!-- Slide 2-->

    </div>
  </div>
  <!-- end .main-slider-->
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="section-area">
          <div class="b-request-estimate">
            <div class="b-request-estimate__info">GET Store POS is  that comes loaded with all the features that modernised and simplify operations, increase sales, and delight guests.</div>
            <div class="b-request-estimate__title bg-primary"><span class="ui-decor-2"></span>Retail POS System</div>
          </div>
          <!-- end .b-request-estimate-->
        </div>
      </div>
    </div>
  </div>
<!-- START THE NAVBAR -->
<section class="section-default retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <i class="fa fa-shopping-bag" aria-hidden="true"></i>
    <h1>Make sales process comfortable</h1>
    <ul>
        <li>Print barcodes for your goods and add the items to the ticket with just a click by using a barcode scanner or built-in device camera.</li>
        <li>Use item variants for goods with multiple versions, like different sizes or colors.</li>
        <li>Apply discounts and taxes, and make notes to the items or to the receipts.</li>
        <li>Create and use different payment types, such as cash, card, cheque, etc.</li>
    </ul>

</div>
<div class="col-md-6">
    <img src="assets/media/general/sales.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row">
<div class="col-md-6">
    <img src="assets/media/general/cds.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <i class="fa fa-group" aria-hidden="true"></i>
    <h1>Make your customers feel comfortable in your store</h1>
    <ul>
        <li>Display purchase information to your customers on a customer facing display simultaneously during the sale process.</li>
        <li>Build a customer base to communicate more effectively with your consumers.</li>
        <li>Motivate customers to visit your store again by rewarding them with bonus points using the loyalty program</li>
        <li>Make notes about each buyer's personal preferences and provide the best customer service.</li>
    </ul>
</div>
</div>
</div>
</section>

<section class=" retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <i class="fa fa-shopping-bag" aria-hidden="true"></i>
    <h1>Manage your employees</h1>
    <ul>
        <li>Track each employee’s sales performance and make informed business decisions.</li>
        <li>Information on the workload per hour allows for efficient management of your staff.</li>
        <li>Manage employee access to sensitive information and functions.</li>
        <li>Employees can clock in/out, and the system calculates their total hours.</li>
        <li>Identify any cash discrepancy at the end of the shift and track employees’ inaccuracy or manipulations.</li>
    </ul>
    <a href="#" class="">Read more about Employee management</a>
</div>
<div class="col-md-6">
    <img src="assets/media/general/timecard.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row">
<div class="col-md-6">
    <img src="assets/media/general/multishop.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <i class="fa fa-handshake-o" aria-hidden="true"></i>
    <h1>Scale your business</h1>
    <ul>
        <li>Manage multiple stores under one account.</li>
        <li>Track your sales, inventory, employees, and customers — everything you need to know in one place.</li>
        <li>Track the sales growth or decline compared with the previous day, week, or month, and promptly react to changes.</li>
        <li>Sales by item analytics allow the best-selling items to be determined and therefore, the right purchasing decisions can be made.</li>
    </ul>
</div>
</div>
</div>
</section>

<section class=" retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <i class="fa fa-cubes" aria-hidden="true"></i>
    <h1>Manage your inventory</h1>
    <ul>
        <li>Quickly fill up your stock with goods using purchase orders.</li>
        <li>Transfer goods between your sales venues using transfer orders.</li>
        <li>Keep your stock always accurate by performing counting and stock correction in the Advanced Inventory System.</li>
        <li>View report on the cost and potential profit of your inventory.</li>
    </ul>
</div>
<div class="col-md-6">
    <img src="assets/media/general/inventory.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<div class="container retail">
<div class="row">
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_green section-radius" style="background-position: -62.7px 20.4px;">
            <div class="b-contact__name">Website</div>
            <div class="b-contact__info">{{'envUrl' | envName }}</div>
            <div class="b-contact__icon icon-globe"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_blue section-radius" style="background-position: -296.7px 20.4px;">
            <div class="b-contact__name">Phone</div>
            <div class="b-contact__info"> +60 3-7803 4040</div>
            <div class="b-contact__icon icon-call-in"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_grey section-radius" style="background-position: -530.7px 20.4px;">
            <div class="b-contact__name">Email</div>
            <div class="b-contact__info">getstore@gmail.com</div>
            <div class="b-contact__icon icon-envelope-open"></div>
          </div>
          <!-- end b-contact-->
        </div>
      </div>
</div>

  <section class="b-taglines area-bg bg-primary_a parallax">
    <div class="area-bg__inner">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 col-sm-offset-3">
            <div class="b-taglines__inner">
              <h1 class="">Download Get Store POS</h1>
              <div class="b-taglines__text col-sm-offset-1">For iPhone, iPad, Android smartphone or tablet</div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/gplay-en.png" alt="foto" class="img-responsive"/></a></div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/appstore-en.png" alt="foto" class="img-responsive"/></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>

</div>
<!-- end layout-theme-->

<!-- //div fix-->
<script>
$(document).ready(function() {
$('.nav li a').click(function(e) {
    $('.nav li').removeClass('active');
    var $parent = $(this).parent();
    console.log($parent);
    if (!$parent.hasClass('active')) {
        $parent.addClass('active');
    }
    e.preventDefault();
});
})
</script>
