<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">



  <app-navbar></app-navbar>

  <div id="main-slider" data-slider-width="100%" data-slider-height="500px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider slider-pro">
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide"><img src="assets/media/components/b-main-slider/product-bg-8.jpg" alt="slider" class="sp-image"/>
        <div class="container">
          <div class="row">
            <div class="col-sm-10 col-sm-offset-1">
              <div data-width="100%" data-show-transition="right" data-hide-transition="left" data-show-duration="1200" data-show-delay="2000" data-hide-delay="400" data-vertical="350px" data-horizontal="300" class="main-slider__item-1 sp-layer"><a href="#" ><img src="assets/media/general/gplay-en.png" alt="Item"/></a></div>

<div data-width="100%" data-show-transition="left" data-hide-transition="right" data-show-duration="1200" data-show-delay="2000" data-hide-delay="400" data-vertical="350px" data-horizontal="600" class="main-slider__item-1 sp-layer"><a href="#" ><img src="assets/media/general/appstore-en.png" alt="Item"/></a></div>

              <h2 data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="800" data-show-delay="400" data-hide-delay="400" class="main-slider__title sp-layer">Multi-store Loyverse POS</h2>
              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="1200" data-show-delay="600" data-hide-delay="400" class="sp-layer">
                <div class="main-slider__decor bg-primary"></div>
              </div>
            <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="1200" data-hide-delay="400" class="main-slider__info sp-layer">Expand your business with ease just by adding new outlets whenever you need it</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end .main-slider-->
<!-- START THE NAVBAR -->
<section class="retail">
<div class="container">
<div class="row">
<div class="col-sm-6">
<h1>Growth</h1>
    <h3>Continuously grow to a powerful chain by analyzing and multiplying the best practices for each outlet</h3>
<br>
<ul>
    <li>Scale your business by adding all necessary outlets in just one click</li>
<li>Easy start from one store, coffee shop or restaurant and expand to several ones</li>
<li>Keep growing from several outlets to hundreds</li>
<li>Continuously polish your business operations and logistics based on the consolidated sales analytic of your chain</li>
</ul>
</div>
<div class="col-sm-6"><img src="assets/media/general/multistore-illustration.jpg" alt="foto" class="img-responsive"/></div>
</div>
</div>
</section>

<section class="bg-grey retail">
    <div class="container">
        <div class="row">
            <div class="col-sm-10 col-sm-offset-1 newp pb-2 text-center">
                <div class="ui-decor-1">
                    <img src="assets/media/general/ui-decor-1.png" alt="decor" class="center-block">
                </div>
                <h1>Inventory management</h1>
                <p>Manage your inventory for all stores from one place</p>
                <br>
                <img src="assets/media/general/inventory-report.png" alt="foto" class="img-responsive col-sm-offset-1"/><br>
            </div>
            <div class="clearfix"></div>
            <ul class="col-sm-offset-2">
                <li>Add your items to all your outlets with importing functionality</li>
                <li>Track your inventory for all the outlets and order them with Purchase orders</li>
                <li>Transfer items between stores</li>
                <li>Set different prices to different stores to find the best pricing strategy</li>
            </ul>
        </div>
    </div>
</section>

<section class="retail">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <img src="assets/media/general/timecard.png" alt="foto" class="img-responsive col-sm-offset-1"/><br>
            </div>
            <div class="col-md-6">
                <h1>Employees</h1>
                <h3>Track the employees’ performance across all of your chain stores</h3>
                <ul>
                    <li>Set up the access of your workers only to certain stores and needed functionality</li>
                    <li>Check the employee performance and reward them accordingly</li>
                    <li>Control the working hours of your employees and use this data to pay them</li>
                    <li>Find out the busiest hours across all stores and manage to cover them by needed human resources</li>
                </ul>
            </div>
        </div>
    </div>
</section>

<section class="bg-grey retail">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h1>Customer care</h1>
                <h3>Manage a consolidated customer base from all of your outlets in one place</h3>
                <ul>
                    <li>Use the same rewarding system across all of your chain stores</li>
                    <li>Reward regular purchasing customers from any locations</li>
                    <li>See purchase history of your customers to know them better</li>
                    <li>Add new customers with one click from any POS or from the Back Office</li>
                </ul>
            </div>
            <div class="col-md-6">
                <img src="assets/media/general/customer.png" alt="foto" class="img-responsive col-sm-offset-1"/><br>
            </div>
        </div>
    </div>
</section>
<section class="retail">
<div class="container">
<div class="row">
<div class="col-sm-10 col-sm-offset-1 newp pb-2 text-center">
<div class="ui-decor-1"><img src="assets/media/general/ui-decor-1.png" alt="decor" class="center-block"></div>
<h1>Reporting</h1>
    <p>Check all essential performance indicators of your chan stores in real-time and make balanced decisions</p>
    <br>
    <img src="assets/media/general/sales-by-item-1.png" alt="foto" class="img-responsive col-sm-offset-1"/><br>
</div>
<div class="clearfix"></div>
<ul class="col-sm-offset-2">
<li>Get an overview of the entire operations across all of your outlets</li>
<li>Compare the performance of your outlets and set benchmarks of growth for each of your chain stores</li>
<li>Check the important parameters in different stores, such as gross sales, net sales, gross profit, and many more</li>
<li>See the best selling products and expend your product line with the most selling items</li>
</ul>
</div>
</div>
</section>

<div class="bg-grey container retail">
<div class="row">
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_green section-radius" style="background-position: -62.7px 20.4px;">
            <div class="b-contact__name">Website</div>
            <div class="b-contact__info">{{'envUrl' | envName }}</div>
            <div class="b-contact__icon icon-globe"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_blue section-radius" style="background-position: -296.7px 20.4px;">
            <div class="b-contact__name">Phone</div>
            <div class="b-contact__info"> +60 3-7803 4040</div>
            <div class="b-contact__icon icon-call-in"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_grey section-radius" style="background-position: -530.7px 20.4px;">
            <div class="b-contact__name">Email</div>
            <div class="b-contact__info">getstore@gmail.com</div>
            <div class="b-contact__icon icon-envelope-open"></div>
          </div>
          <!-- end b-contact-->
        </div>
      </div>
</div>

  <section class="b-taglines area-bg bg-primary_a parallax">
    <div class="area-bg__inner">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 col-sm-offset-3">
            <div class="b-taglines__inner">
              <h1 class="">Download Get Store POS</h1>
              <div class="b-taglines__text col-sm-offset-1">For iPhone, iPad, Android smartphone or tablet</div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/gplay-en.png" alt="foto" class="img-responsive"/></a></div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/appstore-en.png" alt="foto" class="img-responsive"/></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>

</div>
<!-- end layout-theme-->

<!-- //div fix-->
<script>
$(document).ready(function() {
$('.nav li a').click(function(e) {
    $('.nav li').removeClass('active');
    var $parent = $(this).parent();
    console.log($parent);
    if (!$parent.hasClass('active')) {
        $parent.addClass('active');
    }
    e.preventDefault();
});
})
</script>
