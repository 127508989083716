<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">



  <app-navbar></app-navbar>

<div id="main-slider" data-slider-width="100%" data-slider-height="300px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider main-slider_mod-a slider-pro">
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide"><img src="assets/media/components/b-main-slider/bg-14.jpg" alt="slider" class="sp-image"/>
        <div class="container">
          <div class="row">
            <div class="col-md-8">

              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="2200" data-hide-delay="400" class="main-slider__info sp-layer"><img src="assets/media/general/marketplace.png" alt="slider"/></div>
            </div>
          </div>
        </div>
      </div>
      <!-- Slide 2-->

    </div>
  </div>
  <!-- end .main-slider-->
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="section-area">
          <div class="b-request-estimate">
            <div class="b-request-estimate__info">GET Store POS is  that comes loaded with all the features that modernised and simplify operations, increase sales, and delight guests.</div>
            <div class="b-request-estimate__title bg-primary"><span class="ui-decor-2"></span>App Marketplace</div>
          </div>
          <!-- end .b-request-estimate-->
        </div>
      </div>
    </div>
  </div>
  <!-- end b-title-page-->

    <!--  questions-->
<section class="section-advantages">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-md-offset-2">
          <div class="ui-decor-1"><img src="assets/media/general/ui-decor-1.png" alt="decor" class="center-block"></div>
          <div class="text-center">
            <h2 class="ui-title-block ui-title-block_weight_normal">Get Store<span class="text-primary"> App Marketplace</span> </h2>
            <div class="ui-subtitle-block">Find and connect apps for your business to Get Store</div>
          </div>
        </div>
      </div>
      <div class="row marketplace">
        <div class="col-md-4 col-sm-6">
          <section class="b-advantages b-advantages-1"><i class="b-advantages__icon text-primary stroke icon-UploadCLoud"></i>
            <div class="b-advantages__inner">
              <h3 class="b-advantages__title ui-title-inner">Sync to Cloud</h3>
              <div class="b-advantages__info">Sign in and work from wherever your sales, product and reports are ever-available, secure, and up to date with the GET Store Store cloud-based POS system. </div>
            </div>
              <a href="/features" class="btn btn-default btn-xs"><i class="icon"></i>Read More</a>
          </section>
          <!-- end .b-advantages-->

        </div>
        <div class="col-md-4 col-sm-6">
          <section class="b-advantages b-advantages-1"><i class="b-advantages__icon text-primary stroke icon-Dollars"></i>
            <div class="b-advantages__inner">
              <h3 class="b-advantages__title ui-title-inner">Integrated Payment</h3>
              <div class="b-advantages__info">E-Wallets are widely being used for most transactions as it aims at inculcating a tendency of leaning towards a cashless payment medium for fast and efficient transactions. </div>
            </div>
              <a href="/features" class="btn btn-default btn-xs"><i class="icon"></i>Read More</a>
          </section>
          <!-- end .b-advantages-->

        </div>
        <div class="col-md-4 col-sm-6">
          <section class="b-advantages b-advantages-1"><i class="b-advantages__icon text-primary icon-pie-chart"></i>
            <div class="b-advantages__inner">
              <h3 class="b-advantages__title ui-title-inner">POS Features</h3>
              <div class="b-advantages__info">Don't let an unstable internet slow down your efficiency . Once the link is restored, all the data will be immediately synced.

The ability to set up the offline at any suitable place and time,</div>
            </div>
              <a href="/features" class="btn btn-default btn-xs"><i class="icon"></i>Read More</a>
          </section>
          <!-- end .b-advantages-->

        </div>
        <div class="col-md-4 col-sm-6">
          <section class="b-advantages b-advantages-1"><i class="b-advantages__icon text-primary stroke icon-StorageBox"></i>
            <div class="b-advantages__inner">
              <h3 class="b-advantages__title ui-title-inner">Inventory management</h3>
              <div class="b-advantages__info">No more counting your stocks manually after each sale transaction! Minimize human-induced errors and manage your inventory and stock effortlessly using our platform.</div>
            </div>
              <a href="/features" class="btn btn-default btn-xs"><i class="icon"></i>Read More</a>
          </section>
          <!-- end .b-advantages-->

        </div>
        <div class="col-md-4 col-sm-6">
          <section class="b-advantages b-advantages-1"><i class="b-advantages__icon text-primary stroke icon-Mastercard"></i>
            <div class="b-advantages__inner">
              <h3 class="b-advantages__title ui-title-inner">Store Credits</h3>
              <div class="b-advantages__info">StoreHub’s Store Credits feature helps fulfill your Customer Relationship Management efforts. Collect customer data, reward them with Store Credits and procure their loyalty! </div>
            </div>
              <a href="/features" class="btn btn-default btn-xs"><i class="icon"></i>Read More</a>
          </section>
          <!-- end .b-advantages-->

        </div>
        <div class="col-md-4 col-sm-6">
          <section class="b-advantages b-advantages-1"><i class="b-advantages__icon text-primary stroke icon-Truck"></i>
            <div class="b-advantages__inner">
              <h3 class="b-advantages__title ui-title-inner">Supplier management</h3>
              <div class="b-advantages__info">Get Store POS systems include integrated supplier management. This system allows you to enter supplier details when you create the product.</div>
            </div>
              <a href="/features" class="btn btn-default btn-xs"><i class="icon"></i>Read More</a>
          </section>
          <!-- end .b-advantages-->

        </div>
      </div>
    </div>
  </section>
  <section class="b-taglines area-bg bg-primary_a parallax">
    <div class="area-bg__inner">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 col-sm-offset-3">
            <div class="b-taglines__inner">
              <h1 class="">Download Get Store POS</h1>
              <div class="b-taglines__text col-sm-offset-1">For iPhone, iPad, Android smartphone or tablet</div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/gplay-en.png" alt="foto" class="img-responsive"/></a></div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/appstore-en.png" alt="foto" class="img-responsive"/></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    <!-- end questions-->
  <section class="section-contact">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <div class="ui-decor-1"><img src="assets/media/general/ui-decor-1.png" alt="decor" class="center-block"></div>
          <div class="text-center">
            <h2 class="b-contact__title ui-subtitle-block">Contact us if you need our services. We will be happy to make your services memorable!</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_green section-radius">
            <div class="b-contact__name">Website</div>
            <div class="b-contact__info">{{'envUrl' | envName }}</div>
            <div class="b-contact__icon icon-globe"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_blue section-radius">
            <div class="b-contact__name">Phone</div>
            <div class="b-contact__info"> +60 3-7803 4040</div>
            <div class="b-contact__icon icon-call-in"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_grey section-radius">
            <div class="b-contact__name">Email</div>
            <div class="b-contact__info">getstore@gmail.com</div>
            <div class="b-contact__icon icon-envelope-open"></div>
          </div>
          <!-- end b-contact-->
        </div>
      </div>
<div class="section-form-contact pb-2 pt-2">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h2 class="ui-title-block"><i class="ui-decor-2 bg-primary"></i> message form</h2>
          <div id="success"></div>
          <form id="contactForm" action="#" method="post" class="b-form-contacts ui-form">
            <div class="row">
              <div class="col-md-6">
                <input id="user-name" type="text" name="user-name" placeholder="Your Name" required="required" class="form-control"/>
                <input id="user-phone" type="tel" name="user-phone" placeholder="Your Tel" class="form-control"/>
                 <input id="user-name" type="text" name="company" placeholder="Company" required="required" class="form-control"/>
              </div>
              <div class="col-md-6">
                <input id="user-email" type="email" name="user-email" placeholder="Your Email" class="form-control"/>
                <input id="user-name" type="text" name="store-name" placeholder="Store Name" required="required" class="form-control"/>
                <input id="user-subject" type="text" name="user-subject" placeholder="Country" class="form-control last-block_mrg-btn_0"/>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <textarea id="user-message" rows="5" placeholder="What is your primary business system need?" required="required" class="form-control"></textarea>
                <button class="btn btn-primary btn-block">send  comment</button>
              </div>
            </div>
          </form>
          <!-- end .b-form-contact-->
        </div>
        <div class="col-md-6">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6700.199961740027!2d101.59078482942296!3d3.1045554658308263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4c227d48ff09%3A0x78137e41d04f03e!2sKelana%20Square!5e0!3m2!1sen!2sin!4v1622114189291!5m2!1sen!2sin" width="100%" height="550" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
    </div>
  </div>
    </div>
  </section>


  <div class="b-contact-social-net bg-grey text-center">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <ul class="social-net list-inline">
            <li class="social-net__item"><a href="twitter.com" class="social-net__link text-primary_h"><i class="icon fa fa-twitter"></i></a></li>
            <li class="social-net__item"><a href="facebook.com" class="social-net__link text-primary_h"><i class="icon fa fa-facebook"></i></a></li>
            <li class="social-net__item"><a href="plus.google.com" class="social-net__link text-primary_h"><i class="icon fa fa-google-plus"></i></a></li>
            <li class="social-net__item"><a href="instagram.com" class="social-net__link text-primary_h"><i class="icon fa fa-instagram"></i></a></li>
            <li class="social-net__item"><a href="skype.com" class="social-net__link text-primary_h"><i class="icon fa fa-skype"></i></a></li>
            <li class="social-net__item"><a href="behance.com" class="social-net__link text-primary_h"><i class="icon fa fa-behance"></i></a></li>
            <li class="social-net__item"><a href="vimeo.com" class="social-net__link text-primary_h"><i class="icon fa fa-vimeo"></i></a></li>
          </ul>
          <!-- end social-list-->
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>

</div>
<!-- end layout-theme-->

