import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

class User {
  id: number;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  avatar: string;
  role: Role;
  token?: string;
}

enum Role {
  Admin = 'Admin',
  Client = 'Client',
  User = 'User'
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	//public
	public currentUser: Observable<User>;
	public isSubDomain=new BehaviorSubject<boolean>(false)
	public domain=new BehaviorSubject<any>(null)
	//private
	private currentUserSubject: BehaviorSubject<User>;

	/**
	 *
	 * @param {HttpClient} _http
	 * @param {ToastrService} _toastrService
	 */
	constructor(  private apollo: Apollo, private _router: Router) {
		this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
		this.currentUser = this.currentUserSubject.asObservable();
	}

	// getter: currentUserValue
	public get currentUserValue(): User {
		return this.currentUserSubject.value;
	}

	/**
	 *  Confirms if user is admin
	 */
	get isAdmin() {
		return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
	}

	/**
	 *  Confirms if user is client
	 */
	get isClient() {
		return this.currentUser && this.currentUserSubject.value.role === Role.Client;
	}





	async apiLogin(email: string, password: string, domain: string) {
		await this.apollo.client.resetStore();
		let user: any = {};

		return this.apollo.mutate({
			mutation: gql`mutation {
			posLogin(input:{email:"${email}",password:"${password}",domainName:"${domain}"}) {
			accessToken,
			isTempPassword,
			refreshToken,
			message
		  }
		}`}).pipe(
				map((data: any) => {

					//login successful if there's a jwt token in the response
					if (data.data.posLogin && data.data.posLogin.accessToken) {

						user.email = email;
						user.isTempPassword = data.data.posLogin.isTempPassword;
						user.token = data.data.posLogin.accessToken;

						// store user details and jwt token in local storage to keep user logged in between page refreshes
						sessionStorage.setItem('currentUser', JSON.stringify(user))


						// Display welcome toast!
						setTimeout(() => {
							// this._toastrService.info(
							// 	'You have successfully logged in to GetStorePos. Now you can start to explore. Enjoy! 🎉',
							// 	'👋 Welcome!'
							// );
						}, 2500);
						// notify
						this.currentUserSubject.next(user);
						return user;
					} else {
						return data.data.posLogin;
					}


				}, (error) => error));

	}




	/**
	 * User logout
	 *
	 */
	async logout(toastMessage, toastTitle, redirectTo?, redirectOpt?) {

		// this._toastrService.info(
		// 	toastMessage,
		// 	toastTitle
		// );

		// remove user from local storage to log user out
		sessionStorage.clear()
		// sessionStorage.removeItem('currentUser');
		// sessionStorage.removeItem('moduleFeature');
		this.currentUserSubject.next(null);
		this.apollo.client.stop();
		await this.apollo.client.resetStore();
		if (!redirectTo)
			this._router.navigate(['/login'])
		else this._router.navigate([redirectTo, redirectOpt]);


	}


}
