import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthRegisterV2Component } from "./front/auth-register-v2/auth-register-v2.component";
import { BackofficeComponent } from "./front/backoffice/backoffice.component";
import { BarComponent } from "./front/bar/bar.component";
import { TermsAndConditonComponent } from "./front/terms-and-condition/terms-and-condition.component";
import { CafeComponent } from "./front/cafe/cafe.component";
import { CustomerComponent } from "./front/customer/customer.component";
import { DashboardComponent } from "./front/dashboard/dashboard.component";
import { EmployeeComponent } from "./front/employee/employee.component";
import { FashionComponent } from "./front/fashion/fashion.component";
import { FeaturesComponent } from "./front/features/features.component";
import { GroceryComponent } from "./front/grocery/grocery.component";
import { HardwareComponent } from "./front/hardware/hardware.component";
import { HelpComponent } from "./front/help/help.component";
import { InventoryComponent } from "./front/inventory/inventory.component";
import { KitchenComponent } from "./front/kitchen/kitchen.component";
import { MarketplaceComponent } from "./front/marketplace/marketplace.component";
import { MultistoreComponent } from "./front/multistore/multistore.component";
import { OfficeComponent } from "./front/office/office.component";
import { PartnersComponent } from "./front/partners/partners.component";
import { PricingComponent } from "./front/pricing/pricing.component";
import { RestaurantComponent } from "./front/restaurant/restaurant.component";
import { RetailComponent } from "./front/retail/retail.component";
import { PrivacyPolicy } from "./front/privacy-policy/privacy-policy.component";
export const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./front/front.module").then((m) => m.FrontModule),
  },
  {
    path: "features",
    component: FeaturesComponent,
  },
  {
    path: "employee",
    component: EmployeeComponent,
  },
  {
    path: "back-office",
    component: BackofficeComponent,
  },
  {
    path: "kitchen",
    component: KitchenComponent,
  },
  {
    path: "customer",
    component: CustomerComponent,
  },
  {
    path: "multi-store",
    component: MultistoreComponent,
  },
  {
    path: "dashboard",
    component: DashboardComponent,
  },
  {
    path: "inventory",
    component: InventoryComponent,
  },
  {
    path: "hardware",
    component: HardwareComponent,
  },
  {
    path: "pricing",
    component: PricingComponent,
  },
  {
    path: "marketplace",
    component: MarketplaceComponent,
  },
  {
    path: "help",
    component: HelpComponent,
  },
  {
    path: "partners",
    component: PartnersComponent,
  },
  {
    path: "office",
    component: OfficeComponent,
  },
  {
    path: "bar",
    component: BarComponent,
  },
  {
    path: "fashion",
    component: FashionComponent,
  },
  {
    path: "groceries",
    component: GroceryComponent,
  },
  {
    path: "retail",
    component: RetailComponent,
  },
  {
    path: "restaurant",
    component: RestaurantComponent,
  },
  {
    path: "cafe",
    component: CafeComponent,
  },
  {
    path: "signup",
    component: AuthRegisterV2Component,
  },
  {
    path: "terms-and-condition",
    component: TermsAndConditonComponent,
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicy,
  },
];
