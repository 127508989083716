<!-- Loader-->
<div id="page-preloader">
  <span class="spinner border-t_second_b border-t_prim_a"></span>
</div>
<!-- Loader end-->

<div
  data-header="sticky"
  data-header-top="200"
  data-canvas="container"
  class="l-theme animated-css"
>
  <app-navbar></app-navbar>
  <div
    id="main-slider"
    data-slider-width="100%"
    data-slider-height="300px"
    data-slider-arrows="true"
    data-slider-buttons="false"
    class="main-slider main-slider_mod-a slider-pro"
  >
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide">
        <img
          src="assets/media/components/b-main-slider/bg-13.jpg"
          alt="slider"
          class="sp-image"
        />
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <div
                data-width="100%"
                data-show-transition="left"
                data-hide-transition="left"
                data-show-duration="2000"
                data-show-delay="2200"
                data-hide-delay="400"
                class="main-slider__info sp-layer"
              >
                <h1 class="white">
                  Get Store POS PURCHASER / MERCHANT PRIVACY POLICY
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Slide 2-->
    </div>
  </div>
  <!-- end .main-slider-->
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="section-area">
          <div class="b-request-estimate">
            <div class="b-request-estimate__info">
              GET Store POS is that comes loaded with all the features that
              modernised and simplify operations, increase sales, and delight
              guests.
            </div>
            <div class="b-request-estimate__title bg-primary">
              <span class="ui-decor-2"></span>POLICY
            </div>
          </div>
          <!-- end .b-request-estimate-->
        </div>
      </div>
    </div>
  </div>
  <!-- START THE NAVBAR -->
  <!-- end b-title-page-->
  <div class="section-progress terms">
    <div class="container">
      <div class="row">
        <h1 class="text-center">INTRODUCTION</h1>
        <p>
          Please read this privacy policy carefully by accessing or using the
          website/mobile application. You agree to be bound by the terms
          described herein. If you do not agree to all of these terms, please do
          not use the website/mobile application. Using the website/mobile
          application will be considered as a deemed acceptance of this
          policy.<br />
          This policy sets out how we will collect, use, disclose and protect
          your personal information. This policy does not limit or exclude any
          of your rights under applicable legislation, except to the extent
          permitted by law. <br />
          In this policy, the Service is our service as described in our
          Purchaser Terms of Use. This Policy forms part of the Purchaser Terms
          of Use. <br />
          We collect and maintain information that you enter on our Website or
          give us in any other way (collectively,“ Personal Information”). You
          can choose not to provide certain information, but you may not be able
          to enjoy all the features of our Website and services provided by us.
          Personal Information we learn from you helps us to personalise and
          continually improve your online experience. Here are the types of
          Personal Information that we collect:
        </p>
        <ul>
          <li>
            Personally Identifiable information e.g. name, photo, gender, date
            of birth, nationality, passport/identification card number and
            country of residence;
          </li>
          <li>
            Contact information e.g. address (billing and shipping), email
            address and phone numbers;
          </li>
          <li>
            Payment information e.g. credit or debit card information, including
            the name of cardholder, card number, card issuing bank, card issuing
            country, card expiry date and banking account details ;
          </li>
          <li>
            Technical information e.g. IP address, time zone, device
            information; and
          </li>
          <li>
            Behavioural information e.g. features you use on our Services,
            transaction information
          </li>
        </ul>

        <h2>CHANGES TO THIS POLICY</h2>
        <p>
          We reserve the right to change, amend and/or vary may change this
          policy by uploading a revised policy onto our website, @
          www.getstorepos.com The change will apply from the date that we upload
          the revised policy. These Terms were last updated on 1 June 2022.
        </p>

        <h2>WHO DO WE COLLECT YOUR PERSONAL INFORMATION FROM</h2>
        <p>We collect personal information about you from:</p>
        <ul>
          <li>
            you, when you provide that personal information to us, including via
            the Service, the website and any related service, through any
            registration or subscription process, through any contact with us
            (e.g. telephone call or email), or when you buy or use our services
            and products
          </li>
          <li>
            third parties, including Getstorepos merchants, as part of the
            operation of the Service (such as your purchase of products or
            services from Get Store POS merchants), where you have authorised
            this, or the information is publicly available.
          </li>
        </ul>
        <p>
          If possible, we will collect personal information from you directly.
          Where we need to verify your identity (for example, if there are
          concerns around identity theft, or if you call into support and we
          need to authenticate your account), we may request that you provide us
          with government-issued identification information.
        </p>

        <h2>HOW WE USE YOUR PERSONAL INFORMATION</h2>
        <p>We will use your personal information:</p>
        <ul>
          <li>to verify your identity</li>
          <li>
            to provide services and products to you, including the Service,
            including to manage your shared login that you can use for all Get
            Store POS stores
          </li>
          <li>
            to personalise your experience when you use our Services and our
            communications when we contact you periodically to conduct
            performance improvement measures
          </li>
          <li>
            to market our services and products to you, including contacting you
            electronically (e.g. by text or email for this purpose)
          </li>
          <li>to improve the services and develop new services to you</li>
          <li>to undertake credit checks of you (if necessary)</li>
          <li>
            to collect money that you owe merchants, including authorising and
            processing credit card transactions
          </li>
          <li>
            to provide advertisers with reports about the kinds of people seeing
            their ads and how their ads are performing
          </li>
          <li>to respond to communications from you, including a complaint</li>
          <li>
            to help us conduct marketing and/or advertising campaigns, surveys,
            internal marketing analysis, customer profiling activities, analysis
            of customer patterns and choices, usage and activity trends analysis
            in relation to our Services and our users’ demographics (on an
            anonymised basis)
          </li>
          <li>
            to conduct research and statistical analysis (on an anonymised
            basis)
          </li>
          <li>
            if the merchant whose store you visit or access directs us to
            transfer this information (for example, if they enable a third party
            app or use a service or product provided by us that accesses your
            personal information)
          </li>
          <li>
            to protect and/or enforce our legal rights and interests, including
            defending any claim
          </li>
          <li>
            for any other purpose authorised by you or applicable privacy
            legislation.
          </li>
        </ul>

        <h2>DISCLOSING YOUR PERSONAL INFORMATION</h2>
        <p>
          We will not sell, rent, transfer or disclose any of your personal data
          to any third party without your consent. We may disclose your personal
          information to:
        </p>
        <ul>
          <li>merchants as part of providing the Service</li>
          <li>another company within our group</li>
          <li>
            any business that supports our services and products, including any
            person that hosts or maintains any underlying IT system or data
            centre that we use to provide the Service, the website or other
            services and products
          </li>
          <li>
            a credit reference agency for the purpose of credit checking you
          </li>
          <li>other third parties (for anonymised statistical information)</li>
          <li>
            a person who can require us to supply your personal information
            (e.g. a regulatory authority)
          </li>
          <li>
            any other person authorised by applicable law (e.g. a law
            enforcement agency)
          </li>
          <li>
            data centres and/or servers located within or outside your country
            for data storage purposes
          </li>
          <li>storage facility and records management service providers</li>
          <li>
            auditors, consultants, lawyers, accountants or other financial or
            professional advisers appointed in connection with our business on a
            strictly confidential basis, appointed by us to provide services to
            us; any party nominated or appointed by us either solely or jointly
            with other service providers, who provide services or conduct data
            processing on our behalf, or for data centralization and/or
            logistics purposes
          </li>
          <li>any other person authorised by you.</li>
          <p>
            A business that supports our services and products may be located
            outside India. This may mean your personal information is held and
            processed outside India. Personal information may also be disclosed
            to an entity that acquires our business or the business of a
            merchant whose Get Store POS store you access or use.
          </p>
        </ul>

        <h2>HOW LONG DO WE RETAIN YOUR PERSONAL INFORMATION</h2>
        <p>
          We keep your personal information for as long as the purposes for
          which the personal data was collected continues. This means your
          personal information will be destroyed or anonymised from our records
          and back-up systems in the event your personal data is no longer
          required for the said purposes.<br />
          Once you terminate your relationship with us, we generally will
          continue to store archived copies of your personal information for
          legitimate business purposes such as to defend a contractual claim or
          for audit purposes and to comply with the law, except when we receive
          a valid erasure request via hr@getstorepos.com, or, if you are a
          merchant, you terminate your account and your personal information is
          purged pursuant to our standard purge process.
        </p>

        <h2>PROTECTING YOUR PERSONAL INFORMATION</h2>
        <p>
          We will take reasonable steps to keep your personal information safe
          from loss, unauthorised activity, or other misuse.
        </p>
        <h3>Complaints and Grievance Redressal</h3>
        <p>
          Any complaints, abuse or concerns with regards to content and or
          comment or breach of these terms shall be immediately informed to the
          designated Grievance Officer as mentioned below via in writing or
          through email signed with the electronic signature.<br />
          We may charge you our reasonable costs of providing you copies of your
          personal information or correcting that information.
        </p>
        <br />
        <h3>Viswanadham Marni</h3>
        <p>FEATUREZEN INNOVATION TECHNIQUES PVT LTD</p>
        <p>Corporate Office: 305,Krishna Building,</p>
        <p>Kousalya Colony, Bachupally,</p>
        <p>Hyderabad 500090</p>
        <p>Email id: hr@getstorepos.com</p>

        <h2>INTERNET USE</h2>
        <p>
          While we take reasonable steps to maintain secure internet
          connections, if you provide us with personal information over the
          internet, the provision of that information is at your own risk. If
          you follow a link on our website to another site, the owner of that
          site will have its own privacy policy relating to your personal
          information. We suggest you review that site’s privacy policy before
          you provide personal information. We use cookies (an alphanumeric
          identifier that we transfer to your computer’s hard drive so that we
          can recognise your browser) to monitor your use of the website. You
          may disable cookies by changing the settings on your browser, although
          this may mean that you cannot use all of the features of the website.
        </p>

        <h2>SECURITY</h2>
        <p>
          We take appropriate technical and organisational measures to protect
          your personal information.
        </p>

        <h2>CONSENT</h2>
        <p>
          We or any third party engaged by us will obtain your consent and use
          your Personal Information at the time of collection, subject to
          certain legally prescribed circumstances where your consent is not
          required. Where required by law, we will obtain your express written
          consent when collecting your Personal Information, and we will obtain
          your explicit consent when processing your sensitive personal
          information. You may be asked to sign a form or tick a box. Generally,
          in using the website and our Services, you consent to the collection
          and use of your Personal Information in the ways described above
          (which may change from time to time) unless and until you inform us to
          the contrary or terminate your relationship with us.
        </p>
        <p>
          This Privacy Policy shall apply to the use of the Website by all
          Users. Accordingly, a condition of each User's use of and access to
          the Website and to the other services provided by the Company to Users
          is their acceptance of this Privacy policy. Any User is required to
          read and understand the provisions set out herein prior to submitting
          any Sensitive Personal Data or Information to the Company, failing
          which they are required to leave the Website immediately.
        </p>
        <p>
          This Privacy Policy is published in compliance of the (Indian)
          Information Technology Act, 2000 and the rules, regulations,
          guidelines and clarifications framed thereunder, including the
          (Indian) Information Technology (Reasonable Security Practices and
          Procedures and Sensitive Personal Data or Information) Rules, 2011.
        </p>
      </div>
    </div>
  </div>
  <app-footer></app-footer>

  <!-- end layout-theme-->

  <!-- //div fix-->
  <script>
    $(document).ready(function () {
      $(".nav li a").click(function (e) {
        $(".nav li").removeClass("active");
        var $parent = $(this).parent();
        console.log($parent);
        if (!$parent.hasClass("active")) {
          $parent.addClass("active");
        }
        e.preventDefault();
      });
    });
  </script>
</div>
