<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">



    <app-navbar></app-navbar>

<div id="main-slider" data-slider-width="100%" data-slider-height="300px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider main-slider_mod-a slider-pro">
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide"><img src="assets/media/components/b-main-slider/bg-6.jpg" alt="slider" class="sp-image"/>
        <div class="container">
          <div class="row">
            <div class="col-md-8">

              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="2200" data-hide-delay="400" class="main-slider__info sp-layer"><img src="assets/media/general/boutique-pos.png" alt="slider"/></div>

            </div>
          </div>
        </div>
      </div>
      <!-- Slide 2-->

    </div>
  </div>
  <!-- end .main-slider-->
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="section-area">
          <div class="b-request-estimate">
            <div class="b-request-estimate__info">GET Store POS is  that comes loaded with all the features that modernised and simplify operations, increase sales, and delight guests.</div>
            <div class="b-request-estimate__title bg-primary"><span class="ui-decor-2"></span>Fashion Retail Software</div>
          </div>
          <!-- end .b-request-estimate-->
        </div>
      </div>
    </div>
  </div>
<!-- START THE NAVBAR -->
<section class="section-default retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <span class="stroke icon-PicnicBasket"></span>
    <h1>Convenient sales</h1>
    <ul>
        <li>Make your sales quick and smooth with Loyverse POS software for fashion and retail.</li>
        <li>Add clothes and accessories to the ticket with one click using a barcode scanner or a built-in-device camera.</li>
        <li>Accept cards with both integrated and non-integrated card payment terminals.</li>
    </ul>

</div>
<div class="col-md-6">
    <img src="assets/media/general/sales.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row">
<div class="col-md-6">
    <img src="assets/media/general/variants.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <span class="stroke icon-Tshirt"></span>
    <h1>Goods management</h1>
    <ul>
        <li>Organize your goods by categories and customize your sale screen by different items, categories, and discounts.</li>
        <li>Group your clothes and shoes with variants functionality, such as sizes or colors.</li>
        <li>Give your customer a choice from a wide range of clothes, shoes, and accessories, and you can add items to Loyverse POS without any limitations.</li>
    </ul>
</div>
</div>
</div>
</section>

<section class=" retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <span class="stroke icon-ChartUp"></span>
    <h1>Sales analytics</h1>
    <ul>
        <li>Receive all sales analytics in real time from any place with cloud-based software for fashion store.</li>
        <li>Compare your sales by different shops, employees and periods and make balanced decisions.</li>
        <li>Check the best selling goods and less selling goods, and improve your variety of goods.</li>
    </ul>
</div>
<div class="col-md-6">
    <img src="assets/media/general/backoffice-devices.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row">
<div class="col-md-6">
    <img src="assets/media/general/customer.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <span class="stroke icon-Headset"></span>
    <h1>Customer care</h1>
    <ul>
        <li>Use our powerful loyalty system to make your customers return to your fashion or boutique store.</li>
        <li>Identify your regular customers and offer them promotions that will call them to actions.</li>
        <li>Keep notes about your regular customers to know their preferences.</li>
        <li>Check your customers’ purchase history to create tailored offers.</li>
    </ul>
</div>
</div>
</div>
</section>

<section class=" retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <span class="stroke icon-StorageBox"></span>
    <h1>Inventory management</h1>
    <ul>
        <li>Track your stock and keep all your products ready to sell to your customer.</li>
        <li>Use real-time sales data analysis to make informed inventory purchases.</li>
        <li>Distribute goods to your chain stores with the transfer orders.</li>
        <li>Keep all the data of your stock up to date with the inventory recount functionality.</li>
        <li>Identify any cash discrepancy at the end of the shift and track employees’ inaccuracy or manipulations.</li>
    </ul>
</div>
<div class="col-md-6">
    <img src="assets/media/general/inventory.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<div class="container retail">
<div class="row">
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_green section-radius" style="background-position: -62.7px 20.4px;">
            <div class="b-contact__name">Website</div>
            <div class="b-contact__info">{{'envUrl' | envName }}</div>
            <div class="b-contact__icon icon-globe"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_blue section-radius" style="background-position: -296.7px 20.4px;">
            <div class="b-contact__name">Phone</div>
            <div class="b-contact__info"> +60 3-7803 4040</div>
            <div class="b-contact__icon icon-call-in"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_grey section-radius" style="background-position: -530.7px 20.4px;">
            <div class="b-contact__name">Email</div>
            <div class="b-contact__info">getstore@gmail.com</div>
            <div class="b-contact__icon icon-envelope-open"></div>
          </div>
          <!-- end b-contact-->
        </div>
      </div>
</div>

  <section class="b-taglines area-bg bg-primary_a parallax">
    <div class="area-bg__inner">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 col-sm-offset-3">
            <div class="b-taglines__inner">
              <h1 class="">Download Get Store POS</h1>
              <div class="b-taglines__text col-sm-offset-1">For iPhone, iPad, Android smartphone or tablet</div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/gplay-en.png" alt="foto" class="img-responsive"/></a></div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/appstore-en.png" alt="foto" class="img-responsive"/></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>

</div>
<!-- end layout-theme-->

<!-- //div fix-->
<script>
$(document).ready(function() {
$('.nav li a').click(function(e) {
    $('.nav li').removeClass('active');
    var $parent = $(this).parent();
    console.log($parent);
    if (!$parent.hasClass('active')) {
        $parent.addClass('active');
    }
    e.preventDefault();
});
})
</script>
