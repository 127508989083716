<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">



  <app-navbar></app-navbar>

<div id="main-slider" data-slider-width="100%" data-slider-height="300px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider main-slider_mod-a slider-pro">
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide"><img src="assets/media/components/b-main-slider/bg-13.jpg" alt="slider" class="sp-image"/>
        <div class="container">
          <div class="row">
            <div class="col-md-8">

              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="2200" data-hide-delay="400" class="main-slider__info sp-layer"><img src="assets/media/general/help.png" alt="slider"/></div>
            </div>
          </div>
        </div>
      </div>
      <!-- Slide 2-->

    </div>
  </div>
  <!-- end .main-slider-->
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="section-area">
          <div class="b-request-estimate">
            <div class="b-request-estimate__info">GET Store POS is  that comes loaded with all the features that modernised and simplify operations, increase sales, and delight guests.</div>
            <div class="b-request-estimate__title bg-primary"><span class="ui-decor-2"></span>Help</div>
          </div>
          <!-- end .b-request-estimate-->
        </div>
      </div>
    </div>
  </div>
<!-- START THE NAVBAR -->
  <!-- end b-title-page-->
<div class="section-progress help">
    <div class="container">
      <div class="row">
          <h2 class="text-center">Choose Your Topic</h2>
        <div class="col-xs-12">
          <ul class="b-progress-list b-progress-list_mod-a list-unstyled">
            <li class="b-progress-list__item clearfix">
            <a href="#">
              <div class="b-progress-list__inner">
                <div class="b-progress-list__label"></div>
                  <div class="b-progress-list__icon text-primary icon-cup"></div>
                  <span class="b-progress-list__name">Getting Started</span>
              </div>
            </a>
            </li>
            <li class="b-progress-list__item clearfix">
            <a href="#">
              <div class="b-progress-list__inner">
                <div class="b-progress-list__label"></div>
                <div class="b-progress-list__icon text-primary stroke icon-Printer"></div>
                <span class="b-progress-list__name">Sales</span>
              </div>
            </a>
            </li>
            <li class="b-progress-list__item clearfix">
            <a href="#">
              <div class="b-progress-list__inner">
                <div class="b-progress-list__label"></div>
                  <div class="b-progress-list__icon text-primary icon-layers"></div>
                  <span class="b-progress-list__name">Items</span>
              </div>
            </a>
            </li>
            <li class="b-progress-list__item clearfix">
            <a href="#">
              <div class="b-progress-list__inner">
                <div class="b-progress-list__label"></div>
                  <div class="b-progress-list__icon text-primary stroke icon-StorageBox"></div>
                  <span class="b-progress-list__name">Advanced inventory</span>
              </div>
            </a>
            </li>
            <li class="b-progress-list__item clearfix">
            <a href="#">
              <div class="b-progress-list__inner">
                <div class="b-progress-list__label"></div>
                  <div class="b-progress-list__icon text-primary stroke icon-Rolodex"></div>
                  <span class="b-progress-list__name">Employees</span>
              </div>
            </a>
            </li>
            <li class="b-progress-list__item clearfix">
            <a href="#">
              <div class="b-progress-list__inner">
                <div class="b-progress-list__label"></div>
                  <div class="b-progress-list__icon text-primary stroke icon-Users"></div>
                  <span class="b-progress-list__name">Customers</span>
              </div>
            </a>
            </li>
            <li class="b-progress-list__item clearfix">
            <a href="#">
              <div class="b-progress-list__inner">
                <div class="b-progress-list__label"></div>
                <div class="b-progress-list__icon text-primary stroke icon-File"></div>
                <span class="b-progress-list__name">Reports</span>
              </div>
            </a>
            </li>
            <li class="b-progress-list__item clearfix">
            <a href="#">
              <div class="b-progress-list__inner">
                <div class="b-progress-list__label"></div>
                  <div class="b-progress-list__icon text-primary stroke icon-Settings"></div>
                  <span class="b-progress-list__name">Settings</span>
              </div>
            </a>
            </li>
            <li class="b-progress-list__item clearfix">
            <a href="#">
              <div class="b-progress-list__inner">
                <div class="b-progress-list__label"></div>
                  <div class="b-progress-list__icon text-primary stroke icon-Tools"></div>
                  <span class="b-progress-list__name">Hardware</span>
              </div>
            </a>
            </li>
            <li class="b-progress-list__item clearfix">
            <a href="#">
              <div class="b-progress-list__inner">
                <div class="b-progress-list__label"></div>
                  <div class="b-progress-list__icon text-primary stroke icon-Dollars"></div>
                  <span class="b-progress-list__name">Payments</span>
              </div>
            </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

<section class="b-taglines area-bg area-bg_dark parallax">
    <div class="area-bg__inner">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="Guide">
                <span class="stroke icon-Book"></span>
              <h2 class="b-taglines__title">User Guide</h2>
              <div class="b-taglines__text">Last update: July 2021</div><br>
                <a href="#" class="b-isotope__btn btn btn-primary">Download</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

    <!--  questions-->
<div class="container">
    <div class="typography-section col-md-8 col-xs-offset-2">
            <h2 class="typography-title">Frequently asked questions</h2>
            <div id="accordion-1" class="panel-group accordion accordion-1">
              <div class="panel panel-default">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-1" class="btn-collapse"><i class="icon"></i></a>
                  <h3 class="panel-title">How do I subscribe to Add-ons?</h3>
                </div>
                <div id="collapse-1" class="panel-collapse collapse in">
                  <div class="panel-body">
                    <p>Please login to the Back Office, click on the ‘Billing & subscriptions’ button in the Settings menu. Choose the add-on you like, click on the corresponding Subscribe button and follow the instructions. For more details, please refer to the article.</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-2" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">How long is the Trial and how it works?</h3>
                </div>
                <div id="collapse-2" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>All add-on services have a 14-day free trial period. Starting a trial period does not require a card. Once you’ve started the trial period, the Billing & Subscriptions page will display the trial expiration date. If you want to continue using the service after finishing the trial, activate the subscription by clicking on the ‘Activate’ button.</p>

                    <p>At any time, you can cancel the trial with the ‘Unsubscribe’ button. For more details, please refer to the article.. The free trial will last for 14 days and cannot be canceled even if the user unsubscribes from an add-on. If the user resubscribes again after 14 days of the free trial period, the user will not be eligible for free trial anymore.</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-3" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">How do I activate the subscription?</h3>
                </div>
                <div id="collapse-3" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>Upon starting a trial, you can either activate the subscription immediately or after the end of the trial period. During the subscription activation, you will be asked to provide your payment card details.</p>

                    <p>Please note that the price shown for the Employee Management is per employee, and for Advanced Inventory is per store. If you have added a number of employees or stores only for testing during the free trial, and you do not wish to pay for all of them, please adjust the numbers of employees or stores according to your needs before activating the subscription.</p>

                    <p>If you activate the subscription before the end of the free trial, you will still be able to use the service without paying till the end of the trial period. Your card will be charged and an invoice will be issued at the end of the trial.</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-4" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">What form of payment is accepted?</h3>
                </div>
                <div id="collapse-4" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>We accept credit and debit cards. Currently, we only accept Visa, MasterCard, Maestro, Discover, and JCB cards. Users from the USA, Canada, and Japan can use American Express (Amex) card type as well.</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-5" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">I do not have a card, how can I pay?</h3>
                </div>
                <div id="collapse-5" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>We can accept wire bank transfers, however only for annual subscriptions. Please note that all bank fees must be paid by the sender. If you would like to pay by a bank transfer, please contact the support team and we will send you the proforma invoice.</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-6" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">Why is my card declined?</h3>
                </div>
                <div id="collapse-6" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>The decline indicates that the card-issuing bank could not process the transaction. Please contact your bank. The most common reasons for a card to be declined could be that the transaction exceeded the credit limit, declined based on the location, the bank's fraud rules blocked the transaction and so on. Please check with your bank for more details.</p>

                    <p>When contacting the bank, we would recommend you to reach the second-tier customer service of your bank. The first-tier customer service may not have the access and system visibility required to locate any factors behind the decline. We recommend you to prepare some detailed information related to the transaction upon calling the bank (e.g. the amount of the transaction, the website the transaction was made at and the time of the transaction).</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-7" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">Will I lose my sales data while my account is blocked?</h3>
                </div>
                <div id="collapse-7" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>If for some reason, the payment fails and the back office gets blocked, your sales data or the Advanced Inventory documents will not be lost. Furthermore, the tracking of stock will continue to operate. Once you’ve paid up and your account is unblocked, all the sales and the stock levels in the back office will show the most updated information.</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-8" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">How can I cancel my subscription?</h3>
                </div>
                <div id="collapse-8" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>For your convenience, your subscription will be automatically renewed at the end of the billing period. You can easily cancel your subscription at any time.<br>
Follow these easy steps to cancel a subscription:<br>
1. Login to the Back office with the account owner email and password.<br>
2. Go to Settings and then to Billing & Subscriptions section.<br>
3. Click the Unsubscribe button to cancel an active subscription.<br><br>

Any remaining balance will be converted to a credit, which can be used to pay for future subscriptions.</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-9" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">If I stop using Get Store POS or any paid services, would my card get charged?</h3>
                </div>
                <div id="collapse-9" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>While you are subscribed to any subscription, your card will continue to be charged until you unsubscribe, even if you do not use any add-on or Get Store POS App.</p>

                    <p>If you decide not to use the Loyverse POS, please unsubscribe from the subscriptions in your Back Office. We do not refund the charged amount if the subscription is active.</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-10" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">Can I stop recurring payments or delete my card details?</h3>
                </div>
                <div id="collapse-10" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>To stop recurring payments or delete your card details, please email to getstore@gmail.com from your business account email, stating your request.</p>
                  </div>
                </div>
              </div>

            </div>
            <!-- end .accordion-->
        <p>f you have any further questions on any issues regarding Subscriptions and Billing, please contact the support team</p>
    </div>
</div>
    <!-- end questions-->
  <section class="section-contact">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <div class="ui-decor-1"><img src="assets/media/general/ui-decor-1.png" alt="decor" class="center-block"></div>
          <div class="text-center">
            <h2 class="b-contact__title ui-subtitle-block">Contact us if you need our services. We will be happy to make your services memorable!</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_green section-radius">
            <div class="b-contact__name">Website</div>
            <div class="b-contact__info">{{'envUrl' | envName }}</div>
            <div class="b-contact__icon icon-globe"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_blue section-radius">
            <div class="b-contact__name">Phone</div>
            <div class="b-contact__info"> +60 3-7803 4040</div>
            <div class="b-contact__icon icon-call-in"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_grey section-radius">
            <div class="b-contact__name">Email</div>
            <div class="b-contact__info">getstore@gmail.com</div>
            <div class="b-contact__icon icon-envelope-open"></div>
          </div>
          <!-- end b-contact-->
        </div>
      </div>
<div class="section-form-contact pb-2 pt-2">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h2 class="ui-title-block"><i class="ui-decor-2 bg-primary"></i> message form</h2>
          <div id="success"></div>
          <form id="contactForm" action="#" method="post" class="b-form-contacts ui-form">
            <div class="row">
              <div class="col-md-6">
                <input id="user-name" type="text" name="user-name" placeholder="Your Name" required="required" class="form-control"/>
                <input id="user-phone" type="tel" name="user-phone" placeholder="Your Tel" class="form-control"/>
                 <input id="user-name" type="text" name="company" placeholder="Company" required="required" class="form-control"/>
              </div>
              <div class="col-md-6">
                <input id="user-email" type="email" name="user-email" placeholder="Your Email" class="form-control"/>
                <input id="user-name" type="text" name="store-name" placeholder="Store Name" required="required" class="form-control"/>
                <input id="user-subject" type="text" name="user-subject" placeholder="Country" class="form-control last-block_mrg-btn_0"/>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <textarea id="user-message" rows="5" placeholder="What is your primary business system need?" required="required" class="form-control"></textarea>
                <button class="btn btn-primary btn-block">send  comment</button>
              </div>
            </div>
          </form>
          <!-- end .b-form-contact-->
        </div>
        <div class="col-md-6">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6700.199961740027!2d101.59078482942296!3d3.1045554658308263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4c227d48ff09%3A0x78137e41d04f03e!2sKelana%20Square!5e0!3m2!1sen!2sin!4v1622114189291!5m2!1sen!2sin" width="100%" height="550" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
    </div>
  </div>
    </div>
  </section>


  <div class="b-contact-social-net bg-grey text-center">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <ul class="social-net list-inline">
            <li class="social-net__item"><a href="twitter.com" class="social-net__link text-primary_h"><i class="icon fa fa-twitter"></i></a></li>
            <li class="social-net__item"><a href="facebook.com" class="social-net__link text-primary_h"><i class="icon fa fa-facebook"></i></a></li>
            <li class="social-net__item"><a href="plus.google.com" class="social-net__link text-primary_h"><i class="icon fa fa-google-plus"></i></a></li>
            <li class="social-net__item"><a href="instagram.com" class="social-net__link text-primary_h"><i class="icon fa fa-instagram"></i></a></li>
            <li class="social-net__item"><a href="skype.com" class="social-net__link text-primary_h"><i class="icon fa fa-skype"></i></a></li>
            <li class="social-net__item"><a href="behance.com" class="social-net__link text-primary_h"><i class="icon fa fa-behance"></i></a></li>
            <li class="social-net__item"><a href="vimeo.com" class="social-net__link text-primary_h"><i class="icon fa fa-vimeo"></i></a></li>
          </ul>
          <!-- end social-list-->
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>

</div>
<!-- end layout-theme-->
