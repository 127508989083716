import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache, ApolloLink, createHttpLink, concat } from '@apollo/client/core';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { NgxFlagPickerModule } from 'ngx-flag-picker';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AuthenticationService } from './front/auth-register-v2/authentication.service';
import { AuthRegisterV2Component } from './front/auth-register-v2/auth-register-v2.component';
import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EnvPipe } from './shared/pipe/env.pipe';
export const useFactory = () => {


	const httpLink = createHttpLink({ uri: `https://api.${environment.envUrl}/graphql` });

	const authLink = setContext((_, { headers }) => {

		// get the authentication token from local storage if it exists
		let token = JSON.parse(localStorage.getItem('currentUser')) ? JSON.parse(localStorage.getItem('currentUser')).token : null;
		let auth = AppInjector.get(AuthenticationService);

		if (token) {
			try {
				let expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
				if ((Math.floor((new Date).getTime() / 1000)) >= expiry) {
					console.log("expired");
					token = null;
				}
			} catch {
				auth.logout("JWT Invalid. Redirecting to home.", "Clearing Session");
			}
		}

		// return the headers to the context so httpLink can read them
		return token ? {
			headers: {
				...headers,
				'x-authorization-superadmin': `Bearer ${token}`,
			}
		} :
			{
				headers: {
					...headers,
				}
			}

	});


	const logoutLink = onError(({ networkError }) => {
		if ((networkError as any).statusCode == 401) {
			let auth = AppInjector.get(AuthenticationService);
			auth.logout("Logging out. Redirecting to home.", "Session Expired");
		}
	});

	const link = logoutLink.concat(concat(authLink, httpLink));
	const cache = new InMemoryCache();

	return {
		link, cache
	};

};
@NgModule({
  declarations: [
    AppComponent,
    AuthRegisterV2Component,

  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
		ToastrModule.forRoot(),
    HttpClientModule,
    NgxFlagPickerModule

  ],
  providers: [{
    provide: APOLLO_OPTIONS,
    useFactory: useFactory,
    deps: [HttpLink],
  },EnvPipe],

  bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private injector: Injector) {
		AppInjector = this.injector;
	}
}

export let AppInjector: Injector;
