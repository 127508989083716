<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">



    <app-navbar></app-navbar>

  <div id="main-slider" data-slider-width="100%" data-slider-height="500px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider slider-pro">
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide"><img src="assets/media/components/b-main-slider/product-bg-1.jpg" alt="slider" class="sp-image"/>
        <div class="container">
          <div class="row">
            <div class="col-sm-10 col-sm-offset-1">
              <div data-width="100%" data-show-transition="right" data-hide-transition="left" data-show-duration="1200" data-show-delay="2000" data-hide-delay="400" data-vertical="270px" data-horizontal="450" class="main-slider__item-1 sp-layer"><img src="assets/media/general/office.png" alt="Item"/></div>
              <h2 data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="800" data-show-delay="400" data-hide-delay="400" class="main-slider__title sp-layer">POS Software</h2>
              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="1200" data-show-delay="600" data-hide-delay="400" class="sp-layer">
                <div class="main-slider__decor bg-primary"></div>
              </div>
            <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="1200" data-hide-delay="400" class="main-slider__info sp-layer">GET Store POS is self-sufficient at providing you with all the essential features that modernise and simplify operations as well as increase sales and delight guests.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end .main-slider-->
<!-- START THE NAVBAR -->
<section class="section-default retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <span class="stroke icon-ShoppingCart"></span>
    <h1>Fast checkout process</h1>
    <ul>
        <li>Add products to the ticket with just a click by using a barcode scanner or built-in device camera.</li>
        <li>Easily find your items at the customizable sale screen.</li>
        <li>Use item variants for goods with multiple versions, like different sizes or colors.</li>
        <li>Apply discounts and taxes, and make notes to the items or to the receipts.</li>
    </ul>

</div>
<div class="col-md-6">
    <img src="assets/media/general/sales.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row">
<div class="col-md-6">
    <img src="assets/media/general/payment-type.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <span class="stroke icon-Web"></span>
    <h1>Flexible configuration for any business</h1>
    <ul>
        <li>Turn your iPad or Android tablet into a modern cash register and start selling in minutes.</li>
        <li>Set up as many cash registers in your store as you need – all the data will be synced between them.</li>
        <li>Manage your chain of stores from one place in real time.</li>
        <li>See all the important information about your sales and products at any time and from any place.</li>
    </ul>
</div>
</div>
</div>
</section>

<section class=" retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <span class="stroke icon-Chart"></span>
    <h1>Track your sales</h1>
    <ul>
        <li>Track the sales growth or decline compared with the previous day, week, or month, and promptly react to changes.</li>
        <li>Sales by item analytics allow the best-selling items to be determined and therefore, the right purchasing decisions can be made.</li>
        <li>Use different payment types, such as cash, card, cheque, etc. to analyze your sales.</li>
    </ul>
</div>
<div class="col-md-6">
    <img src="assets/media/general/backoffice-devices.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row">
<div class="col-md-6">
    <img src="assets/media/general/cash-management.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <span class="stroke icon-Dollars"></span>
    <h1>Control cash payins and payouts</h1>
    <ul>
        <li>Check all the cash flow into and out of the cash drawer.</li>
        <li>Control the real amount of cash in the cash drawer and compare with the expected amount to avoid theft and mistakes.</li>
        <li>See all the history of any payins/payouts with the employee identifier.</li>
    </ul>
</div>

</div>
</div>
</section>

<section class=" retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <span class="stroke icon-Users"></span>
    <h1>Separate cashier access</h1>
    <ul>
        <li>Each cash register user groups can be assigned different access rights.</li>
        <li>Restrict employees from accessing sensitive information and functions.</li>
        <li>Access the cash register app with a PIN code.</li>
        <li>You can set access rights to void saved items in open tickets.</li>
    </ul>
</div>
<div class="col-md-6">
    <img src="assets/media/general/accessrights.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row">
<div class="col-md-6">
    <img src="assets/media/general/timecard.png" alt="foto" class="img-responsive"/>
</div>
<div class="col-md-6">
    <span class="stroke icon-Users"></span>
    <h1>Manage your employees</h1>
    <ul>
        <li>Create shifts and control all the cash flow and sales inside of them with the shift reports.</li>
        <li>Check your employees’ hours worked with the clock-in/clock-out functionality.</li>
        <li>The information on the workload per hour allows for efficient management of your staff.</li>
        <li>Track each employee’s sales performance and make informed business decisions.</li>
    </ul>
</div>
</div>
</div>
</section>

<section class=" retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <span class="stroke icon-Tools"></span>
    <h1>Extend the ability of your POS device with other hardware</h1>
    <ul>
        <li>Connect receipt printers and customer display to give your customers full information about their purchase.</li>
        <li>Connect Kitchen displays and kitchen printers to send orders to the kitchen.</li>
        <li>Connect barcode scanners for quick and accurate sales.</li>
        <li>Connect card readers to accept credit cards.</li>
    </ul>
</div>
<div class="col-md-6">
    <img src="assets/media/general/settings.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<div class="container retail">
<div class="row">
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_green section-radius" style="background-position: -62.7px 20.4px;">
            <div class="b-contact__name">Website</div>
            <div class="b-contact__info">{{'envUrl' | envName }}</div>
            <div class="b-contact__icon icon-globe"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_blue section-radius" style="background-position: -296.7px 20.4px;">
            <div class="b-contact__name">Phone</div>
            <div class="b-contact__info"> +60 3-7803 4040</div>
            <div class="b-contact__icon icon-call-in"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_grey section-radius" style="background-position: -530.7px 20.4px;">
            <div class="b-contact__name">Email</div>
            <div class="b-contact__info">getstore@gmail.com</div>
            <div class="b-contact__icon icon-envelope-open"></div>
          </div>
          <!-- end b-contact-->
        </div>
      </div>
</div>

  <section class="b-taglines area-bg bg-primary_a parallax">
    <div class="area-bg__inner">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 col-sm-offset-3">
            <div class="b-taglines__inner">
              <h1 class="">Download Get Store POS</h1>
              <div class="b-taglines__text col-sm-offset-1">For iPhone, iPad, Android smartphone or tablet</div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/gplay-en.png" alt="foto" class="img-responsive"/></a></div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/appstore-en.png" alt="foto" class="img-responsive"/></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>

</div>
<!-- end layout-theme-->

<!-- //div fix-->
<script>
$(document).ready(function() {
$('.nav li a').click(function(e) {
    $('.nav li').removeClass('active');
    var $parent = $(this).parent();
    console.log($parent);
    if (!$parent.hasClass('active')) {
        $parent.addClass('active');
    }
    e.preventDefault();
});
})
</script>
