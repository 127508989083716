  <!-- Loader-->
  <div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
  <!-- Loader end-->

  <div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">



    <app-navbar></app-navbar>

    <div id="main-slider" data-slider-width="100%" data-slider-height="500px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider slider-pro">
      <div class="sp-slides">
        <!-- Slide 1-->
        <div class="sp-slide"><img src="assets/media/components/b-main-slider/product-bg-9.jpg" alt="slider" class="sp-image"/>
          <div class="container">
            <div class="row">
              <div class="col-sm-10 col-sm-offset-1">
                <div data-width="100%" data-show-transition="right" data-hide-transition="left" data-show-duration="1200" data-show-delay="2000" data-hide-delay="400" data-vertical="350px" data-horizontal="300" class="main-slider__item-1 sp-layer"><a href="#" ><img src="assets/media/general/gplay-en.png" alt="Item"/></a></div>

<div data-width="100%" data-show-transition="left" data-hide-transition="right" data-show-duration="1200" data-show-delay="2000" data-hide-delay="400" data-vertical="350px" data-horizontal="600" class="main-slider__item-1 sp-layer"><a href="#" ><img src="assets/media/general/appstore-en.png" alt="Item"/></a></div>

                <h2 data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="800" data-show-delay="400" data-hide-delay="400" class="main-slider__title sp-layer">Advanced Inventory Management System</h2>
                <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="1200" data-show-delay="600" data-hide-delay="400" class="sp-layer">
                  <div class="main-slider__decor bg-primary"></div>
                </div>
              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="1200" data-hide-delay="400" class="main-slider__info sp-layer"><strong>Gain a deeper insight into your inventory</strong><br>The Advanced Inventory Management includes a robust tools that streamline your back of house – create purchase orders, view inventory valuation report and manage stock, right from your Loyverse Back Office.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end .main-slider-->
  <!-- START THE NAVBAR -->
<section class="retail">
    <div class="container">
        <div class="row">
            <div class="col-sm-10 col-sm-offset-1 newp pb-2 text-center">
                <div class="ui-decor-1"><img src="assets/media/general/ui-decor-1.png" alt="decor" class="center-block"></div>
                <h1>Cloud Based Inventory Management</h1>
                <p>Designed for retailers that have one or several shops, cafe, or restaurant, Loyverse Advanced Inventory can easily keep track of the products, allowing you to have a full control over your stock. It also gives you an easy tool to track and manage your orders and receive and transfer goods between your locations.</p>
            </div>
        </div>
    </div>
</section>

<section class="bg-grey retail">
    <div class="container">
        <div class="row">
            <div class="col-sm-10 col-sm-offset-1 newp pb-2 text-center">
                <h1>Advanced Inventory Core Features</h1>
            </div>
            <div class="col-sm-6">
                <span class="stroke icon-Podium"></span>
                <h1>Purchase orders</h1>
                <h3>Plan purchases, export records to suppliers, track stock receipts and manage vendor relationships.</h3>
                <p>Purchase orders allows you to create and save orders for the supply of products, to send orders to suppliers, to receive products, and to save information of suppliers in the system.</p>
                <h3>Gain insights into the flow of your inventory by viewing adjustment log.</h3>
                <p>You can see the ‘Inventory history’ of all the items that underwent any changes. You can filter the history by period, stores, employees, or by reason of stock changes, including sales and refunds.</p>
            </div>
            <div class="col-sm-6">
                <img src="assets/media/general/01-create-po.png" alt="foto" class="img-responsive"/>
            </div>
        </div>
    </div>
</section>

<section class="retail">
    <div class="container">
        <div class="row">
            <div class="col-sm-10 col-sm-offset-1 newp pb-2 text-center">
                <div class="ui-decor-1">
                    <img src="assets/media/general/ui-decor-1.png" alt="decor" class="center-block">
                </div>
                <h1>Transfer orders</h1>
                <p>Easily create a transfer order document and shift stock between your stores.</p>
                <br>
                <img src="assets/media/general/02-transfer-order.png" alt="foto" class="img-responsive col-sm-offset-2"/><br>
                <p>You can add items from the list of your items to the transfer order. You will see the stock information of the chosen items at the source and destination stores. The received order will change the stock in the source and destination stores. You can email your transfer order to the destination store and/or download as a PDF or CSV file.</p>
            </div>
        </div>
    </div>
</section>

<section class="bg-grey retail">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <img src="assets/media/general/03-stock-adjustment.png" alt="foto" class="img-responsive col-sm-offset-1"/><br>
            </div>
            <div class="col-md-6">
                <span class="stroke icon-StorageBox"></span>
                <h1>Stock adjustments</h1>
                <h3>Increase and decrease stocks levels for received items, damages, loss, and inventory counts – which also works for multi-item stock adjustments.</h3>
                <p>You can modify the stock of items and indicate the reason for that adjustment. After making the adjustment, the stock of the adjusted items will be changed. You will see the stock adjustment details in the document. You can save it as a PDF or CSV file.
                </p>
            </div>
        </div>
    </div>
</section>

<section class="retail">
    <div class="container">
        <div class="row">
            <div class="col-sm-10 col-sm-offset-1 newp pb-2 text-center">
                <div class="ui-decor-1"><img src="assets/media/general/ui-decor-1.png" alt="decor" class="center-block"></div>
                <h1>Inventory count</h1>
                <p>Recount your products to keep the actual stock up-to-date</p>
                <br>
                <img src="assets/media/general/04-inventory-count.png" alt="foto" class="img-responsive col-sm-offset-1"/><br>
                <p>Inventory count allows you to reconcile the expected and actual inventory for all or selected items, see the amount of inventory loss or surplus inventory, and keep documents for each inventory.</p>
            </div>
        </div>
    </div>
</section>

<section class="bg-grey retail">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <span class="stroke icon-FileBox"></span>
                <h1>Production</h1>
                <h3>Make products out of ingredients and track the stock of both</h3>
                <p>The production feature can be useful for items that are made in advance, not at the time of ordering. For example, in a bakery. Produced items can be moved between stores by using inventory adjustments and inventory counts.</p>
            </div>
            <div class="col-md-6">
                <img src="assets/media/general/05-production.png" alt="foto" class="img-responsive"/><br>
            </div>
        </div>
    </div>
</section>

<section class="retail">
    <div class="container">
        <div class="row">
            <div class="col-sm-10 col-sm-offset-1 newp pb-2 text-center">
                <div class="ui-decor-1">
                    <img src="assets/media/general/ui-decor-1.png" alt="decor" class="center-block">
                </div>
                <h1>Inventory history</h1>
                <p>Gain insights into the flow of your inventory by viewing adjustment log.</p>
                <br>
                <img src="assets/media/general/06-inventory-history.png" alt="foto" class="img-responsive col-sm-offset-1"/><br>
                <p>You can see the ‘Inventory history’ of all the items that underwent any changes. You can filter the history by period, stores, employees, or by reason of stock changes, including sales and refunds.</p>
            </div>
        </div>
    </div>
</section>

<section class="bg-grey retail">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <span class="stroke icon-FileBox"></span>
                <h1>Printing labels</h1>
                <h3>Print labels to have a quick and accurate management of your products</h3>
                <p>The label can contain the item’s name, SKU, price, and barcode. Labels with a barcode allow you to use the scanner to add items to the ticket.</p><br>
                <img src="assets/media/general/barcodes.png" alt="foto" class="img-responsive"/><br>
            </div>
            <div class="col-md-6">
                <img src="assets/media/general/07-create-labels.png" alt="foto" class="img-responsive"/>
            </div>
        </div>
    </div>
</section>

<section class="retail">
    <div class="container">
        <div class="row">
            <div class="col-sm-10 col-sm-offset-1 newp pb-2 text-center">
                <div class="ui-decor-1">
                    <img src="assets/media/general/ui-decor-1.png" alt="decor" class="center-block">
                </div>
                <h1>Inventory valuation report</h1>
                <p>Get a detailed overview of your inventory with a list of all the items and variants you have in stock, their inventory and potential profit values.</p>
                <br>
                <img src="assets/media/general/08-valuation-report.png" alt="foto" class="img-responsive col-sm-offset-1"/><br>
                <p>Inventory Valuation Report gives you an understanding of the total cost of the inventory and potential profits from their sale. You can see the up-to-date ‘Inventory valuation’ report. You can filter the report by categories or stores (if you have multiple stores).</p>
            </div>
        </div>
    </div>
</section>

<div class="bg-grey container retail">
<div class="row">
          <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
            <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_green section-radius" style="background-position: -62.7px 20.4px;">
              <div class="b-contact__name">Website</div>
              <div class="b-contact__info">{{'envUrl' | envName }}</div>
              <div class="b-contact__icon icon-globe"></div>
            </div>
            <!-- end b-contact-->
          </div>
          <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
            <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_blue section-radius" style="background-position: -296.7px 20.4px;">
              <div class="b-contact__name">Phone</div>
              <div class="b-contact__info"> +60 3-7803 4040</div>
              <div class="b-contact__icon icon-call-in"></div>
            </div>
            <!-- end b-contact-->
          </div>
          <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
            <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_grey section-radius" style="background-position: -530.7px 20.4px;">
              <div class="b-contact__name">Email</div>
              <div class="b-contact__info">getstore@gmail.com</div>
              <div class="b-contact__icon icon-envelope-open"></div>
            </div>
            <!-- end b-contact-->
          </div>
        </div>
</div>

    <section class="b-taglines area-bg bg-primary_a parallax">
      <div class="area-bg__inner">
        <div class="container">
          <div class="row">
            <div class="col-sm-10 col-sm-offset-3">
              <div class="b-taglines__inner">
                <h1 class="">Download Get Store POS</h1>
                <div class="b-taglines__text col-sm-offset-1">For iPhone, iPad, Android smartphone or tablet</div>
                  <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/gplay-en.png" alt="foto" class="img-responsive"/></a></div>
                  <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/appstore-en.png" alt="foto" class="img-responsive"/></a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <app-footer></app-footer>

  </div>
  <!-- end layout-theme-->

<!-- //div fix-->
<script>
$(document).ready(function() {
  $('.nav li a').click(function(e) {
      $('.nav li').removeClass('active');
      var $parent = $(this).parent();
      console.log($parent);
      if (!$parent.hasClass('active')) {
          $parent.addClass('active');
      }
      e.preventDefault();
  });
})
</script>
