<!-- Loader-->
<div id="page-preloader">
  <span class="spinner border-t_second_b border-t_prim_a"></span>
</div>
<!-- Loader end-->

<div
  data-header="sticky"
  data-header-top="200"
  data-canvas="container"
  class="l-theme animated-css"
>
  <app-navbar></app-navbar>
  <div
    id="main-slider"
    data-slider-width="100%"
    data-slider-height="300px"
    data-slider-arrows="true"
    data-slider-buttons="false"
    class="main-slider main-slider_mod-a slider-pro"
  >
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide">
        <img
          src="assets/media/components/b-main-slider/bg-13.jpg"
          alt="slider"
          class="sp-image"
        />
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <div
                data-width="100%"
                data-show-transition="left"
                data-hide-transition="left"
                data-show-duration="2000"
                data-show-delay="2200"
                data-hide-delay="400"
                class="main-slider__info sp-layer"
              >
                <h1 class="white" style="color: white">
                  Get Store POS MERCHANT<br />
                  TERMS-CONDITIONS
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Slide 2-->
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="section-area">
          <div class="b-request-estimate">
            <div class="b-request-estimate__info">
              GET Store POS is that comes loaded with all the features that
              modernised and simplify operations, increase sales, and delight
              guests.
            </div>
            <div class="b-request-estimate__title bg-primary">
              <span class="ui-decor-2"></span>TERMS
            </div>
          </div>
          <!-- end .b-request-estimate-->
        </div>
      </div>
    </div>
  </div>
  <!-- START THE NAVBAR -->
  <!-- end b-title-page-->
  <div class="section-progress terms">
    <div class="container">
      <div class="row">
        <h1 class="text-center">GENERAL TERMS</h1>
        <h2>APPLICATION OF TERMS</h2>
        <ul>
          <li>
            These Terms (as defined herein) apply to the Merchant’s (as defined
            below) access and use of the Service (as defined below). By setting
            up an account with us, accessing and/or using the Service, you agree
            that you have read, understood and agreed to these Terms and these
            Terms shall constitute a legally binding agreement between you and
            Get Store POS (as defined below). If you do not agree to these
            Terms, you are not authorised to access and use the Service, and you
            must immediately stop doing so.
          </li>
          <li>
            The Merchant must be of 18 years or older or at least the age of
            majority in the jurisdiction where the Merchant resides or from
            which the Merchant uses the Service.
          </li>
        </ul>

        <h2>CHANGES</h2>
        <ul>
          <li>
            We may change these Terms at any time by notifying you of the
            changes by email or by posting a notice on the Website (as defined
            below). Unless stated otherwise, the changes take effect from the
            date set out in the notice. You are responsible for ensuring that
            you are familiar with the latest Terms. By continuing to access and
            use the Service from the date on which the Terms are changed, you
            are bound by the revised Terms, whether or not reviewed by you.
          </li>
          <li>These Terms were last updated on 1st June 2022.</li>
          <li>
            <strong>“Get Delivery Platform and Service”</strong> means the
            online delivery platform at getdelivery.com, including all
            subdomains and components thereof and its mobile applications (if
            any), as updated from time to time.
            <strong>Delivery Service”</strong> means the provision of ordering
            and delivery booking services through the Get Delivery Platform.
          </li>
          <li>
            <strong>“Confidential Information”</strong> means any information
            that is not available to the general public and that is obtained
            from the other party in the course of, or in connection with, the
            provision or use of the Service. Our Confidential Information
            includes Intellectual Property owned by us (or our licensors),
            including the Get store POS Software. Your Confidential Information
            includes the Data.
          </li>
          <li>
            <strong>“Data”</strong> means all photos, data, content, and
            information (including Personal Information) owned, held, used,
            provided or created by you or on your behalf that is stored in,
            used, or inputted into, the Service, including the Purchaser’s data.
          </li>
          <li>
            <strong>“Delivery Service Providers”</strong> means, in respect of
            the Get Delivery Service, independent third party delivery service
            providers appointed by Get Store POS
          </li>
          <li>
            <strong>“Fees”</strong> means, collectively, the Subscription Fees
            and the Service Fees.
          </li>
          <li>
            <strong>“Force Majeure”</strong> includes acts of God, strikes,
            lockout, quarantine, epidemics, pandemics, state of war, war,
            blockades, revolutions, riots, insurrections, lightning, fire,
            storm, floods, earthquakes, explosions, embargoes, restriction of
            movement, power outage, breakdown of internet service, government
            restraint or regulation and any other cause whether of the kind
            specifically referred to in this definition or otherwise which is
            not reasonably within the control of a party rendering the
            performance of these Terms or the Service illegal, impossible or
            impracticable, excluding:
            <ul>
              <li>
                an event to the extent that it could have been avoided by the
                relevant party taking reasonable steps or reasonable care; or
              </li>
              <li>an event arising from a lack of funds for any reason.</li>
            </ul>
          </li>
          <li>
            “include”, “including”, (or other similar term) do not imply any
            limitation and general words introduced by the word “other” (or any
            similar term) shall not be given a restrictive meaning by reason of
            the fact that they are preceded or followed by words indicating a
            particular class of acts, matters or things.
          </li>
          <li>
            <strong>“Intellectual Property Rights”</strong> includes copyright
            and all rights existing anywhere in the world conferred under
            statute, common law or equity relating to inventions (including
            patents), registered, registrable and unregistered trade and service
            marks, rights in logos, designs and design rights, circuit layouts,
            data and databases, confidential information, trade secret,
            know-how, all rights of whatsoever nature in computer software and
            data, all rights of privacy and intangible rights and privileges of
            a nature similar or allied to any of the foregoing, and all other
            rights resulting from intellectual activity, in every case in any
            part of the world and whether or not registered (and including all
            granted registrations and all applications for registration in
            respect of any of the same).<strong>
              “Intellectual Property”</strong
            >
            has a consistent meaning, and includes any enhancement, modification
            or derivative work of the Intellectual Property.
          </li>
          <li>
            <strong>Merchant Privacy Policy”</strong> means our merchant privacy
            policy available from time to time on the Website at
            getstorepos.com/privacy
          </li>
          <li>
            <strong>Personal Information</strong> “means information about an
            identifiable, living person. This includes but is not limited to
            name, nationality, telephone number, bank and credit card details,
            personal interests, email address, image, government-issued
            identification numbers, biometric data, race, date of birth, marital
            status, religion, health information, vehicle and insurance
            information.
          </li>
          <li>
            <strong>“Sales Tax”</strong> means goods and services tax, value
            added tax, sales tax or equivalent tax payable under any applicable
            law.
          </li>
          <li>
            <strong>“Service”</strong> means, collectively, the Get Store POS
            Service and the Get Delivery Service .
            <strong>Get Store POS Service”</strong> means the provision of point
            of sale (POS) and e-commerce services having the core
            functionalities described on the Website at getstorepos.com,
            getstoreecom.com, getstoredelivery.com, as updated from time to
            time, including Get Store POS and eCommerce.
          </li>
          <li>
            <strong>“Subscription Fees”</strong> means the applicable fees in
            relation to the access and use of the Get Store POS Service as set
            out on our pricing page on the Website at getstorepos.com/pricing.
          </li>
          <li>
            <strong>“Third Party Providers”</strong> means the independent third
            parties who provide the relevant solutions to you through the
            Service.
          </li>
        </ul>

        <h2>PROVISION OF THE SERVICE</h2>
        <ul>
          <li>
            We must use reasonable efforts to provide the Service:
            <ul>
              <li>
                in accordance with these Terms and the Applicable Governing
                Laws;
              </li>
              <li>exercising reasonable care, skill and diligence; and</li>
              <li>
                using suitably skilled, experienced and qualified personnel.
              </li>
            </ul>
          </li>
          <li>
            Our provision of the Service to you is non-exclusive. Nothing in
            these Terms prevents us from providing the Service to any other
            person. We use reasonable efforts to ensure the Service is available
            on a 24/7 basis. However, it is possible that on occasion the
            Service may be unavailable to permit maintenance or other
            development activity to take place, or in the event of Force
            Majeure. We must use reasonable efforts to publish on the Website
            advance details of any unavailability. In such event, we shall not
            be liable nor shall be deemed to be in breach for any delay or
            failure or for any consequence of delay or failure in the
            performance of these Terms.
          </li>
          <li>
            Through the use of web services and application program interfaces
            (APIs), the Service interoperates with a range of third party
            service features provided by Third Party Providers<strong>
              (“Third Party Services”). </strong
            >We do not make any warranty or representation on the availability
            of those features and it is your responsibility to read the terms
            and conditions and/or privacy policies applicable to such Third
            Party Services before using them. In some instances, Get Store POS
            may receive a revenue share from Third Party Providers that Get
            Store POS recommends to you or that you otherwise engage through
            your use of the Service. Without limiting the generality of the
            foregoing, if a Third Party Provider ceases to provide that feature
            or ceases to make that feature available on reasonable terms, we may
            cease to make available that feature to you without any liability.
            To avoid doubt, if we exercise our right to cease the availability
            of a third party feature, you are not entitled to any refund,
            discount or other compensation.
          </li>
        </ul>

        <h2>Ownership of rights</h2>
        <ul>
          <li>
            Any use of this website or its contents, including copying or
            storing it or them in whole or part, other than for your own
            personal, non-commercial use is prohibited without the explicit
            permission of Get Store POS. All information displayed, transmitted
            or carried on Get Store POS is protected by copyright and other
            intellectual property laws. All rights, including copyright, in this
            website are owned by or licensed to Get Store POS. This site is
            designed, updated and maintained independently by Get Store POS.<br />
            The content is owned by Get Store POS. You may not modify, publish,
            transmit, transfer, sell, reproduce, create derivative work from,
            distribute, repost, perform, display or in any way commercially
            exploit any of the content available in the website.
          </li>
        </ul>
        <h2>Privacy policy</h2>
        <ul>
          <li>
            The Service Provider will use the customer information as shared by
            the merchant to update and inform the customer through sms, whatsapp
            and/or email regarding the delivery status of the product and obtain
            feedback from the customer regarding the said service to improve
            customer experience with the service provider.<br />
            The said information will be used for the purpose of updating the
            customer with the delivery status of the product and improving our
            service for the customer.
          </li>
        </ul>

        <h2>Refund policy</h2>
        <p>We support the following payment options at Get Store POS.com</p>
        <ul>
          <li>Credit Card</li>
          <li>Debit Card</li>
          <li>UPI</li>
          <p>
            There are no hidden charges. You pay only the amount that you see in
            your order summary at the time of check out. If you see any charges
            on your credit/debit card for purchases made but you have never
            created an account or signed up, please check with your family
            members or business colleagues authorized to make purchases on your
            behalf. If you’re still unable to recognize the charge, please
            report the unauthorized purchase within 30 days of the transaction
            so that we can look into it. Please email us at hr@getstorepos.com
          </p>
          <li>
            In case of a payment failure, please retry ensuring: Information
            passed on to payment gateway is accurate i.e. account details,
            billing address, password (for net banking), etc. Kindly also check
            if your Internet connection has been disrupted in the process.
          </li>
          <li>
            If your account has been debited after a payment failure, it is
            normally rolled back to your account within 7 business days. You can
            email us on hr@getstorepos.com with your order number for any
            clarification
          </li>
        </ul>

        <h2>PROVISION OF THE GET DELIVERY SERVICE</h2>
        <ul>
          <li>
            Subject to these Terms, we provide the Delivery Service to the
            Merchants who wish to list their stores on the get delivery
            Platform, whereby we are entitled to accept order and delivery
            booking placed on the get delivery Platform by the Purchasers<strong>
              (“F&B and Retail”)</strong
            >
            on the Merchants’ behalf. We do not act for or on behalf of the
            Purchasers.
          </li>
          <li>
            By utilising the Underlying Systems, we receive Orders from the
            Purchasers through the get delivery Platform, accept payments from
            the Purchasers, and transmit such<strong> F&B and Retail </strong
            >Orders via a printer/tablet or electronic device application
            installed by us (or in exceptional circumstances, by phone) to the
            Merchants.
          </li>
          <li>
            Get Store POS may carry out changes to the Get Delivery Service, or
            suspend the Delivery Service, without notice to you. Get Store POS
            is not responsible or liable to anyone for discontinuation or
            suspension of access to, or disablement of, the Get Delivery
            Service.
          </li>
        </ul>

        <h2>SERVICE FEES AND PURCHASE AMOUNTS</h2>
        <ul>
          <li>
            In consideration of the provision of the Get Delivery Service to
            you, you must pay us the Service Fees, The Service Fees are non-
            refundable by us in any event.
          </li>
          <li>
            You hereby grant us the authority to receive the Purchase Amounts
            paid by the Purchasers in your name and for your account.
          </li>
          <li>
            You hereby agree and accept that the Service Fees and other
            transaction fees(collectively,<strong> “Amounts Payable”</strong>)
            as applicable to you will be calculated and paid by you and that no
            other fees or charges shall apply between the parties or towards the
            Purchasers. You hereby agree and accept that we are entitled to set
            off the Amounts Payable against the Purchase Amounts collected by us
            on your behalf through the Get Delivery Platform. Any Amounts
            Payable are non-refundable by us in any event.
          </li>
          <li>
            The Service Fees for using the Get Delivery Service and the rate of
            the transaction fees are subject to change upon 30 days’ notice from
            Get Store POS. Such notice may be provided at any time by posting
            the changes to the website or via email.
          </li>
        </ul>

        <h2>DELIVERY FAILURE</h2>
        <ul>
          <li>
            In the event any Delivery Service Provider or the delivery service
            provider appointed by the Merchant (as the case may be) fails to
            deliver the Food Order in a proper state, the Purchaser shall be
            refunded for the spoiled Food Order. The amount of the refund to the
            Purchaser shall be agreed between us and the Purchaser, and such
            refunded amount shall be deducted from the Merchant’s Purchase
            Amounts. Any claim by the Merchant for such deducted amount shall
            not lie against us.
          </li>
          <li>
            In the event any Delivery Service Provider or the delivery service
            provider appointed by the Merchant (as the case may be) fails to
            deliver the Food Order to the Purchaser for any reason whatsoever,
            the Merchant shall decide whether to deliver the Food Order to the
            Purchaser itself or to cancel the order. If the Food Order is
            cancelled by the Merchant, we are entitled to refund to the
            Purchaser the payment made in respect of the cancelled Food Order,
            and such refunded amount shall be deducted from the Merchant’s
            Purchase Amounts. Any claim by the Merchant for such deducted amount
            shall not lie against us.
          </li>
        </ul>

        <h2>DELIVERY SERVICE PROVIDER AS THIRD PARTY CONTRACTOR</h2>
        <ul>
          <li>
            The Delivery Service Provider is not an employee or agent of Get
            Store POS in any event.
          </li>
          <li>
            Get Store POS does not provide any transportation services and shall
            not be held responsible whatsoever for the Delivery Service
            Provider’s action, negligence, failure, lateness, or rejection to
            provide transportation services, specifically food delivery service
            from the Merchant’s store to the Purchaser.
          </li>
          <li>
            Get Store POS shall not guarantee nor be held liable for the safety,
            reliability, compatibility, or capability of the Delivery Service
            Provider during the deliverance of his/her obligation in delivering
            the food and/or beverage items from the Merchant’s store to the
            Purchaser. Therefore, the Merchant shall hereby expressly agree to
            hold Get Store POS and the Get Store POS Indemnified Persons
            harmless and shall discharge Get Store POS from any and all
            responsibility, claim, cause, or damage which occurs from such
            delivery services. This Clause shall survive the termination of your
            engagement of the Get Delivery Service.
          </li>
        </ul>

        <h2>Prohibited or Restricted Items</h2>
        <ul>
          <li>
            Live animals (including but not limited to mammals, reptiles, birds
            and fish), Insects, larvae, pupae etc
          </li>
          <li>
            Clinical and or Biological samples (including but not limited to
            bodily fluids and tissue samples)
          </li>
          <li>
            Perishable items that require a temperature controlled environment
            (including but not limited to foodstuffs and flowers)
          </li>
          <li>
            Tobacco, loose leaf tobacco products and pre-rolled cigarettes.
          </li>
          <li>Pornography (whether in printed, film or digital format)</li>
          <li>
            Jewellery, Costume Jewellery, Watches and objects constructed of
            precious metal and/or stones with a value in excess of £5,000 per
            waybill
          </li>
        </ul>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<!-- end layout-theme-->

<!-- //div fix-->
<script>
  $(document).ready(function () {
    $(".nav li a").click(function (e) {
      $(".nav li").removeClass("active");
      var $parent = $(this).parent();
      console.log($parent);
      if (!$parent.hasClass("active")) {
        $parent.addClass("active");
      }
      e.preventDefault();
    });
  });
</script>
