<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">
  
    

    <app-navbar></app-navbar>  
  
  <div id="main-slider" data-slider-width="100%" data-slider-height="300px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider main-slider_mod-a slider-pro">
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide"><img src="assets/media/components/b-main-slider/bg-4.jpg" alt="slider" class="sp-image"/>
        <div class="container">
          <div class="row">
            <div class="col-md-8">
                
              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="2200" data-hide-delay="400" class="main-slider__info sp-layer"><img src="assets/media/general/Pricing.png" alt="slider"/></div>
              <h2 data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="800" data-show-delay="400" data-hide-delay="400" class="main-slider__title sp-layer">Get Store POS Pricing</h2>
              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="1200" data-show-delay="600" data-hide-delay="400" class="sp-layer">
                <div class="main-slider__decor bg-primary"></div>
              </div>
              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="1200" data-show-delay="2000" data-hide-delay="400" class="sp-layer"><a href="contact.html" class="main-slider__btn btn btn-default">Contact us</a></div>
            </div>
          </div>
        </div>
      </div>
      <!-- Slide 2-->
      
    </div>
  </div>
  <!-- end .main-slider-->
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="section-area">
          <div class="b-request-estimate">
            <div class="b-request-estimate__info">GET Store POS is  that comes loaded with all the features that modernised and simplify operations, increase sales, and delight guests.</div>
            <div class="b-request-estimate__title bg-primary"><span class="ui-decor-2"></span>Pricing</div>
          </div>
          <!-- end .b-request-estimate-->
        </div>
      </div>
    </div>
  </div>
<!-- START THE NAVBAR -->
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-8 product col-xs-offset-2">
    <h1>Products</h1>
    <div class="product-ul">
        <ul>
        <li><img src="assets/media/general/product-1.png" alt="foto" class="img-responsive"/></li>
        <li><h3>Get Store POS — Point of Sale</h3>
        <p>Manage multiple stores, sales analytics, inventory management, loyalty program</p>
        </li>
        <li class="pull-right">Free</li>
        </ul>
    </div>
    <div class="product-ul">
        <ul>
        <li><img src="assets/media/general/product-2.png" alt="foto" class="img-responsive"/></li>
        <li><h3>Get Store POS — Dashboard</h3>
        <p>Instant access to your store's sales analytics and inventory</p>
        </li>
        <li class="pull-right">Free</li>
        </ul>
    </div>
    <div class="product-ul">
        <ul>
        <li><img src="assets/media/general/product-3.png" alt="foto" class="img-responsive"/></li>
        <li><h3>Get Store POS — Kitchen Display</h3>
        <p>Let your cooking staff know what to prepare from an order</p>
        </li>
        <li class="pull-right">Free</li>
        </ul>
    </div>
    <div class="product-ul">
        <ul>
        <li><img src="assets/media/general/product-4.png" alt="foto" class="img-responsive"/></li>
        <li><h3>Get Store POS — Customer Display</h3>
        <p>Display price information to your customers</p>
        </li>
        <li class="pull-right">Free</li>
        </ul>
    </div>
<div class="clearfix"></div>
    <!-- end product-->
    <!--  add ons-->
<div class="add-ons">
            <div class="pane-content-inner">
            <div id="guides-tabs">
            <!-- Nav tabs -->
        <ul class="nav nav-tabs tabs-group top pull-right">
        <li class="study-inn" tabindex="0" class="tab tab-1 ">
            <a data-toggle="tab" data-target="#1" class="box box-1 jquery-once-6-processed"><span tabindex="0" class="text">Pay monthly</span></a>
        </li>
        <li class="study-inn" tabindex="1" class="tab tab-2">
            <a data-toggle="tab" data-target="#2" class="box box-2 jquery-once-6-processed"><span tabindex="1" class="text">Pay annually</span></a>
        </li>
    </ul>

        <!-- Tab panes -->
        <div class="tab-content">
            <h1>Add ons</h1>
          <div id="1" class="tab-pane active" >
            <div class="add-onsul">
                <ul>
              <li><img src="assets/tab/icon-employees.png" alt="printers" class="sp-image"/></li>
              <li><h3>Employee management</h3>
                  <p>Manage access rights, track timecards and sales by employee</p>
              </li>
                <li class="pull-right">
                    <h3>$5 USD/month</h3>
                      <p>per employee<br>14 day free trial</p>
                  </li> 
                </ul>
                <div class="clearfix"></div>
              </div>
              
              <div class="add-onsul">
                <ul>
              <li><img src="assets/tab/icon-inventory.png" alt="printers" class="sp-image"/></li>
              <li><h3>Advanced inventory</h3>
                  <p>Create purchase orders, view inventory valuation report and manage stock</p>
                  <p>* If you have more than 3 stores, contact us for special offer</p>
              </li>
                <li class="pull-right">
                    <h3>$25 USD/month</h3>
                      <p>per employee<br>14 day free trial</p>
                  </li> 
                </ul>
                <div class="clearfix"></div>
              </div>
              <div class="add-onsul">
                <ul>
              <li><img src="assets/tab/icon-integrations.png" alt="printers" class="sp-image"/></li>
              <li><h3>Integrations</h3>
                  <p>Connect accounting, ecommerce, marketing and other apps</p>
                  <p>* Third-party app developers may charge additional fees</p>
              </li>
                <li class="pull-right">
                    <h3>$9 USD/month</h3>
                      <p>per employee<br>14 day free trial</p>
                  </li> 
                </ul>
                <div class="clearfix"></div>
              </div>
              
            </div>
            
            
          <div id="2" class="tab-pane">
            <div class="add-onsul">
                <ul>
              <li><img src="assets/tab/icon-employees.png" alt="printers" class="sp-image"/></li>
              <li><h3>Employee management</h3>
                  <p>Manage access rights, track timecards and sales by employee</p>
              </li>
                <li class="pull-right">
                    <h3>$50 USD/month</h3>
                      <p>per employee<br>14 day free trial</p>
                  </li> 
                </ul>
                <div class="clearfix"></div>
              </div>
              <div class="add-onsul">
                <ul>
              <li><img src="assets/tab/icon-inventory.png" alt="printers" class="sp-image"/></li>
              <li><h3>Advanced inventory</h3>
                  <p>Create purchase orders, view inventory valuation report and manage stock</p>
                  <p>* If you have more than 3 stores, contact us for special offer</p>
              </li>
                <li class="pull-right">
                    <h3>$250 USD/month</h3>
                      <p>per employee<br>14 day free trial</p>
                  </li> 
                </ul>
                <div class="clearfix"></div>
              </div>
              <div class="add-onsul">
                <ul>
              <li><img src="assets/tab/icon-integrations.png" alt="printers" class="sp-image"/></li>
              <li><h3>Integrations</h3>
                  <p>Connect accounting, ecommerce, marketing and other apps</p>
                  <p>* Third-party app developers may charge additional fees</p>
              </li>
                <li class="pull-right">
                    <h3>$90 USD/month</h3>
                      <p>per employee<br>14 day free trial</p>
                  </li> 
                </ul>
                <div class="clearfix"></div>
              </div>
              
            </div>

        </div>
    </div>
    </div>
    <p>All add-ons include 24/7 priority support via online chat<br>
    All prices are exclusive of taxes</p>
    </div>
    <!-- end add ons-->
    
    <!--  questions-->
    <div class="typography-section">
            <h2 class="typography-title">Frequently asked questions</h2>
            <div id="accordion-1" class="panel-group accordion accordion-1">
              <div class="panel panel-default">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-1" class="btn-collapse"><i class="icon"></i></a>
                  <h3 class="panel-title">How do I subscribe to Add-ons?</h3>
                </div>
                <div id="collapse-1" class="panel-collapse collapse in">
                  <div class="panel-body">
                    <p>Please login to the Back Office, click on the ‘Billing & subscriptions’ button in the Settings menu. Choose the add-on you like, click on the corresponding Subscribe button and follow the instructions. For more details, please refer to the article.</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-2" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">How long is the Trial and how it works?</h3>
                </div>
                <div id="collapse-2" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>All add-on services have a 14-day free trial period. Starting a trial period does not require a card. Once you’ve started the trial period, the Billing & Subscriptions page will display the trial expiration date. If you want to continue using the service after finishing the trial, activate the subscription by clicking on the ‘Activate’ button.</p>
                      
                    <p>At any time, you can cancel the trial with the ‘Unsubscribe’ button. For more details, please refer to the article.. The free trial will last for 14 days and cannot be canceled even if the user unsubscribes from an add-on. If the user resubscribes again after 14 days of the free trial period, the user will not be eligible for free trial anymore.</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-3" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">How do I activate the subscription?</h3>
                </div>
                <div id="collapse-3" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>Upon starting a trial, you can either activate the subscription immediately or after the end of the trial period. During the subscription activation, you will be asked to provide your payment card details.</p>
                      
                    <p>Please note that the price shown for the Employee Management is per employee, and for Advanced Inventory is per store. If you have added a number of employees or stores only for testing during the free trial, and you do not wish to pay for all of them, please adjust the numbers of employees or stores according to your needs before activating the subscription.</p>
                      
                    <p>If you activate the subscription before the end of the free trial, you will still be able to use the service without paying till the end of the trial period. Your card will be charged and an invoice will be issued at the end of the trial.</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-4" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">What form of payment is accepted?</h3>
                </div>
                <div id="collapse-4" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>We accept credit and debit cards. Currently, we only accept Visa, MasterCard, Maestro, Discover, and JCB cards. Users from the USA, Canada, and Japan can use American Express (Amex) card type as well.</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-5" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">I do not have a card, how can I pay?</h3>
                </div>
                <div id="collapse-5" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>We can accept wire bank transfers, however only for annual subscriptions. Please note that all bank fees must be paid by the sender. If you would like to pay by a bank transfer, please contact the support team and we will send you the proforma invoice.</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-6" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">Why is my card declined?</h3>
                </div>
                <div id="collapse-6" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>The decline indicates that the card-issuing bank could not process the transaction. Please contact your bank. The most common reasons for a card to be declined could be that the transaction exceeded the credit limit, declined based on the location, the bank's fraud rules blocked the transaction and so on. Please check with your bank for more details.</p>
                      
                    <p>When contacting the bank, we would recommend you to reach the second-tier customer service of your bank. The first-tier customer service may not have the access and system visibility required to locate any factors behind the decline. We recommend you to prepare some detailed information related to the transaction upon calling the bank (e.g. the amount of the transaction, the website the transaction was made at and the time of the transaction).</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-7" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">Will I lose my sales data while my account is blocked?</h3>
                </div>
                <div id="collapse-7" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>If for some reason, the payment fails and the back office gets blocked, your sales data or the Advanced Inventory documents will not be lost. Furthermore, the tracking of stock will continue to operate. Once you’ve paid up and your account is unblocked, all the sales and the stock levels in the back office will show the most updated information.</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-8" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">How can I cancel my subscription?</h3>
                </div>
                <div id="collapse-8" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>For your convenience, your subscription will be automatically renewed at the end of the billing period. You can easily cancel your subscription at any time.<br>
Follow these easy steps to cancel a subscription:<br>
1. Login to the Back office with the account owner email and password.<br>
2. Go to Settings and then to Billing & Subscriptions section.<br>
3. Click the Unsubscribe button to cancel an active subscription.<br><br>

Any remaining balance will be converted to a credit, which can be used to pay for future subscriptions.</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-9" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">If I stop using Get Store POS or any paid services, would my card get charged?</h3>
                </div>
                <div id="collapse-9" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>While you are subscribed to any subscription, your card will continue to be charged until you unsubscribe, even if you do not use any add-on or Get Store POS App.</p>
                      
                    <p>If you decide not to use the Loyverse POS, please unsubscribe from the subscriptions in your Back Office. We do not refund the charged amount if the subscription is active.</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading"><a data-toggle="collapse" data-parent="#accordion-1" href="#collapse-10" class="btn-collapse collapsed"><i class="icon"></i></a>
                  <h3 class="panel-title">Can I stop recurring payments or delete my card details?</h3>
                </div>
                <div id="collapse-10" class="panel-collapse collapse">
                  <div class="panel-body">
                    <p>To stop recurring payments or delete your card details, please email to getstore@gmail.com from your business account email, stating your request.</p>
                  </div>
                </div>
              </div>
                
            </div>
            <!-- end .accordion-->
        <p>f you have any further questions on any issues regarding Subscriptions and Billing, please contact the support team</p>
    </div>
    <!-- end questions-->
</div>
    </div>
</div>

  <section class="b-taglines area-bg bg-primary_a parallax">
    <div class="area-bg__inner">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 col-sm-offset-3">
            <div class="b-taglines__inner">
              <h1 class="">Download Get Store POS</h1>
              <div class="b-taglines__text col-sm-offset-1">For iPhone, iPad, Android smartphone or tablet</div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/gplay-en.png" alt="foto" class="img-responsive"/></a></div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/appstore-en.png" alt="foto" class="img-responsive"/></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>
  
</div>
<!-- end layout-theme-->

<!-- //div fix-->
<script>
$(document).ready(function() {
$('.nav li a').click(function(e) {
    $('.nav li').removeClass('active');
    var $parent = $(this).parent();
    console.log($parent);
    if (!$parent.hasClass('active')) {
        $parent.addClass('active');
    }
    e.preventDefault();
});
})
</script>