<!-- Loader-->
<div id="page-preloader"><span class="spinner border-t_second_b border-t_prim_a"></span></div>
<!-- Loader end-->

<div data-header="sticky" data-header-top="200" data-canvas="container" class="l-theme animated-css">



  <app-navbar></app-navbar>

  <div id="main-slider" data-slider-width="100%" data-slider-height="500px" data-slider-arrows="true" data-slider-buttons="false" class="main-slider slider-pro">
    <div class="sp-slides">
      <!-- Slide 1-->
      <div class="sp-slide"><img src="assets/media/components/b-main-slider/product-bg-5.jpg" alt="slider" class="sp-image"/>
        <div class="container">
          <div class="row">
            <div class="col-sm-10 col-sm-offset-1">
              <div data-width="100%" data-show-transition="right" data-hide-transition="left" data-show-duration="1200" data-show-delay="2000" data-hide-delay="400" data-vertical="350px" data-horizontal="300" class="main-slider__item-1 sp-layer"><a href="#" ><img src="assets/media/general/gplay-en.png" alt="Item"/></a></div>

<div data-width="100%" data-show-transition="left" data-hide-transition="right" data-show-duration="1200" data-show-delay="2000" data-hide-delay="400" data-vertical="350px" data-horizontal="600" class="main-slider__item-1 sp-layer"><a href="#" ><img src="assets/media/general/appstore-en.png" alt="Item"/></a></div>

              <h2 data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="800" data-show-delay="400" data-hide-delay="400" class="main-slider__title sp-layer">Kitchen Display System</h2>
              <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="1200" data-show-delay="600" data-hide-delay="400" class="sp-layer">
                <div class="main-slider__decor bg-primary"></div>
              </div>
            <div data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="1200" data-hide-delay="400" class="main-slider__info sp-layer"><strong>Get Store informs your cooking staff what to prepare from an order</strong><br>Improve ticket times and accuracy with Get Store app. Get Store communicates directly with Get Stoer POS and displays all necessary order information: items, modifiers and notes.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end .main-slider-->
<!-- START THE NAVBAR -->
<section class="retail">
<div class="container">
<div class="row text-center">
<div class="col-sm-10 col-sm-offset-1 newp">
<h1>Get Store Helps Your Kitchen Run<br>
Smooth Even at Peak Times</h1><br><br>
    <img src="assets/media/general/new-kitchen.png" alt="foto" class="img-responsive col-sm-offset-1"/><br>
    <p>Simplify communication between servers and kitchen staff. Your servers ring up orders in Loyverse POS, and Loyverse Kitchen Display System immediately brings them right in front of your kitchen staff eyes, no time wasted.</p>

</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row text-center">
<div class="col-sm-10 col-sm-offset-1 newp">
<div class="ui-decor-1"><img src="assets/media/general/ui-decor-1.png" alt="decor" class="center-block"></div>
<h1>Features</h1>
<br>
<br>
    <img src="assets/media/general/kds-samsung-en.png" alt="foto" class="img-responsive col-sm-offset-1"/>
</div>
</div>
    <ul class="col-sm-10 col-sm-offset-2">
        <li><strong>Effective order management</strong><br>
Get orders to kitchen automatically straight from Loyverse POS, faster and with less errors.</li>
        <li><strong>Tickets at a glance</strong><br>
Items, quantities, modifiers, item and ticket comments - everything in one screen.</li>
        <li><strong>Colors to indicate wait time</strong><br>
You set the time, KDS changes ticket header colors to alert your kitchen staff.</li>
        <li><strong>Ticket recall</strong><br>
Mark orders as completed with a single touch. Easily recall them as necessary.</li>
        <li><strong>Customizable sound notifications</strong><br>
In a noisy kitchen environment, KDS will chime to announce each new order.</li>
        <li><strong>Sleek design</strong><br>
Straight-forward user interface which requires zero training.</li>
        <li><strong>Dark theme</strong><br>
Choose a mode which is suitable for your low-light kitchen environment.</li>
        <li><strong>Seamless installation</strong><br>
Download and set up in an instant. Use the same account you’ve been using for Loyverse POS.</li>
    </ul>
</div>
</section>

<section class="section-default retail">
<div class="container">
<div class="row">
<!--  product-->
<div class="col-md-6">
    <span class="stroke icon-Bread"></span>
    <h1>Less paper, less mess</h1>
    <h3>Say goodbye to kitchen printers and those chunky paper rolls. Save and go green.</h3>
    <p>Colorful ticket headers clearly indicate order waiting time, drawing attention to what has to be cooked and served first. Less late orders, more happy patrons.</p>

</div>
<div class="col-md-6">
    <img src="assets/media/general/orders.png" alt="foto" class="img-responsive"/>
</div>
</div>
</div>
</section>

<section class="bg-grey retail">
<div class="container">
<div class="row text-center">
<div class="col-sm-10 col-sm-offset-1 newp">
<span class="stroke icon-Speaker2"></span>
<h1>Pair any number of kitchen displays and printers with any number of POS devices</h1>
<br>
    <img src="assets/media/general/lds-pair.png" alt="foto" class="img-responsive col-sm-offset-1"/>
</div>
</div>
</div>
</section>

<div class="container retail">
<div class="row">
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_green section-radius" style="background-position: -62.7px 20.4px;">
            <div class="b-contact__name">Website</div>
            <div class="b-contact__info">{{'envUrl' | envName }}</div>
            <div class="b-contact__icon icon-globe"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_blue section-radius" style="background-position: -296.7px 20.4px;">
            <div class="b-contact__name">Phone</div>
            <div class="b-contact__info"> +60 3-7803 4040</div>
            <div class="b-contact__icon icon-call-in"></div>
          </div>
          <!-- end b-contact-->
        </div>
        <div class="col-lg-4 col-lg-offset-0 col-md-6 col-md-offset-3">
          <div data-stellar-background-ratio="0.4" class="b-contact stellar section-texture section-texture_grey section-radius" style="background-position: -530.7px 20.4px;">
            <div class="b-contact__name">Email</div>
            <div class="b-contact__info">getstore@gmail.com</div>
            <div class="b-contact__icon icon-envelope-open"></div>
          </div>
          <!-- end b-contact-->
        </div>
      </div>
</div>

  <section class="b-taglines area-bg bg-primary_a parallax">
    <div class="area-bg__inner">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 col-sm-offset-3">
            <div class="b-taglines__inner">
              <h1 class="">Download Get Store POS</h1>
              <div class="b-taglines__text col-sm-offset-1">For iPhone, iPad, Android smartphone or tablet</div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/gplay-en.png" alt="foto" class="img-responsive"/></a></div>
                <div class="col-md-3"><a href="#" class=""><img src="assets/media/general/appstore-en.png" alt="foto" class="img-responsive"/></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>

</div>
<!-- end layout-theme-->

<!-- //div fix-->
<script>
$(document).ready(function() {
$('.nav li a').click(function(e) {
    $('.nav li').removeClass('active');
    var $parent = $(this).parent();
    console.log($parent);
    if (!$parent.hasClass('active')) {
        $parent.addClass('active');
    }
    e.preventDefault();
});
})
</script>
